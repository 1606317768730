<template>
  <el-radio-group
    :value="value == null ? '' : value + ''"
    @input="$emit('input', $event)"
    :disabled="disabled"
  >
    <el-radio v-for="item in dictList" :key="item.code" :label="item.code">{{
      item.name
    }}</el-radio>
  </el-radio-group>
</template>
<script>
export default {
  name: "FortressRadioGroup",
  props: {
    value: [Number, String],
    dictList: Array,
    disabled: {
      typeof: [String, Boolean],
      default: false,
    },
  },
};
</script>
