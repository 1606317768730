<template>
  <div>
    <div v-if="isOpen" class="mask" @click="controlMask"></div>
    <div :class="{ 'fortress-theme-tools': true, 'fortress-theme-tools--open': isOpen }">
      <div class="fortress-theme-tools__toggle" @click="toggle">
        <i class="el-icon-setting" style="color:#fff"></i>
      </div>
      <div class="fortress-theme-tools__content">
        <div class="fortress-theme-part">
          <h3 class="fortress-theme-part-title">
            <span>内容区域</span>
          </h3>
          <div class="fortress-theme-part-list">
            <ul class="fortress-theme-part-list-item">
              <li>
                <span>开启多页签</span>
                <el-switch
                  v-model="$store.state.tagSwitch"
                  active-color="#0cafcc"
                  inactive-color="#00000040"
                  class="fortress-theme-switch"
                ></el-switch>
              </li>
              <li>
                <span>底部</span>
                <el-switch
                  v-model="$store.state.bottomSwitch"
                  active-color="#0cafcc"
                  inactive-color="#00000040"
                  class="fortress-theme-switch"
                ></el-switch>
              </li>
            </ul>
          </div>
        </div>
        <div class="fortress-theme-part">
          <h3 class="fortress-theme-part-title">
            <span>样式区域</span>
          </h3>
          <div class="fortress-theme-part-list">
            <ul class="fortress-theme-part-list-item">
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-container class="parentsBox">
      <el-header class="e-header">
        <fortress-header />
      </el-header>
      <el-container class="homeContent">
        <!-- 侧边布局 -->
        <!-- <el-aside>
        </el-aside>-->
        <el-menu
          :default-active="currentMenu"
          class="el-menu-vertical-demo aside"
          router
          :collapse="isCollapse"
          :unique-opened="true"
          :style="{top:top}"
        >
          <el-menu-item index="/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">控制台</span>
          </el-menu-item>
          <fortress-sidebar :sidebars="menuList"></fortress-sidebar>
        </el-menu>
        <!-- 内容页 -->
        <el-container class="el-container contentBox" :style="{ marginLeft: left }">
          <el-row class="breadCrumbs" :style="{ left: left,top:top }">
            <el-col :span="1.5">
              <span class="homeIcon">
                <i :class="menuCollapseClass" @click="changeCollapseType"></i>
              </span>
              <span class="homeIcon">
                <i class="iconfont shuaxin" @click="refreshPage" style="fontSize:18px"></i>
              </span>
            </el-col>
            <el-col :span="22.5">
              <el-breadcrumb separator-class="el-icon-arrow-right" class="homeBread">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, index) in array" :key="index">{{ item }}</el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
          </el-row>
          <el-row class="positionTag" :style="{'padding-top': paddingTop} " ref="positionTag">
            <vTag></vTag>
          </el-row>
          <!-- <el-divider style="margin: 0"></el-divider> -->
          <!-- <div class="el_content">
							<router-view></router-view>
          </div>-->
          <div
            class="el-footer max"
            v-if="$store.state.bottomSwitch"
          >
            © Copyright 56世界 版权所有
          </div>
          <div class="el-footer min" v-if="$store.state.bottomSwitch">

            <p>© Copyright 56世界 版权所有 </p>
          </div>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import FortressHeader from "@/components/fortress-header.vue"
import FortressSidebar from "@/components/fortress-sidebar.vue"
import { menuList } from "@/api/auth/auth.js"
import vTag from "@/components/fortress-tag.vue"
export default {
  name: "Home",
  components: {
    "fortress-header": FortressHeader,
    "fortress-sidebar": FortressSidebar,
    vTag,
  },
  data () {
    return {
      value: false,
      drawer: true,
      breadlist: [],
      isCollapse: false,
      menuCollapseClass: "iconfont zhedie1",
      menuList: [],
      array: [],
      asideWidth: "200px",
      left: "200px",
      currentMenu: "/home",
      isOpen: false,
      top: '60px',
      paddingTop: '40px',
      platform: true
    }
  },
  created () {
    var that = this
    that.getBreadcrumb(that.$route)
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (w <= 990) {
      that.menuCollapseClass = "iconfont zhedie2"
      that.$nextTick(() => {
        that.isCollapse = true
        that.asideWidth = "64px"
        that.left = "64px"
        if (w <= 768) {
          this.top = "106px"
          this.paddingTop = "86px"
          this.platform = false
        } else {
          this.top = "60px"
          this.paddingTop = "40px"
          this.platform = true
        }
      })
    } else {
      that.menuCollapseClass = "iconfont zhedie1"
      that.$nextTick(() => {
        that.isCollapse = false
        that.asideWidth = "200px"
        that.left = "200px"
        this.top = "60px"
        this.paddingTop = "40px"
        this.platform = true
      })
    }
    this.currentMenu = localStorage.getItem("currentPath") || "/home"
    this.isCollapse = false
    this.initMenu()
    this.getBreadcrumb()
  },

  mounted () {
    var that = this
    window.addEventListener(
      "resize",
      () => {
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (w <= 990) {
          that.menuCollapseClass = "iconfont zhedie2"
          that.$nextTick(() => {
            that.isCollapse = true
            that.asideWidth = "64px"
            that.left = "64px"
            this.top = "106px"
            if (w <= 768) {
              this.top = "106px"
              this.paddingTop = "86px"
              this.platform = false
            } else {
              this.top = "60px"
              this.paddingTop = "40px"
              this.platform = true
            }
          })
        } else {
          that.menuCollapseClass = "iconfont zhedie1"
          that.$nextTick(() => {
            that.isCollapse = false
            that.asideWidth = "200px"
            that.left = "200px"
            this.top = "60px"
            this.paddingTop = "40px"
            this.platform = true
          })
        }
      },
      false,
    )
    this.$store.state.tagSwitch = localStorage.getItem("tagSwitch") === "false" ? false : true
    this.$store.state.bottomSwitch = localStorage.getItem("bottomSwitch") === "false" ? false : true

  },
  methods: {
    //控制遮罩层
    controlMask () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.stopMove()
      } else {
        this.Move()
      }
    },
    // 面包屑
    getBreadcrumb (e) {
      if (e) {
        this.array = []
        if (localStorage.getItem("menusList")) {
          var menusList = JSON.parse(localStorage.getItem("menusList"))
          var ids = e.meta.id
          this.recursion(ids, menusList)
          localStorage.setItem("breadcrumb", JSON.stringify(this.array))
        }
      } else {
        // let currentMenu = localStorage.getItem('currentMenu')
        // console.log(currentMenu);
        // console.log(this.$route);
        // if(currentMenu!=this.$route.)
        this.array = JSON.parse(localStorage.getItem("breadcrumb"))
      }
    },
    recursion (demoId, demoArray) {
      if (demoId) {
        let list = demoArray.filter(item => {
          return item.id == demoId
        })
        this.array.unshift(list[0].name)
        var ids = list[0].parentId
        var menusList = JSON.parse(localStorage.getItem("menusList"))
        this.recursion(ids, menusList)
      }
    },

    //初始化菜单
    initMenu () {
      menuList().then(resp => {
        if (resp.code == 0) {
          this.menuList = resp.data
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          })
        }
      })
    },
    //切换菜单的收缩状态
    changeCollapseType () {
      if (!this.isCollapse) {
        this.isCollapse = true
        this.menuCollapseClass = "iconfont zhedie2"
        this.$nextTick(() => {
          this.asideWidth = "64px"
          this.left = "64px"
        })
      } else {
        this.isCollapse = false
        this.menuCollapseClass = "iconfont zhedie1"
        this.$nextTick(() => {
          this.asideWidth = "200px"
          this.left = "200px"
        })
      }
    },
    //页面刷新
    refreshPage () {
      location.reload()
    },
    toggle () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.stopMove()
      } else {
        this.Move()
      }
    },
    //停止页面滚动
    stopMove () {
      let m = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = "hidden"
      document.addEventListener("touchmove", m, { passive: false }) //禁止页面滑动
    },
    //开启页面滚动
    Move () {
      let m = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = "" //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true })
    },
  },
  watch: {
    $route (e) {
      this.getBreadcrumb(e)
      localStorage.setItem("currentMenu", e.fullPath)
      this.currentMenu = localStorage.getItem("currentMenu")
    },
    "$store.state.tagSwitch": function (val) {
      this.$store.commit("tagSwitchHandle", val)
      localStorage.setItem("tagSwitch", val)
    },
    "$store.state.bottomSwitch": function (val) {
      localStorage.setItem("bottomSwitch", val)
    },
  },
}
</script>
<style>
.fortress-theme-switch .el-switch__core {
  border-color: transparent !important;
  height: 16px;
}
.fortress-theme-switch .el-switch__core:after {
  width: 12px;
  height: 12px;
  top: 1px;
}
.fortress-theme-switch.is-checked .el-switch__core::after {
  margin-left: -13px !important;
}
</style>
<style scoped lang="scss">
.max {
  display: block;
}
.min {
  display: none;
}
.parentsBox {
  height: 100%;
  background: #f5f7f9;
  .e-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 0;
  }
  .homeContent {
    width: 100%;
    padding-top: 60px;
    .aside {
      width: 64px;
      position: fixed;
      left: 0;
      z-index: 100;
      bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      color: #333;
      // transition: top 0.3s;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
    }
    .contentBox {
      min-height: calc(100vh - 60px);
      padding: 0;
      color: #333;
      background: #f5f7f9;
      position: relative;
      transition: all 0.3s;
      // -webkit-animation: all 0.3s;
      .breadCrumbs {
        width: 100%;
        background: #fff;
        box-shadow: 0 1px 4px #eee;
        border-radius: 2px;
        font-weight: normal !important;
        position: fixed;

        right: 0;
        z-index: 99;
        transition: all 0.3s;
        .homeIcon {
          /* padding: 0 7px 0 0; */
          display: inline-block;
          width: 36px;
          height: 36px;
          text-align: center;
          cursor: pointer;
        }
        .homeIcon i {
          display: inline-block;
          font-size: 18px;
          line-height: 36px;
          text-align: center;
          font-weight: normal;
        }
        .homeBread {
          height: 36px;
          line-height: 36px;
          text-align: center;
          padding: 0 10px;
        }
        .homeBread span {
          display: inline-block;
          height: 36px;
          line-height: 36px;
          text-align: center;
          box-sizing: border-box;
        }
      }

      .positionTag {
        margin-bottom: 50px;
        width: 100%;
      }
      .el-footer {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        text-align: center;
        color: #808695;
        font-size: 14px;
        box-sizing: border-box;
        background: transparent !important;
      }
    }
  }
}
//控制底部copyright
@media screen and (max-width: 768px) {
  .max {
    display: none;
  }
  .min {
    display: block;
  }
}
/* 设置 */
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
}
.fortress-theme-tools {
  position: fixed;
  top: 0px;
  right: -300px;
  bottom: 0;
  z-index: 1010;
  width: 300px;
  -webkit-transition: right 0.3s;
  transition: right 0.3s;
  z-index: 210;
}
.fortress-theme-tools__toggle {
  position: absolute;
  top: 240px;
  left: -48px;
  width: 48px;
  padding: 10px 8px;
  text-align: center;
  font-size: 20px;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  color: #fff;
  background-color: #0cafcc;
  cursor: pointer;
  z-index: 210;
}
.fortress-theme-tools__content {
  height: 100%;
  padding: 20px;
  /* border: 1px solid #86878b; */
  background-color: #fff;
  overflow-y: auto;
}
.fortress-theme-part {
  margin-bottom: 20px;
}
.fortress-theme-part-title {
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.fortress-theme-part-title span {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.fortress-theme-part-title span::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #e8eaec;
  transform: translateX(70px);
}
.fortress-theme-part-list-item li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.fortress-theme-part-list-item span {
  font-size: 14px;
}
.fortress-theme-switch {
  width: 28px !important;
  height: 16px !important;
  line-height: 16px !important;
}

.fortress-theme-tools--open {
  right: 0;
}
</style>
