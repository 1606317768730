import Vue from "vue"
import Vuex from "vuex"
import request from "@/common/request"
import router from "../router/router"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token") || "",
        userInfo: localStorage.getItem("userInfo") || {},
        menus: localStorage.getItem("menus") || [],
        prefixUrl: localStorage.getItem("prefixUrl") || "",
        tagList: localStorage.getItem("tagLists") || "",
        tagSwitch: localStorage.getItem("tagSwitch") || true,
        bottomSwitch: localStorage.getItem("bottomSwitch") || true,
    },
    mutations: {
        tagSwitchHandle(state, val) {
            state.tagSwitch = val
            localStorage.setItem("tagSwitch", val)
        },
        tagBottomHandle(state, val) {
            state.bottomSwitch = val
            localStorage.setItem("bottomSwitch", val)
        },
        tagListHandle(state, tagList) {
            state.tagList = JSON.stringify(tagList)
            localStorage.setItem("tagLists", JSON.stringify(tagList))
        },
        setToken(state, token) {
            state.token = token
            localStorage.setItem("token", token)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = JSON.stringify(userInfo)
            localStorage.setItem("userInfo", JSON.stringify(userInfo))
            localStorage.setItem("permission", JSON.stringify(userInfo.permission))
        },
        //初始化路由
        setRouter(_state, menus) {
            console.log('初始化路由');
            localStorage.setItem("menus", JSON.stringify(menus))
            menus.forEach(item => {
                if (item.path.indexOf('/pdworld/pdline') != -1 || item.path.indexOf('/pdworld/pdstore') != -1 || item.path.indexOf('/pdworld/pdorder') != -1 || item.path.indexOf('/pdworld/pdqa') != -1 || item.path.indexOf('/pdworld/pdshopinfoimg') != -1 || item.path.indexOf('/operation/basics') != -1) {
                    router.options.routes[0].children[3].children.push(item)
                    console.log(item, 'item');
                } else {
                    router.options.routes[0].children.push(item)
                }

            })
            router.addRoutes(router.options.routes)
        },
        //初始化文件访问前缀
        setPrefixUrl(_state, prefixUrl) {
            _state.prefixUrl = prefixUrl
            localStorage.setItem("prefixUrl", prefixUrl)
        },
    },
    actions: {
        initRouter({ commit }) {
            // axios 请求数据
            //
            let routers = []
            let formdata = []
            request("get", "/system/menu/routers")
                .then(resp => {
                    if (resp.code == 0) {
                        if (resp.data) {
                            localStorage.setItem("menusList", JSON.stringify(resp.data))
                            resp.data.forEach(menu => {
                                if (menu.viewPath) {
                                    if (menu.viewPath.indexOf('/form/formdata/') != 0) {
                                        routers.push({
                                            path: menu.viewPath,
                                            meta: {
                                                title: menu.name,
                                                id: menu.id,
                                            },
                                            name: menu.viewPath,
                                            component: resolve => require([`@/views${menu.viewPath}.vue`], resolve),
                                        })
                                    } else {
                                        formdata.push({
                                            path: menu.viewPath,
                                            meta: {
                                                title: menu.name,
                                                id: menu.id,
                                            },
                                            name: menu.viewPath,
                                            component: resolve => require([`@/views${menu.viewPath}.vue`], resolve),
                                        })
                                    }
                                }
                            })
                        }
                        localStorage.setItem('formdata', JSON.stringify(formdata))
                        commit("setRouter", routers)
                    } else if (resp.code == 1001) {
                        router.replace({ name: "login" })
                    } else {
                        commit("setRouter", routers)
                    }
                })
                .catch(e => {
                    commit("setRouter", routers)
                })
        },
    },
})