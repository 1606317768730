import request from "@/common/request"

//用户详情
export const personInfo = () => {
        return request('get', '/system/sysUser/personInfo')
    }
    //修改用户信息
export const personEdit = (data) => {
        return request('post', '/system/sysUser/personEdit', data)
    }
    //修改用户密码
export const personUptPwd = (data) => {
        return request('post', '/system/sysUser/personUptPwd', data)
    }
    //修改手机号
export const personUptPhone = (data) => {
        return request('post', '/system/sysUser/personUptPhone', data)
    }
    //获取验证码
export const personGetCode = (data) => {
    return request('get', '/system/sysCaptcha/init', data)
}