import axios from 'axios';
import { Message } from 'element-ui'
import Store from '@/store/store';
import router from '@/router/router'


axios.defaults.validateStatus = function (status) {
	return status >= 200 && status <= 500;
};

let baseUrl = process.env.VUE_APP_ROOT_URL_ENV;   //这里是一个默认的url，可以没有

let headers = {
	// 'Content-Type': 'application/x-www-form-urlencoded'
}
//创建axios的一个实例 
const axiosInstance = axios.create({
	baseURL: baseUrl,//接口统一域名
	timeout: 1000 * 7,//设置超时
	headers: headers
})


//------------------- 一、请求拦截器 忽略
axiosInstance.interceptors.request.use(config => {
	if (config.url == baseUrl + "refreshToken") {
		return config;
	}
	if (localStorage.getItem("token") || Store.state.token) {
		config.headers.Authorization = 'Bearer ' + localStorage.getItem("token") || Store.state.token
	}
	if (localStorage.getItem("fortressExpiredTime") && new Date(Date.parse(localStorage.getItem("fortressExpiredTime"))) < new Date()) {
		//通过refreshToken重新获取token
		let promisefresh = new Promise(function (resolve, reject) {
			//刷新token
			axios.post(baseUrl + "refreshToken", {}, {
				headers: {
					'token': 'Bearer ' + localStorage.getItem("fortressRefreshToken")
				}
			}).then(response => {
				if (response.data && response.data.code == 0) {
					localStorage.setItem(
						"fortressRefreshToken",
						response.data.data.refreshToken
					);
					localStorage.setItem(
						"fortressExpiredTime",
						response.data.data.expiredTime
					);
					localStorage.setItem(
						"token",
						response.data.data.token
					);
					config.headers.Authorization = `Bearer ${response.data.data.token}`;
				}
				resolve(config);
			})
		});
		return promisefresh;
	}
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
axiosInstance.interceptors.response.use(response => {
	// 用户未登录 跳转到登录页面
	if ((response.data && (response.data.code == 401 || response.data.code == 1001 || response.data.code == 1000)) || response.status === 401) {
		router.replace({ name: 'login' })
	}
	// else if (response.data && response.data.code != 0) {
	// 	Message({
	// 		message: response.data.msg,
	// 		type: 'error'
	// 	});
	// 	return Promise.reject({
	// 		code: response.data.code,
	// 		msg: response.data.msg,
	// 	});
	// }
	return response.data;
}, function (error) {
	// 对响应错误做点什么
	console.log('拦截器报错');
	return Promise.reject(error);
});

/**动态表头导出 */
export const eptpost = function (url, data) {
	axios({
		method: "post",
		url: baseUrl + url,
		data: data,
		responseType: "blob",
		headers: {
			'Authorization': 'Bearer ' + localStorage.getItem("token") || Store.state.token
		}
	})
		.then(res => {
			console.log(res);
			const link = document.createElement("a");
			let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
			link.style.display = "none";
			link.href = URL.createObjectURL(blob);
			link.setAttribute("download", decodeURI(res.headers['filename']));
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
		.catch(error => {
			Message({
				message: "导出失败",
				type: 'error'
			});
			console.log(error);
		});
}



export default function (method, url, data) {
	method = method.toLowerCase();
	if (method == 'post') {
		return axiosInstance.post(url, data)
	} else if (method == 'get') {
		return axiosInstance.get(url, { params: data })
	} else if (method == 'delete') {
		return axiosInstance.delete(url, { params: data })
	} else if (method == 'put') {
		return axiosInstance.put(url, data)
	} else if (method == 'eptpost') {
		eptpost(url, data);
	} else {
		console.error('未知的method' + method)
		return false
	}
}