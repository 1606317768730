import request from "@/common/request"

//行政区域分页展示
export const sysregionPage = (queryForm) => {
    return request("get", "/system/sysRegion/page", queryForm);
}
//行政区域列表展示
export const sysregionList = (queryForm) => {
    return request("get", "/system/sysRegion/list", queryForm);
}
//行政区域详情
export const sysregionInfo = (id) => {
    return request('get', '/system/sysRegion/info', {
        id: id
    })
}
//行政区域信息保存
export const sysregionSave = (data) => {
    return request('post', '/system/sysRegion/save', data)
}
//行政区域信息修改
export const sysregionEdit = (data) => {
    return request('post', '/system/sysRegion/edit', data)
}
//行政区域信息删除
export const sysregionDel = (id) => {
    return request('post', '/system/sysRegion/delete?id=' + id)
}
//行政区域批量删除
export const sysregionDelBatch = (ids) => {
    return request('post', '/system/sysRegion/deleteBatch', ids)
}
//行政区域树形展示
export const sysregionTree = () => {
    return request("get", "/system/sysRegion/tree");
}