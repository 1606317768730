<template>
	<div class="FortressRegionChecked">
		<slot :open="toOpen" :province="province" :city="city" :area="area" :value="inputNames">
			<el-input
				type="textarea"
				:autosize="{ minRows: 1, maxRows: 4 }"
				readonly
				:placeholder="placeholder"
				@click.native="toOpen"
				:value="inputNames"
			></el-input>
		</slot>
		<el-dialog title="选择区域" :visible.sync="visible" modal-append-to-body append-to-body lock-scroll>
			<div class="region-content">
				<div class="province">
					<div class="title">省份</div>
					<div class="region-main">
						<div
							class="region-item"
							:class="{ active: provinceActive.id === item.id }"
							v-for="item in regionList"
							:key="item.id"
							@click="onProvinceCheckActive(item)"
						>
							{{ item.name }}
							<el-checkbox
								:value="provinceCheckedIds.includes(item.id)"
								@change="onProvinceCheckChange(item)"
								@click.stop.native
							></el-checkbox>
						</div>
					</div>
				</div>
				<div class="city">
					<div class="title">城市</div>
					<div class="region-main">
						<div
							class="region-item"
							:class="{ active: cityActive.id === item.id }"
							v-for="item in cityList"
							:key="item.id"
							@click="onCityCheckActive(item)"
						>
							{{ item.name }}
							<el-checkbox
								:value="cityCheckedIds.includes(item.id)"
								@change="onCityCheckChange(item)"
								@click.stop.native
							></el-checkbox>
						</div>
					</div>
				</div>
				<div class="area">
					<div class="title">区</div>
					<div class="region-main">
						<div
							class="region-item"
							:class="{ active: areaActive.id === item.id }"
							v-for="item in areaList"
							:key="item.id"
							@click="onAreaCheckActive(item)"
						>
							{{ item.name }}
							<el-checkbox
								:value="areaCheckedIds.includes(item.id)"
								@change="onAreaCheckChange(item)"
								@click.stop.native
							></el-checkbox>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<el-button type="text" @click="onClose">取消</el-button>
				<el-button type="primary" @click="onConform">确定</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { sysregionTree } from "@/api/system/sysregion.js"

export default {
	name: "FortressRegionChecked",
	model: {
		prop: "value",
		event: "update:value",
	},
	props: {
		placeholder: {
			type: String,
			default: "请选择省市区",
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			visible: false,
			regionList: [],
			province: [],
			city: [],
			area: [],

			provinceActive: "",
			cityActive: "",
			areaActive: "",

			inputNames: "",
		}
	},
	computed: {
		provinceCheckedIds() {
			return this.province.map(item => item.id)
		},
		cityList() {
			return this.provinceActive.children || []
		},
		cityCheckedIds() {
			return this.city.map(item => item.id)
		},
		areaList() {
			return this.cityActive.children || []
		},
		areaCheckedIds() {
			return this.area.map(item => item.id)
		},
	},
	async created() {
		const { data } = await sysregionTree()
		this.regionList = data
	},
	methods: {
		toOpen() {
			this.visible = true
			if (typeof this.value === "object") {
				this.province = this.value.province || []
				this.city = this.value.city || []
				this.area = this.value.area || []
			}
		},
		onClose() {
			this.visible = false
		},
		onConform() {
			this.visible = false
			this.inputNames = this.city.map(item => item.name).join(";") + ";" + this.area.map(item => item.name).join(";")
			this.$emit("update:value", {
				province: this.province,
				city: this.city,
				area: this.area,
			})
			this.$emit("change", {
				province: this.province,
				city: this.city,
				area: this.area,
			})
		},

		onProvinceCheckActive(item) {
			this.provinceActive = item
			this.cityActive = {}
			this.areaActive = {}
		},
		onProvinceCheckChange(item) {
			const city = item.children || []
			const area = []
			city.forEach(item => {
				area.push(...item.children)
			})

			if (this.provinceCheckedIds.includes(item.id)) {
				this.province = this.province.filter(i => i.id !== item.id)
				this.city = this.city.filter(i => !city.map(item => item.id).includes(i.id))
				this.area = this.area.filter(i => !area.map(item => item.id).includes(i.id))
			} else {
				this.province.push(item)
				this.city.push(...city)
				this.area.push(...area)
			}
		},
		onCityCheckActive(item) {
			this.cityActive = item
			this.areaActive = {}
		},
		onCityCheckChange(item) {
			const province = this.regionList.find(i => i.id === item.parentId)
			const area = item.children || []

			if (!this.provinceCheckedIds.includes(province.id)) {
				this.province.push(province)
			}

			if (this.cityCheckedIds.includes(item.id)) {
				this.city = this.city.filter(i => i.id !== item.id)
				this.area = this.area.filter(i => !area.map(item => item.id).includes(i.id))
			} else {
				this.city.push(item)
				this.area.push(...area)
			}
		},
		onAreaCheckActive(item) {
			this.areaActive = item
		},
		onAreaCheckChange(item) {
			const city = this.cityList.find(i => i.id === item.parentId)
			if (!this.cityCheckedIds.includes(city.id)) {
				this.city.push(city)
			}
			if (this.areaCheckedIds.includes(item.id)) {
				this.area = this.area.filter(i => i.id !== item.id)
			} else {
				this.area.push(item)
			}
		},
	},
	components: {},
}
</script>

<style lang="scss" scoped>
.FortressRegionChecked {
	display: inline-block;
	width: 100%;
}
.region-content {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	.province,
	.city,
	.area {
		flex: 1;
	}
	.title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 15px;
	}
	.region-main {
		max-height: 500px;
		overflow-y: auto;
		.region-item {
			border-bottom: 1px dashed #ccc;
			padding: 8px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: all 0.25s;
			&.active {
				background: #f5f5f5;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
</style>
