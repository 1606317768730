<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    let url = window.location.href;
    let loginFlag = url.indexOf("/login") != -1;
    if (this.$store.state.token && !loginFlag) {
      this.$store.dispatch("initRouter");
    } else {
      this.$router.replace({ name: "login" });
    }
  },
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}
/* table暂无数据中添加背景图 */
.el-table__empty-text {
  display: block;
  width: 500px;
  height: 385px;
  padding-top: 270px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/img/no_data.png");
  background-position: center center;
  color: #909399;
  box-sizing: border-box;
}

</style>
