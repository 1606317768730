<template>
	<div class="_tag">
		<div class="tag_center">
			<el-tabs
				class="tag_tabs__warp"
				@tab-click="handleClick"
				v-model="currentPath"
				@tab-remove="handleCloseTag"
				v-if="$store.state.tagSwitch"
			>
				<el-tab-pane
					v-for="(tag, index) in tagsList"
					:key="index"
					:label="tag.title"
					:name="tag.path"
					:closable="tag.path !== '/home'"
				>
					<keep-alive>
						<router-view v-if="tag.path === currentPath" />
					</keep-alive>
				</el-tab-pane>
			</el-tabs>
			<div v-else>
				<router-view />
			</div>
		</div>
		<el-dropdown @command="handleCloseBtn" class="_dropdown" v-if="$store.state.tagSwitch">
			<i class="el-icon-arrow-down el-icon--right"></i>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item command="closeOther">关闭其它</el-dropdown-item>
				<el-dropdown-item command="closeAll">关闭所有</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>
<style lang="scss" scoped>
._tag /deep/ .tag_tabs__warp > .el-tabs__header {
	margin: 0;
	width: calc(100% - 32px);
}
._tag /deep/ .tag_tabs__warp .el-tabs__item {
	width: auto !important;
	padding: 0 12px !important;
}
._tag /deep/ .tag_tabs__warp .el-tabs__nav {
	width: auto;
}
._tag /deep/ .tag_tabs__warp .el-tabs__active-bar {
	display: none;
}
._tag /deep/ .tag_tabs__warp .el-tabs__nav-wrap::after {
	height: 0 !important;
}
._tag /deep/ .tag_tabs__warp .el-tabs__nav-next,
._tag /deep/ .tag_tabs__warp .el-tabs__nav-prev {
	line-height: 32px !important;
}
._tag /deep/ .tag_tabs__warp .el-tabs__item {
	color: #515a6e;
	background-color: #fff;
	margin-right: 6px;
	border-radius: 3px;
	height: 32px !important;
	line-height: 32px !important;
	font-size: 14px;
}
._tag /deep/ .tag_tabs__warp .el-tabs__content {
	margin-top: 15px;
}
._tag {
	padding-bottom: 50px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background-color: #f5f7f9;
	.tag_center {
		// width: calc(100% - 32px) !important;
		padding: 6px 12px;
		height: 100%;
		width: 100%;
	}
	._dropdown {
		width: 32px;
		height: 32px;
		display: flex;
		line-height: 32px;
		justify-content: center;
		align-items: center;
		background-color: white;
		border-radius: 0 0 4px 4px;
		position: absolute;
		right: 0;
	}
}
</style>

<script>
export default {
	computed: {},
	data() {
		return {
			tagsList: [],
			iconShow: true,
			innerWidth: 0,
			scrollWitch: 0,
			currentPath: "",
		}
	},
	created() {
		this.currentPath = localStorage.getItem("currentPath")
	},
	mounted() {
		if (this.$route.meta.title != "控制台") {
			let oldView = JSON.parse(sessionStorage.getItem("tabViews")) || []
			this.tagsList = oldView
			const isKZT = this.tagsList.some(item => {
				return item.title === "控制台"
			})
			const isKZTData = {
				path: "/home",
				fullPath: "/home",
				name: "home",
				meta: { title: "控制台" },
				title: "控制台",
			}
			if (!isKZT) {
				this.setTags(isKZTData)
			}
			this.setTags(this.$route)
		}
		this.beforeUnload()
	},
	methods: {
		handleClick(tab) {
			this.$router.push(this.tagsList[tab.index].path)
		},
		// 刷新前缓存tab
		beforeUnload() {
			// 监听页面刷新(tag刷新也存在)
			window.addEventListener("beforeunload", () => {
				let tabViews = this.tagsList.map(item => {
					return {
						fullPath: item.fullPath,
						hash: item.hash,
						meta: { ...item.meta },
						name: item.name,
						params: { ...item.params },
						path: item.path,
						query: { ...item.query },
						title: item.title,
					}
				})
				sessionStorage.setItem("tabViews", JSON.stringify(tabViews))
			})
			// 页面初始化加载判断缓存中是否有数据
			if (sessionStorage.getItem("tabViews")) {
				let oldViews = JSON.parse(sessionStorage.getItem("tabViews")) || []
				if (oldViews.length > 0) {
					this.tagsList = oldViews
				}
			}
		},
		//设置标签
		setTags(route) {
			console.log(route, "新路由")
			const isExsit = this.tagsList.some(item => {
				return item.path === route.fullPath
			})
			if (isExsit == false) {
				this.tagsList.push({
					title: route.meta.title, //标签名
					name: route.name, //路由里的name对应vue页的name,标签列表里的name可以做vue页面缓存
					path: route.fullPath, //路由
					hideclose: false,
				})
			}
			this.$store.commit("tagListHandle", this.tagsList)
		},
		//关闭标签
		handleCloseTag(tag) {
			var closePath = this.tagsList.filter(item => {
				return item.path === tag
			})
			this.tagsList.splice(this.tagsList.indexOf(closePath[0]), 1)
			this.$store.commit("tagListHandle", this.tagsList)
			if (this.tagsList.length > 0) {
				this.$router.push(this.tagsList[this.tagsList.length - 1].path)
			} else {
				this.$router.push("/home")
			}
		},
		//关闭功能按钮
		handleCloseBtn(command) {
			if (command == "closeOther") {
				if (this.$route.fullPath !== "/home") {
					var activeTag = this.tagsList.find(item => {
						return item.path == this.$route.fullPath
					}) //查找第一个满足的
					var noCloseTags = []
					noCloseTags.push({
						title: "控制台", //标签名
						name: "/home", //路由里的name对应vue页的name,标签列表里的name可以做vue页面缓存
						path: "/home", //路由
						hideclose: false,
					})
					let arry = this.getNoCloseTabs()
					if (arry.length > 0) {
						noCloseTags.push(arry)
					}
					if (
						noCloseTags.some(item => {
							return item.path == activeTag.path && item.title == activeTag.title
						}) == false
					) {
						noCloseTags = noCloseTags.concat(activeTag)
					}
					this.tagsList = noCloseTags
				} else {
					this.handleCloseBtn("closeAll")
				}
			} else if (command == "closeAll") {
				this.tagsList = this.getNoCloseTabs()
				this.tagsList.push({
					title: "控制台", //标签名
					name: "/home", //路由里的name对应vue页的name,标签列表里的name可以做vue页面缓存
					path: "/home", //路由
					hideclose: false,
				})
				this.$router.push("/home")
			}
			// this.$store.commit("tagListHandle", this.tagsList)
		},
		getNoCloseTabs() {
			//获取没有删除的标签
			var noCloseList = this.tagsList.filter(item => {
				return item.hideclose == true
			})
			return noCloseList
		},
	},
	watch: {
		//路由变化,设置标签
		$route(newValue) {
			this.currentPath = newValue.fullPath
			localStorage.setItem("currentPath", newValue.fullPath)
			this.setTags(newValue)
			// this.beforeUnload()
		},
	},
}
</script>
