<template>
  <div class="fortress-header">
    <el-row class="ft-header" style="margin: 0px" v-if="platform">
      <div class="header-title">
        <h3>
          {{ proName }}
          <!-- 运营管理平台 -->
        </h3>
      </div>
      <div class="header-right">
        <!--
          data:需要显示的总的数据
          lable:显示\搜索字段的key
          value:选中字段值
          placeholder:"默认值：根据关键字搜索"
          select方法：返回选中项
          className:
          默认：defaultClass 灰色主题
          whiteClass 白色主题
        -->
        <fortress-search
          :data="tableData"
          lable="name"
          v-if="show"
          @select="selectHandle"
          :value="value"
          placeholder="菜单搜索..."
          class="whiteClass"
        ></fortress-search>
        <div class="center">
          <el-popover
            placement="bottom"
            width="300"
            trigger="click"
            popper-class="header-info"
          >
            <div>
              <el-tabs v-model="activeName" class="tabs">
                <el-tab-pane label="通知" name="first">
                  <div class="inner">
                    <div class="item-list">
                      <div
                        class="list-card"
                        v-for="(item, indexs) in noticeList"
                        :key="indexs"
                      >
                        <div class="card-left">
                          <img :src="item.pic" alt />
                        </div>
                        <div class="card-right">
                          <h4>{{ item.title }}</h4>
                          <p>{{ item.time }}</p>
                        </div>
                      </div>
                      <div class="more" v-if="overOne">
                        <div class="loading">加载更多</div>
                      </div>
                    </div>
                    <div class="remove">
                      <i></i>
                      <span></span>
                      清空通知
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="代办" name="third">
                  <div class="inner">
                    <div class="item-list">
                      <div
                        class="list-card list-cardSecond"
                        v-for="(item, index) in commissionList"
                        :key="index"
                      >
                        <div class="cardSecond-top">
                          {{ item.title }}
                          <div class="card-tip">{{ item.tip }}</div>
                        </div>
                        <div class="cardSecond-bottom">{{ item.content }}</div>
                      </div>
                      <div class="more" v-if="overTwo" @click="getMore">
                        <div :class="{ loading: true, gray: more }">
                          <i class="el-icon-loading" v-if="more"></i>
                          加载更多
                        </div>
                      </div>
                    </div>
                    <div class="remove">
                      <i></i>
                      <span></span>
                      清空通知
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <span slot="reference">
              <i class="iconfont icon-lingdang"></i>
            </span>
          </el-popover>
        </div>
        <!-- <div class="center">
          <el-dropdown :show-timeout="100">
            <span class="dropdown-span">
              <i class="iconfont icon-zhongyingwenqiehuan"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>简体中文</el-dropdown-item>
              <el-dropdown-item>English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>-->
        <div class="center" @click="big()">
          <span class="dropdown-span">
            <i class="el-icon-full-screen" style="color: #fff"></i>
          </span>
        </div>
        <div class="div-user">
          <el-dropdown @command="handleCommand" :show-timeout="100">
            <span>
              <span class="user-span">
                <el-avatar :size="40" :src="avatar"></el-avatar>
              </span>
              <span class="el-dropdown-link user-span">
                {{ username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="personInfo">个人信息</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-row>
    <el-row class="mobile-header" style="margin: 0px" v-else>
      <div class="header-title">
        <h3>
          {{ proName }}
          <span>运营管理平台</span>
        </h3>
      </div>
      <div class="header-right">
        <fortress-search
          :data="tableData"
          lable="name"
          v-if="show"
          @select="selectHandle"
          :value="value"
          placeholder="菜单搜索..."
          class="whiteClass"
        ></fortress-search>
        <div class="center">
          <el-popover
            placement="bottom"
            width="300"
            trigger="click"
            popper-class="header-info"
          >
            <div>
              <el-tabs v-model="activeName" class="tabs">
                <el-tab-pane label="通知" name="first">
                  <div class="inner">
                    <div class="item-list">
                      <div
                        class="list-card"
                        v-for="(item, indexs) in noticeList"
                        :key="indexs"
                      >
                        <div class="card-left">
                          <img :src="item.pic" alt />
                        </div>
                        <div class="card-right">
                          <h4>{{ item.title }}</h4>
                          <p>{{ item.time }}</p>
                        </div>
                      </div>
                      <div class="more" v-if="overOne">
                        <div class="loading">加载更多</div>
                      </div>
                    </div>
                    <div class="remove">
                      <i></i>
                      <span></span>
                      清空通知
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="代办" name="third">
                  <div class="inner">
                    <div class="item-list">
                      <div
                        class="list-card list-cardSecond"
                        v-for="(item, index) in commissionList"
                        :key="index"
                      >
                        <div class="cardSecond-top">
                          {{ item.title }}
                          <div class="card-tip">{{ item.tip }}</div>
                        </div>
                        <div class="cardSecond-bottom">{{ item.content }}</div>
                      </div>
                      <div class="more" v-if="overTwo" @click="getMore">
                        <div :class="{ loading: true, gray: more }">
                          <i class="el-icon-loading" v-if="more"></i>
                          加载更多
                        </div>
                      </div>
                    </div>
                    <div class="remove">
                      <i></i>
                      <span></span>
                      清空通知
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <span slot="reference">
              <i class="iconfont icon-lingdang"></i>
            </span>
          </el-popover>
        </div>
        <!-- <div class="center">
          <el-dropdown :show-timeout="100">
            <span class="dropdown-span">
              <i class="iconfont icon-zhongyingwenqiehuan"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>简体中文</el-dropdown-item>
              <el-dropdown-item>English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>-->
        <div class="center" @click="big()">
          <span class="dropdown-span">
            <i class="el-icon-full-screen" style="color: #fff"></i>
          </span>
        </div>
        <div class="div-user">
          <el-dropdown @command="handleCommand" :show-timeout="100">
            <span>
              <span class="user-span">
                <el-avatar :size="40" :src="avatar"></el-avatar>
              </span>
              <span class="el-dropdown-link user-span">
                {{ username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="personInfo">个人信息</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
import { userInfo } from "@/api/auth/auth.js";
import { optMenuList } from "@/api/system/menu.js";
import router from "@/router/router";
import screenfull from "screenfull";
import "element-ui/lib/theme-chalk/display.css";
import defaultAvator from "@/assets/img/default_avatar.png";
export default {
  props: {
    data: {
      typeof: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      tableData: [],
      proName: window.$conf.commonConf.projectName,
      avatar: "",
      username: "",
      activeName: "first",
      noticeList: [
        {
          pic: "https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar",
          title: "蒂姆·库克回复了你的邮件",
          time: "2019-05-08 14:33:18",
        },
      ],
      commissionList: [
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
        {
          title: "公孙离的面试评审",
          tip: "即将到期",
          content: "需要在 2019-06-14 之前完成",
        },
      ],
      overOne: false,
      overTwo: false,
      more: false,
      platform: true,
      value: "",
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
  },
  created() {
    this.offsetWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (+this.offsetWidth <= 768) {
      this.$nextTick(() => {
        this.platform = false;
      });
    } else {
      this.$nextTick(() => {
        this.platform = true;
      });
    }
    if (this.noticeList.length >= 6) {
      this.overOne = true;
    }
    if (this.commissionList.length >= 6) {
      this.overTwo = true;
    }
    userInfo().then((resp) => {
      this.$store.commit("setUserInfo", resp.data);
      let userInfo = JSON.parse(this.$store.state.userInfo);
      if (userInfo.avatar) {
        this.avatar = this.$store.state.prefixUrl + userInfo.avatar;
      } else {
        this.avatar = defaultAvator;
      }
      this.username = userInfo.nickname || userInfo.loginName;
    });
  },
  mounted() {
    //获取菜单数据
    this.queryMenuList();
    window.onresize = () => {
      return (() => {
        this.offsetWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (+this.offsetWidth <= 768) {
          this.$nextTick(() => {
            this.platform = false;
          });
        } else {
          this.$nextTick(() => {
            this.platform = true;
          });
        }
        // this.isMobileHandle()
      })();
    };
  },
  methods: {
    //搜索选择操作
    selectHandle(e) {
      console.log(e);
      this.$router.push({ path: e.path });
      this.value = e.name;
    },
    //查询菜单列表
    queryMenuList() {
      optMenuList().then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data;
          this.show = true;
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    handleCommand(command) {
      if ("logout" == command) {
        this.$confirm("确定退出系统?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.setItem("token", "");
            localStorage.setItem("userInfo", "");
            localStorage.setItem("permission", "");
            sessionStorage.setItem("tabViews", "");
            router.replace({ name: "login" });
          })
          .catch(() => {});
      }
      if ("personInfo" == command) {
        this.$router.push({ path: "/home/person" });
      }
    },
    // 加载更多消息
    getMore() {
      this.more = true;
    },
    // 全屏
    big() {
      // if (!screenfull.enabled) {
      // 	this.$message({
      // 		message: "Your browser does not work",
      // 		type: "warning",
      // 	})

      // 	return false
      // }
      screenfull.toggle();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-popper[x-placement^="bottom"] {
  margin-top: 0 !important;
}
</style>
<style scoped>
.el-popper {
  margin-top: 0 !important;
}

.gray {
  color: #c5c8ce !important;
}
.fortress-header {
  width: 100%;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
}
.ft-header {
  display: flex;
  display: -webkit-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0px;
  background: linear-gradient(
    to right,
    rgb(0, 213, 230) 10%,
    rgb(31, 114, 162) 100%
  );
}

.ft-header .header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.header-title {
  flex: 1;
  text-align: left;
}
.header-title h3 {
  padding-left: 17px;
  color: aliceblue;
  transition: all 0.1s linear;
}
@media screen and (max-width: 768px) {
  .header-title h3 {
    width: 100%;
    font-size: 22px;
    transition: all 0.1s linear;
  }
  .div-user span {
    font-size: 18px !important;
    font-weight: normal;
  }
}
.div-user {
  height: 60px;
  padding: 0 14px 0 5px;
  display: flex;
  align-items: center;
}
.div-user:hover {
  background: rgba(31, 114, 162, 0.6) !important;
}
.div-user > span {
  font-size: 20px;
  color: aliceblue;
  width: 32px !important;
  height: 32px !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.center {
  /* padding: 0 10px; */
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
}
.center:hover {
  background: rgba(31, 114, 162, 0.6) !important;
}
.center i {
  font-size: 18px;
}
.center > span {
  padding: 0 10px;
}
.center span {
  display: block;
  height: 60px;
  line-height: 60px;
}
/* .center .el-dropdown {
	height: 60px;
	line-height: 60px;
} */
.el-dropdown {
  height: 60px;
}
.el-dropdown > span {
  display: flex;
  align-items: center;
}
.tabs {
  width: 100%;
}
.el-tabs__nav {
  width: 100%;
}
.el-tabs__item {
  padding: 0;
  width: 50%;
  text-align: center;
}

.list-card {
  padding: 12px;
  border-top: 1px solid #e8eaec;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  text-align: left;
  display: flex;
  align-items: center;
}
.list-cardSecond {
  flex-wrap: wrap;
}
.card-left {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.card-left img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.card-right {
  width: calc(100% - 32px);
}
.card-right h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #515a6e;
}
.card-right p {
  font-size: 12px;
  color: #808695;
}
.tabs .el-tabs__content {
  margin-top: 1px !important;
}
.cardSecond-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #515a6e;
}
.cardSecond-top .card-tip {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin: 2px 4px 2px 0;
  padding: 0 8px;
  border: 1px solid #e8eaec;
  border-radius: 3px;
  background: #f7f7f7;
  font-size: 12px;
  vertical-align: middle;
  opacity: 1;
  overflow: hidden;
}
.cardSecond-bottom {
  color: #808695;
  font-size: 12px;
  margin-bottom: 4px;
}
.item-list {
  max-height: 400px;
  overflow: auto;
}
.header-info {
  padding: 0 12px !important;
}
.header-info .el-tabs__nav {
  width: 100% !important;
}
.header-info .el-tabs__nav .el-tabs__item {
  width: 50% !important;
}
.loading {
  cursor: pointer;
  color: #2d8cf0;
  text-align: center;
  line-height: 2;
}
.remove {
  border-top: 1px solid #e8eaec;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 0;
  color: #515a6e;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.dropdown-span {
  display: block !important;
  height: 60px !important;
  line-height: 60px !important;
  padding: 0 10px !important;
}
.user-span {
  height: 60px;
  display: flex;
  align-items: center;
}
/* //移动端样式 */
.mobile-header {
  width: 100%;
  height: 100%;
  margin: 0px;
  background: linear-gradient(
    to right,
    rgb(0, 213, 230) 10%,
    rgb(31, 114, 162) 100%
  );
}
.mobile-header .header-title {
  width: 100%;
  padding: 10px 0;
}
.mobile-header .header-right {
  padding-left: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
