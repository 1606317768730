<!--  -->
<template>
  <div>
    <div class="bg">
      <div class="loading" v-if="show">
        <div class="typing_loader"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    };
  },
  components: {},
  computed: {},
  methods: {}
}

</script>
<style lang='scss' scoped>
.bg {
  width: 100%;
  height: 100%;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    // background: rgba(255, 255, 255, 0.9);
    animation: bgTypeing 1s linear infinite alternate;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*Typing Loader*/
.typing_loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  // margin: 100px auto; /* Not necessary- its only for layouting*/
  position: relative;
  left: -12px;
}
// @-webkit-keyframes typing {
//   0% {
//     background-color: rgba(255, 255, 255, 1);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 0.2),
//       60px 0px 0px 0px rgba(255, 255, 255, 0.2);
//   }
//   25% {
//     background-color: rgba(255, 255, 255, 0.4);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 2),
//       60px 0px 0px 0px rgba(255, 255, 255, 0.2);
//   }
//   75% {
//     background-color: rgba(255, 255, 255, 0.4);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 0.2),
//       60px 0px 0px 0px rgba(255, 255, 255, 1);
//   }
// }

// @-moz-keyframes typing {
//   0% {
//     background-color: rgba(255, 255, 255, 1);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 0.2),
//       60px 0px 0px 0px rgba(255, 255, 255, 0.2);
//   }
//   25% {
//     background-color: rgba(255, 255, 255, 0.4);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 2),
//       60px 0px 0px 0px rgba(255, 255, 255, 0.2);
//   }
//   75% {
//     background-color: rgba(255, 255, 255, 0.4);
//     box-shadow: 40px 0px 0px 0px rgba(255, 255, 255, 0.2),
//       60px 0px 0px 0px rgba(255, 255, 255, 1);
//   }
// }

@keyframes typing {
  0% {
    background-color: rgb(134, 225, 241);
    box-shadow: 40px 0px 0px 0px rgba(134, 225, 241, 0.2),
      80px 0px 0px 0px rgba(134, 225, 241, 0.2);
  }
  25% {
    background-color: rgba(134, 225, 241, 0.4);
    box-shadow: 40px 0px 0px 0px rgba(134, 225, 241, 2),
      80px 0px 0px 0px rgba(134, 225, 241, 0.2);
  }
  75% {
    background-color: rgba(134, 225, 241, 0.4);
    box-shadow: 40px 0px 0px 0px rgba(134, 225, 241, 0.2),
      80px 0px 0px 0px rgba(134, 225, 241, 1);
  }
}
@keyframes bgTypeing {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }
  25% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
</style>