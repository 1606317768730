<template>
  <div>
    <div class="asideList">
      <h5>
        <i class="el-icon-s-grid"></i>
        个人设置
      </h5>
      <div class="uls">
        <div
          :class="{ group: true, bg: current == index }"
          v-for="(item, index) in list"
          :key="index"
          @click="tab(index)"
        >
          <div class="group-title">{{ item.name }}</div>
          <div class="group-label tipShow">{{ item.supplement }}</div>
        </div>
      </div>
    </div>
    <div class="content" v-if="current == 0">
      <h4 style="text-align:left">基本设置</h4>
      <div class="inputForm basic">
        <div class="avatar">
          <div class="inner">
            <div class="title">头像</div>
            <div class="square">
              <el-avatar shape="circle" :src="squareUrl" class="imgs"></el-avatar>
            </div>
          </div>
          <el-upload
            :limit="1"
            :action="fileUploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            name="upfile"
          >
            <div class="avatarBtn">
              <button>
                <i class="el-icon-picture-outline"></i>
                更换头像
              </button>
            </div>
          </el-upload>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
          class="demo-ruleForm"
        >
          <!-- <el-form-item label="姓名" prop="realName">
            <el-input v-model="ruleForm.realName"></el-input>
          </el-form-item>-->
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="ruleForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender" class="item gender">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio label="f">女</el-radio>
              <el-radio label="m">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="生日" prop="birthday" class="item date">
            <el-date-picker v-model="ruleForm.birthday" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="update">
          <button @click="submitForm('ruleForm')">更新信息</button>
        </div>
      </div>
    </div>
    <div class="content listContent" v-if="current == 1">
      <h4>安全设置</h4>
      <div class="safe-inner">
        <div class="safe-item" v-for="(item, index) in safeList" :key="index">
          <div class="safe-item-left">
            <h5>{{ item.title }}</h5>
            <p class="ellipsis">{{ item.content }}</p>
          </div>
          <div class="safe-item-right" @click="safeSetting(item.code)">修改</div>
        </div>
      </div>
    </div>
    <div class="content listContent" v-if="current == 2">
      <h4>账号绑定</h4>
      <div class="safe-inner">
        <div class="safe-item" v-for="(item, index) in account" :key="index">
          <div class="safe-item-left">
            <div style="height: 24px;margin-bottom:10px">
              <img src="../../assets/img/wechat.png" mode="widthFix" v-if="index==0" />
              <img src="../../assets/img/qq.png" mode="widthFix" v-if="index==1" />
              <img src="../../assets/img/qiyeweixin.png" mode="widthFix" v-if="index==3" />
              <img src="../../assets/img/dingdinglogin.png" mode="widthFix" v-if="index==2" />
              <span class="name">{{ item.name }}</span>
            </div>
            <p class="ellipsis">{{ item.content }}</p>
          </div>
          <div class="safe-item-right">绑定</div>
        </div>
      </div>
    </div>
    <div class="content listContent" v-if="current == 10">
      <div class="back" style="margin-top:-10px">
        <icon class="el-icon-arrow-left iconLeft" @click="reback"></icon>
      </div>
      <h4>修改密码</h4>
      <div class="inputForm">
        <el-form
          :model="pwdForm"
          :rules="pwdRules"
          ref="pwdForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item prop="oldPwd" label="原密码">
            <el-input v-model="pwdForm.oldPwd" type="password" placeholder="请输入原密码"></el-input>
          </el-form-item>
          <el-form-item prop="newPwd" label="新密码">
            <el-input v-model="pwdForm.newPwd" type="password" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="confirmPwd" label="确认密码">
            <el-input v-model="pwdForm.confirmPwd" type="password" placeholder="请再次确认新密码"></el-input>
          </el-form-item>
        </el-form>
        <div class="sure">
          <button @click="editPwd('pwdForm')">确认</button>
        </div>
      </div>
    </div>
    <!-- 修改手机号 -->
    <div class="content listContent" v-if="current == 11">
      <div class="back" style="margin-top:-10px">
        <icon class="el-icon-arrow-left iconLeft" @click="reback" color="#999"></icon>
      </div>
      <h4>修改手机号</h4>
      <div class="inputForm">
        <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" class="demo-ruleForm">
          <el-form-item prop="oldPhone">
            <el-input v-model="phoneForm.oldPhone" type="number" placeholder="请输入原手机号" disabled></el-input>
          </el-form-item>
          <el-form-item prop="captcha" class="captcha">
            <el-input v-model="phoneForm.captcha" type="text" placeholder="请输入验证码"></el-input>
            <el-button
              @click="codeTxt=='获取验证码'?send('oldPhone'):''"
              :disabled="codeTxt=='获取验证码'?false:true"
            >{{codeTxt}}</el-button>
          </el-form-item>
          <el-form-item prop="phone" ref="phone">
            <el-input v-model="phoneForm.phone" type="number" placeholder="请输入新手机号"></el-input>
          </el-form-item>
        </el-form>
        <div class="sure">
          <button @click="editPhone('phoneForm')">确认</button>
        </div>
      </div>
    </div>
    <!-- 修改邮箱 -->
    <div class="content listContent" v-if="current == 12">
      <div class="back" style="margin-top:-10px">
        <icon class="el-icon-arrow-left iconLeft" @click="reback" color="#999"></icon>
      </div>
      <h4>修改邮箱</h4>
      <div class="inputForm">
        <el-form
          :model="emailForm"
          :rules="emailRules"
          ref="emailForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item prop="oldemail" label="原邮箱">
            <el-input v-model="emailForm.oldemail" type="email" placeholder="请输入原邮箱" disabled></el-input>
          </el-form-item>
          <el-form-item prop="email" label="新邮箱">
            <el-input v-model="emailForm.email" type="email" placeholder="请输入新邮箱"></el-input>
          </el-form-item>
        </el-form>
        <div class="sure">
          <button @click="editemail('emailForm')">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { personInfo, personEdit, personUptPwd, personUptPhone, personGetCode } from "@/api/person/person.js";
import md5 from "crypto-js/md5";
import * as ftVal from "@/common/fortressValidator.js";
import defaultAvator from "@/assets/img/default_avatar.png"
export default {
  computed: {},
  data () {
    return {
      codeTxt: "获取验证码",
      fileUploadUrl: window.$conf.commonConf.baseUrl + "/system/oss/upload",
      account: [
        {
          picUrl: "../../assets/img/qq.png",
          name: "微信",
          content: "当前未绑定微信账号",
        },
        {
          picUrl:
            "../../assets/img/qq.png",
          name: "QQ",
          content: "当前未绑定QQ账号",
        },
        {
          picUrl:
            "../../assets/img/dingdinglogin.png",
          name: "钉钉",
          content: "当前未绑定钉钉账号",
        },
        {
          picUrl:
            "../../assets/img/qiyeweixin.png",
          name: "企业微信",
          content: "当前未绑定企业微信账号",
        },
      ],
      current: 0,
      value01: false,
      value02: false,
      squareUrl: "",
      ruleForm: {},
      rules: {
        // realName: [
        // { required: true, message: "请输入中文", trigger: "blur" },
        // { validator: ftVal.validateChinese, trigger: "blur" }
        // ],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "请选择出生日期", trigger: "blur" },
        ],

      },
      oldPwd: "",
      //密码修改
      pwdForm: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
      },
      pwdRules: {
        oldPwd: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        confirmPwd: [
          { required: true, message: "请确认新密码", trigger: "blur" },
        ],
      },
      phoneForm:
      {
        id: JSON.parse(localStorage.getItem("userInfo")).id,
        phone: "",
        oldPhone: "",
        captcha: "",
      }
      ,
      phoneRules: {
        oldPhone: [{ required: true, message: "请输入原手机号", trigger: "blur" }, { validator: ftVal.validatePhone, trigger: "blur" }],
        phone: [{ required: true, message: "请输入新手机号", trigger: "blur" }, { validator: ftVal.validatePhone, trigger: "blur" }],
        captcha: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      emailForm: {
        oldemail: "",
        email: "",
      },
      emailRules: {
        oldemail: [{ required: true, message: "请输入原邮箱", trigger: "blur" }, { validator: ftVal.validateEMail, trigger: "blur" }],
        email: [{ required: true, message: "请输入新邮箱", trigger: "blur" }, { validator: ftVal.validateEMail, trigger: "blur" }]
      },
      list: [
        { name: "基本设置", supplement: "个人账户信息设置" },
        { name: "安全设置", supplement: "密码、邮箱等设置" },
        { name: "账号绑定", supplement: "绑定第三方社交账户" },
      ],
      safeList: [
        {
          code: "pwd",
          title: "账户密码",
          content: "绑定手机和邮箱，并设置密码，帐号更安全",
        },
        {
          code: "phone",
          title: "绑定手机",
          content: "",
        },
        {
          code: "email",
          title: "绑定邮箱",
          content: "",
        },
      ],

    };
  },
  created () {
    this.user();
  },
  activated () {
    this.user()
  },
  methods: {
    //发送验证码
    send (name) {
      let time = 60
      this.$refs.phoneForm.validateField(name, (errMsg) => {
        if (errMsg) {
          console.log("未通过");
        }
        else {
          this.codeTxt = time + "秒后重发"
          personGetCode({
            phone: this.phoneForm.oldPhone
          }).then(() => {

            let timer = setInterval(() => {
              time--
              if (time > 0) {
                this.codeTxt = time + "秒后重发"
              }
              else {
                clearInterval(timer)
                time = 60
                this.codeTxt = "获取验证码"
              }
            }, 1000)
          })
        }
      })

    },
    tab (index) {
      this.current = index;
      this.phoneForm.id = JSON.parse(localStorage.getItem("userInfo")).id
      this.phoneForm.phone = ''
      this.captcha = ''
      this.emailForm.email = ""
    },
    //更新用户信息
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          personEdit({
            ...this.ruleForm,
          }).then((resp) => {
            if (resp.code == 0) {
              this.user();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    //上传图片
    //上传成功
    handleAvatarSuccess (res, file) {
      if (res && res.code == 0) {
        this.ruleForm.avatar = res.data.path;
        this.squareUrl = res.data.url;
      }
    },
    beforeAvatarUpload (file) {
      //   const isJPG = file.type === "image/jpeg";
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      //   if (!isLt2M) {
      //     this.$message.error("上传头像图片大小不能超过 2MB!");
      //   }
      //   return isJPG && isLt2M;
    },
    //当前用户
    user () {
      personInfo().then((resp) => {
        if (resp.code == 0) {
          this.$nextTick(() => {
            this.ruleForm = {
              ...this.ruleForm,
              ...resp.data,
            };
            this.safeList[1].content = "已绑定手机号：+86" + this.ruleForm.phone.substring(0, 3) + "****" + this.ruleForm.phone.substring(7, 11)
            this.safeList[2].content = "已绑定邮箱：" + this.ruleForm.email
            this.phoneForm.oldPhone = this.ruleForm.phone
            this.emailForm.oldemail = this.ruleForm.email
            if (this.ruleForm.avatar) {
              this.squareUrl =
                this.$store.state.prefixUrl + this.ruleForm.avatar;
            }
            else {
              this.squareUrl = defaultAvator
            }
            this.oldPwd = resp.data.password;
          });
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //安全设置切换
    safeSetting (code) {
      //账户密码
      if ("pwd" == code) {
        this.pwdForm.oldPwd = "";
        this.pwdForm.newPwd = "";
        this.pwdForm.confirmPwd = "";
        this.current = 10;
        //绑定手机
      } else if ("phone" == code) {
        this.current = 11;
        //密保问题
      }
      else if ("email" == code) {
        this.current = 12;
        //密保问题
      }
    },
    //返回上级目录
    reback () {
      this.phoneForm.id = JSON.parse(localStorage.getItem("userInfo")).id
      this.phoneForm.phone = ''
      this.captcha = ''
      this.emailForm.email = ""
      this.current = 1;
      if (this.current == 1) {
        this.user()
      }
    },

    //修改手机号
    editPhone (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          personUptPhone({
            ...this.phoneForm,

          }).then((resp) => {
            if (resp.code == 0) {
              this.reback();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改邮箱提交
    editemail (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.oldemail = this.emailForm.oldemail;
          this.ruleForm.email = this.emailForm.email;
          personEdit({
            ...this.ruleForm,
          }).then((resp) => {
            if (resp.code == 0) {
              this.reback();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改密码
    editPwd (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.pwdForm.newPwd != this.pwdForm.confirmPwd) {
            this.$msg({
              message: "两次密码输入不一致，请重新输入",
              type: "error",
            });
            return false;
          }
          this.ruleForm.oldPassword = md5(this.pwdForm.oldPwd).toString();
          this.ruleForm.password = md5(this.pwdForm.newPwd).toString();
          personUptPwd({
            ...this.ruleForm,
          }).then((resp) => {
            if (resp.code == 0) {
              this.reback();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },

};
</script>
<style scoped>
/* //返回箭头 */
.iconLeft {
  color: #999;
  background: #eee;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.asideList {
  width: 25%;
  float: left;
  background-color: #fff;
  border-radius: 4px;
}
.asideList h5 {
  padding: 10px 0 10px 20px;
  margin-bottom: 20px;
  font-size: 20px;
}
.group {
  margin-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
}
.bg {
  background-color: rgba(121, 221, 228, 0.1);
  color: rgb(31, 114, 162);
}
.uls .bg .group-label {
  color: rgb(31, 114, 162);
}
.group .group-title {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  box-sizing: border-box;
}
.group .group-label {
  line-height: 1.2;
  font-size: 12px;
  color: #808695;
}
.content {
  padding: 40px 20px;
  /* border-left: 1px solid #eee; */
  width: 74%;
  float: right;
  background-color: #fff;
  border-radius: 4px;
}
.content /deep/ .el-form {
  /* width: 50%; */
  max-width: 440px;
}
.listContent /deep/ .el-form {
  margin: 0 auto;
}
.el-form /deep/ .el-form-item__label {
  padding: 0;
}
.el-form /deep/ .el-form-item {
  margin-bottom: 20px;
}
.content h4 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.content .btn {
  width: 100%;
  text-align: center;
}

.avatar {
  max-width: 440px;
  margin-bottom: 20px;
  /* width: 100%; */
  /* height: 120px; */
}
@media screen and (min-width: 1200px) {
  .avatar {
    position: absolute;
    left: 500px;
    top: 16px;
  }
}
.avatar .inner {
  margin-bottom: 10px;
  width: 100%;
}
.inner .title {
  height: 30px;
  /* float: left; */
  width: 100px;
  text-align: left;
  font-size: 14px;
  color: #606266;
}
.inner .square {
  width: 100px;
  height: 100px;
  /* float: left; */
}
.square .imgs {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.avatarBtn {
  width: 100px;
  height: 30px;
  line-height: 30px;
}
.avatarBtn button {
  width: 100px;
  height: 30px;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 14px;
}
.avatarBtn button:hover {
  color: rgb(31, 114, 162);
  border: 1px solid rgb(31, 114, 162);
}
.date /deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.gender /deep/ .el-radio-group {
  vertical-align: baseline;
}
.basic {
  position: relative;
}
.basic .update button {
  /* margin-left: 100px; */
  width: 120px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  background: rgb(31, 114, 162);
  border-radius: 4px;
}
.update button:hover {
  opacity: 0.8;
}
.captcha /deep/ .el-input {
  width: calc(100% - 124px);
}
.captcha /deep/ .el-button {
  margin: 0 0 0 10px;
}
.safe-inner .safe-item {
  padding-bottom: 20px;
  padding-right: 14px;
  margin-bottom: 20px;
  /* height: 70px;
  line-height: 50px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-items: center; */
  border-bottom: 1px solid #eee;
}
.safe-item-left {
  flex: 1;
  overflow: hidden;
  /* float: left; */
}
.safe-item-right {
  min-width: 30px;
  /* float: right; */
  cursor: pointer;
}
.safe-item-left h5 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.safe-item-left p {
  font-size: 14px;
  color: #808695;
}
.safe-item-right {
  font-size: 14px;
  color: rgb(31, 114, 162);
}
.safe-item-left img {
  margin-right: 10px;
  /* width: 24px; */
  height: 24px;
}
.safe-item-left .name {
  font-size: 14px;
}
.tipShow {
  display: block;
}
.sure {
  padding-top: 10px;
  text-align: center;
}
.sure /deep/ button {
  height: 40px;
  max-width: 440px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: #0cafcc;
  border-radius: 4px;
  width: 100%;
}
/* .inputForm /deep/ .el-button:hover,
.el-button:focus {
  color: transparent;
  border-color: transparent;
  background-color: transparent;
} */
/* 个人设置 */
@media screen and (max-width: 790px) {
  .asideList {
    width: 100%;
    margin-bottom: 10px;
  }
  .content {
    width: 100%;
  }
  .uls {
    padding: 0 16px;
    display: flex;
  }
  .group {
    padding: 0 5px !important;
    min-width: 40px;
  }
  .group-title {
    /* min-width: 40px; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
  }
  .tipShow {
    display: none;
  }
}
</style>
