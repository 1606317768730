import request from "@/common/request"

//获取当前用户可操作菜单
export const optMenuList = () => {
    return request("get", "/system/menu/list");
}
//菜单详情
export const menuInfo = (id) => {
    return request('get', '/system/menu/info', {
        id: id
    })
}
//保存菜单信息
export const menuSave = (data) => {
    return request('post', '/system/menu/save', data)
}
//修改菜单信息
export const menuEdit = (data) => {
    return request('post', '/system/menu/edit', data)
}
//删除菜单信息
export const menuDel = (id) => {
    return request('post', '/system/menu/delete?id=' + id)
}
