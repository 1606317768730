<template>
	<el-cascader
		:ref="ref"
		v-if="visible"
		v-model="currentRegion"
		:options="options"
		:props="cascaderProps"
		filterable
		:clearable="clearable"
		:placeholder="placeholder"
		@change="changeHandler"
	></el-cascader>
</template>
<script>
import { sysregionTree } from "@/api/system/sysregion.js"
export default {
	name: "FortressRegion",
	props: {
		placeholder: String,
		clearable: Boolean,
	},
	data() {
		return {
			visible: false,
			ref: "ft_region_" + parseInt(Math.random() * 10000),
			currentRegion: [],
			options: [],
			cascaderProps: {
				value: "id",
				label: "name",
				children: "children",
			},
			nodes: [],
		}
	},
	created() {
		this.visible = true
		sysregionTree().then(resp => {
			this.options = resp.data.map(pro => {
				pro.children.map(city => {
					city.children.unshift({
						id: "-1",
						name: "全境",
					})
					return city
				})
				return pro
			})
		})
	},
	methods: {
		init(regions) {
			this.$refs[this.ref].$refs.panel.activePath = []
			this.currentRegion = regions
			this.nodes = []
			if (regions.length > 0 && regions.length == 3) {
				let provinceD = this.options.find(item => {
					return item.id == regions[0]
				})
				if (provinceD) {
					this.nodes.push({ id: provinceD.id, name: provinceD.name })
					let cityD = provinceD.children.find(item => {
						return item.id == regions[1]
					})
					if (cityD) {
						this.nodes.push({ id: cityD.id, name: cityD.name })
						let areaD = cityD.children.find(item => {
							return item.id == regions[2]
						})
						if (areaD) {
							this.nodes.push({ id: areaD.id, name: areaD.name })
						}
					}
				}
			}
		},
		hide() {
			this.visible = false
		},
		changeHandler(val) {
			console.log("val: ", val)
			if (val.length > 0 && val.length == 3) {
				// let node = this.$refs[this.ref].getCheckedNodes()
				// console.log(node)
				// if (node.length > 0) {
				// 	this.nodes = []
				// 	let areaD = node[0]
				// 	let cityD = areaD.parent
				// 	let provinceD = cityD.parent
				// 	this.nodes.push({ id: provinceD.value, name: provinceD.label })
				// 	this.nodes.push({ id: cityD.value, name: cityD.label })
				// 	this.nodes.push({ id: areaD.value, name: areaD.label })
				// } else {
				// 	console.log("else")
				// 	let provinceD = this.options.find(item => {
				// 		return item.id == val[0]
				// 	})
				// 	if (provinceD) {
				// 		this.nodes.push({ id: provinceD.id, name: provinceD.name })
				// 		let cityD = provinceD.children.find(item => {
				// 			return item.id == val[1]
				// 		})
				// 		if (cityD) {
				// 			this.nodes.push({ id: cityD.id, name: cityD.name })
				// 			let areaD = cityD.children.find(item => {
				// 				return item.id == val[2]
				// 			})
				// 			if (areaD) {
				// 				this.nodes.push({ id: areaD.id, name: areaD.name })
				// 			}
				// 		}
				// 	}
				// }

				this.nodes = []
				let provinceD = this.options.find(item => {
					return item.id == val[0]
				})
				if (provinceD) {
					this.nodes.push({ id: provinceD.id, name: provinceD.name })
					let cityD = provinceD.children.find(item => {
						return item.id == val[1]
					})
					if (cityD) {
						this.nodes.push({ id: cityD.id, name: cityD.name })
						let areaD = cityD.children.find(item => {
							return item.id == val[2]
						})
						if (areaD) {
							this.nodes.push({ id: areaD.id, name: areaD.name })
						}
					}
				}
			} else {
				this.nodes = []
			}
			this.$emit("ftregion", this.nodes, this.nodes)
		},
	},
}
</script>
