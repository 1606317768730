<template>
  <div :class="['search', className]">
    <el-autocomplete
      class="inline-input"
      v-model="state2"
      size="medium"
      :fetch-suggestions="querySearch"
      :placeholder="placeholder"
      :trigger-on-focus="false"
      prefix-icon="el-icon-search"
      @select="handleSelect"
    ></el-autocomplete>
    <div class="close" @click="state2 = ''" v-if="state2 != ''">
      <img src="../../assets/img/close.png" alt />
    </div>
  </div>
</template>

<script>
import vPinyin from "../../../public/pinyin/pinyin2.js";
export default {
  name: "FortressSearch",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    lable: {
      type: String,
      default: "name",
    },
    value: [Number, String] || "",
    placeholder: {
      type: String,
      default: "根据关键字搜索",
    },
    className: {
      type: String,
      default: "dafaultClass",
    },
  },
  data() {
    return {
      allData: [],
      filterData: [],
      state2: "",
    };
  },
  mounted() {
    var data = this.data;
    this.state2 = this.value;
    this.getData(data);
  },
  methods: {
    async getData(data) {
      await this.refactorData(data, this.allData);
      await this.allData.forEach((item) => {
        let pinyin = vPinyin.chineseToPinYin(item[this.lable]);
        let sx = vPinyin.initials(pinyin);
        //根据情况而定
        this.filterData.push({
          value: item[this.lable],
          pinyin: pinyin,
          sx: sx,
          path: item.viewPath,
        });
      });
    },
    //数据重构
    refactorData(data, obj) {
      let children = [];
      for (var i in data) {
        if (data[i].viewPath) {
          obj.push(data[i]);
        }
        if (data[i].children) {
          for (var j in data[i].children) {
            children.push(data[i].children[j]);
          }
        }
      }
      if (children.length > 0) {
        this.refactorData(children, obj);
      }
    },
    querySearch(queryString, cb) {
      var filterData = this.filterData;
      var results = queryString
        ? filterData.filter(this.createFilter(queryString))
        : filterData;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (filterData) => {
        const searchValueReg = new RegExp(queryString.toLowerCase(), "gi");
        return (
          searchValueReg.test(filterData.value.toLowerCase()) ||
          searchValueReg.test(filterData.pinyin.toLowerCase()) ||
          searchValueReg.test(filterData.sx.toLowerCase())
        );
      };
    },
    handleSelect(item) {
      this.$emit("select", item);
      // this.$router.push({ path: item.path })
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  position: relative;
  & /deep/ .el-input__inner {
    height: 32px;
    line-height: 28px;
    background: transparent;
  }
}
.dafaultClass {
  & /deep/ .el-input__inner {
    color: #dcdfe6;
    border: 1px solid #dcdfe6;
  }
  & /deep/ .el-input__icon {
    color: #dcdfe6;
  }
  & /deep/ input::-webkit-input-placeholder {
    color: #dcdfe6;
  }
  & /deep/ input::-moz-input-placeholder {
    color: #dcdfe6;
  }
  & /deep/ input::-ms-input-placeholder {
    color: #dcdfe6;
  }
}
.whiteClass {
  & /deep/ .el-input__inner {
    color: #fff;
    border: 1px solid #fff;
  }
  & /deep/ .el-input__icon {
    color: #fff;
  }
  & /deep/ input::-webkit-input-placeholder {
    color: #fff;
  }
  & /deep/ input::-moz-input-placeholder {
    color: #fff;
  }
  & /deep/ input::-ms-input-placeholder {
    color: #fff;
  }
}
.close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
