/* eslint-disable no-undef */
/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable spaced-comment */
/* eslint-disable no-unreachable */
/* eslint-disable one-var */
/* eslint-disable eqeqeq */
var Pinyin = {}
Pinyin._JMcode = {
  '-': '',
  '—': '',
  '"': '',
  '#': '',
  '%': '',
  '(': '',
  ')': '',
  '*': '',
  ',': '',
  '、': '',
  '.': '',
  '/': '',
  '：': '',
  '；': '',
  '\\': '',
  '`': '',
  '“': '',
  '”': '',
  '+': '',
  '0': '0',
  '1': '1',
  ⅰ: 'Y',
  '2': '2',
  ⅱ: 'E',
  '3': '3',
  Ⅲ: 'S',
  '4': '4',
  Ⅳ: 'S',
  '5': '5',
  Ⅴ: 'W',
  '6': '6',
  Ⅵ: 'L',
  '7': '7',
  Ⅶ: 'Q',
  '8': '8',
  Ⅷ: 'B',
  '9': '9',
  Ⅸ: 'J',
  Ⅹ: 'S',
  Ⅺ: 'S',
  Ⅻ: 'S',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  吖: 'A',
  阿: 'A',
  啊: 'A',
  锕: 'A',
  嗄: 'A',
  哎: 'A',
  哀: 'A',
  唉: 'A',
  埃: 'A',
  挨: 'A',
  锿: 'A',
  捱: 'A',
  皑: 'A',
  癌: 'A',
  嗳: 'A',
  矮: 'A',
  蔼: 'A',
  霭: 'A',
  艾: 'A',
  爱: 'A',
  砹: 'A',
  隘: 'A',
  嗌: 'A',
  嫒: 'A',
  碍: 'A',
  暧: 'A',
  瑷: 'A',
  安: 'A',
  桉: 'A',
  氨: 'A',
  庵: 'A',
  谙: 'A',
  鹌: 'A',
  鞍: 'A',
  俺: 'A',
  埯: 'A',
  铵: 'A',
  揞: 'A',
  犴: 'A',
  岸: 'A',
  按: 'A',
  案: 'A',
  胺: 'A',
  暗: 'A',
  黯: 'A',
  肮: 'A',
  昂: 'A',
  盎: 'A',
  凹: 'A',
  坳: 'A',
  敖: 'A',
  嗷: 'A',
  廒: 'A',
  獒: 'A',
  遨: 'A',
  熬: 'A',
  翱: 'A',
  聱: 'A',
  螯: 'A',
  鳌: 'A',
  鏖: 'A',
  拗: 'A',
  袄: 'A',
  媪: 'A',
  岙: 'A',
  傲: 'A',
  奥: 'A',
  骜: 'A',
  澳: 'A',
  懊: 'A',
  鏊: 'A',
  八: 'B',
  巴: 'B',
  叭: 'B',
  扒: 'B',
  吧: 'B',
  岜: 'B',
  芭: 'B',
  疤: 'B',
  捌: 'B',
  笆: 'B',
  粑: 'B',
  拔: 'B',
  茇: 'B',
  菝: 'B',
  跋: 'B',
  魃: 'B',
  把: 'B',
  钯: 'B',
  靶: 'B',
  坝: 'B',
  爸: 'B',
  罢: 'B',
  鲅: 'B',
  霸: 'B',
  灞: 'B',
  掰: 'B',
  白: 'B',
  百: 'B',
  佰: 'B',
  柏: 'B',
  捭: 'B',
  摆: 'B',
  呗: 'B',
  败: 'B',
  拜: 'B',
  稗: 'B',
  扳: 'B',
  班: 'B',
  般: 'B',
  颁: 'B',
  斑: 'B',
  搬: 'B',
  瘢: 'B',
  癍: 'B',
  阪: 'B',
  坂: 'B',
  板: 'B',
  版: 'B',
  钣: 'B',
  舨: 'B',
  办: 'B',
  半: 'B',
  伴: 'B',
  扮: 'B',
  拌: 'B',
  绊: 'B',
  瓣: 'B',
  邦: 'B',
  帮: 'B',
  梆: 'B',
  浜: 'B',
  绑: 'B',
  榜: 'B',
  膀: 'P',
  蚌: 'B',
  傍: 'B',
  棒: 'B',
  谤: 'B',
  蒡: 'B',
  磅: 'B',
  镑: 'B',
  包: 'B',
  孢: 'B',
  苞: 'B',
  胞: 'B',
  煲: 'B',
  龅: 'B',
  褒: 'B',
  雹: 'B',
  宝: 'B',
  饱: 'B',
  保: 'B',
  鸨: 'B',
  堡: 'B',
  葆: 'B',
  褓: 'B',
  报: 'B',
  抱: 'B',
  豹: 'B',
  趵: 'B',
  鲍: 'B',
  暴: 'B',
  爆: 'B',
  陂: 'B',
  卑: 'B',
  杯: 'B',
  悲: 'B',
  碑: 'B',
  鹎: 'B',
  北: 'B',
  贝: 'B',
  狈: 'B',
  邶: 'B',
  备: 'B',
  背: 'B',
  钡: 'B',
  倍: 'B',
  悖: 'B',
  被: 'B',
  惫: 'B',
  焙: 'B',
  辈: 'B',
  碚: 'B',
  蓓: 'B',
  褙: 'B',
  鞴: 'B',
  鐾: 'B',
  奔: 'B',
  贲: 'B',
  锛: 'B',
  本: 'B',
  苯: 'B',
  畚: 'B',
  坌: 'B',
  笨: 'B',
  崩: 'B',
  绷: 'B',
  嘣: 'B',
  甭: 'B',
  泵: 'B',
  迸: 'B',
  甏: 'B',
  蹦: 'B',
  逼: 'B',
  荸: 'B',
  鼻: 'B',
  匕: 'B',
  比: 'B',
  吡: 'B',
  妣: 'B',
  彼: 'B',
  秕: 'B',
  俾: 'B',
  笔: 'B',
  舭: 'B',
  鄙: 'B',
  币: 'B',
  必: 'B',
  毕: 'B',
  闭: 'B',
  庇: 'B',
  畀: 'B',
  哔: 'B',
  毖: 'B',
  荜: 'B',
  陛: 'B',
  毙: 'B',
  狴: 'B',
  铋: 'B',
  婢: 'B',
  庳: 'B',
  敝: 'B',
  萆: 'B',
  弼: 'B',
  愎: 'B',
  筚: 'B',
  滗: 'B',
  痹: 'B',
  蓖: 'B',
  裨: 'B',
  跸: 'B',
  辟: 'B',
  弊: 'B',
  碧: 'B',
  箅: 'B',
  蔽: 'B',
  壁: 'B',
  嬖: 'B',
  篦: 'B',
  薜: 'B',
  避: 'B',
  濞: 'B',
  臂: 'B',
  髀: 'B',
  璧: 'B',
  襞: 'B',
  边: 'B',
  砭: 'B',
  笾: 'B',
  编: 'B',
  煸: 'B',
  蝙: 'B',
  鳊: 'B',
  鞭: 'B',
  贬: 'B',
  扁: 'B',
  窆: 'B',
  匾: 'B',
  碥: 'B',
  褊: 'B',
  卞: 'B',
  弁: 'B',
  忭: 'B',
  汴: 'B',
  苄: 'B',
  拚: 'P',
  便: 'B',
  变: 'B',
  缏: 'B',
  遍: 'B',
  辨: 'B',
  辩: 'B',
  辫: 'B',
  杓: 'B',
  彪: 'B',
  标: 'B',
  飑: 'B',
  髟: 'B',
  骠: 'B',
  膘: 'B',
  瘭: 'B',
  镖: 'B',
  飙: 'B',
  飚: 'B',
  镳: 'B',
  表: 'B',
  婊: 'B',
  裱: 'B',
  鳔: 'B',
  憋: 'B',
  鳖: 'B',
  别: 'B',
  蹩: 'B',
  瘪: 'B',
  宾: 'B',
  彬: 'B',
  傧: 'B',
  斌: 'B',
  滨: 'B',
  缤: 'B',
  槟: 'B',
  镔: 'B',
  濒: 'B',
  豳: 'B',
  摈: 'B',
  殡: 'B',
  膑: 'B',
  髌: 'B',
  鬓: 'B',
  冰: 'B',
  兵: 'B',
  丙: 'B',
  邴: 'B',
  秉: 'B',
  柄: 'B',
  炳: 'B',
  饼: 'B',
  禀: 'B',
  并: 'B',
  病: 'B',
  摒: 'B',
  拨: 'B',
  波: 'B',
  玻: 'B',
  剥: 'B',
  钵: 'B',
  饽: 'B',
  啵: 'B',
  脖: 'B',
  菠: 'B',
  播: 'B',
  伯: 'B',
  孛: 'B',
  驳: 'B',
  帛: 'B',
  泊: 'B',
  勃: 'B',
  亳: 'B',
  钹: 'B',
  铂: 'B',
  舶: 'B',
  博: 'B',
  渤: 'B',
  鹁: 'B',
  搏: 'B',
  箔: 'B',
  膊: 'B',
  踣: 'B',
  薄: 'B',
  礴: 'B',
  跛: 'B',
  簸: 'B',
  擘: 'B',
  檗: 'B',
  逋: 'B',
  钸: 'B',
  晡: 'B',
  醭: 'B',
  卜: 'B',
  卟: 'B',
  补: 'B',
  哺: 'B',
  捕: 'B',
  不: 'B',
  布: 'B',
  步: 'B',
  怖: 'B',
  钚: 'B',
  部: 'B',
  埠: 'B',
  瓿: 'B',
  簿: 'B',
  嚓: 'C',
  擦: 'C',
  礤: 'C',
  猜: 'C',
  才: 'C',
  材: 'C',
  财: 'C',
  裁: 'C',
  采: 'C',
  彩: 'C',
  睬: 'C',
  踩: 'C',
  菜: 'C',
  蔡: 'C',
  参: 'S',
  骖: 'C',
  傪: 'C',
  餐: 'C',
  残: 'C',
  蚕: 'C',
  惭: 'C',
  惨: 'C',
  黪: 'C',
  灿: 'C',
  粲: 'C',
  璨: 'C',
  仓: 'C',
  伧: 'C',
  沧: 'C',
  苍: 'C',
  舱: 'C',
  藏: 'C',
  操: 'C',
  糙: 'C',
  曹: 'C',
  嘈: 'C',
  漕: 'C',
  槽: 'C',
  艚: 'C',
  螬: 'C',
  草: 'C',
  册: 'C',
  侧: 'C',
  厕: 'C',
  恻: 'C',
  测: 'C',
  策: 'C',
  岑: 'C',
  涔: 'C',
  噌: 'C',
  层: 'C',
  蹭: 'C',
  叉: 'C',
  杈: 'C',
  插: 'C',
  馇: 'C',
  锸: 'C',
  查: 'C',
  茬: 'C',
  茶: 'C',
  搽: 'C',
  猹: 'S',
  槎: 'C',
  察: 'C',
  碴: 'C',
  檫: 'C',
  衩: 'C',
  镲: 'C',
  汊: 'C',
  岔: 'C',
  诧: 'C',
  姹: 'C',
  差: 'C',
  拆: 'C',
  钗: 'C',
  侪: 'C',
  柴: 'C',
  豺: 'C',
  虿: 'C',
  瘥: 'C',
  觇: 'C',
  掺: 'C',
  搀: 'C',
  婵: 'C',
  谗: 'C',
  孱: 'C',
  禅: 'C',
  馋: 'C',
  缠: 'C',
  蝉: 'C',
  廛: 'C',
  潺: 'C',
  镡: 'C',
  蟾: 'C',
  躔: 'C',
  产: 'C',
  谄: 'C',
  铲: 'C',
  阐: 'C',
  蒇: 'C',
  冁: 'C',
  忏: 'C',
  颤: 'C',
  羼: 'C',
  伥: 'C',
  昌: 'C',
  娼: 'C',
  猖: 'C',
  菖: 'C',
  阊: 'C',
  鲳: 'C',
  长: 'C',
  肠: 'C',
  苌: 'C',
  尝: 'C',
  偿: 'C',
  常: 'C',
  徜: 'C',
  嫦: 'C',
  厂: 'C',
  场: 'C',
  昶: 'C',
  惝: 'C',
  敞: 'C',
  氅: 'C',
  怅: 'C',
  畅: 'C',
  倡: 'C',
  鬯: 'C',
  唱: 'C',
  抄: 'C',
  怊: 'C',
  钞: 'C',
  焯: 'Z',
  超: 'C',
  晁: 'C',
  巢: 'C',
  朝: 'C',
  嘲: 'C',
  潮: 'C',
  吵: 'C',
  炒: 'C',
  耖: 'C',
  车: 'C',
  砗: 'C',
  扯: 'C',
  彻: 'C',
  坼: 'C',
  掣: 'C',
  撤: 'C',
  澈: 'C',
  抻: 'C',
  郴: 'C',
  琛: 'C',
  嗔: 'C',
  尘: 'C',
  臣: 'C',
  忱: 'C',
  沉: 'C',
  辰: 'C',
  陈: 'C',
  宸: 'C',
  晨: 'C',
  谌: 'C',
  碜: 'C',
  闯: 'C',
  衬: 'C',
  称: 'C',
  龀: 'C',
  趁: 'C',
  榇: 'C',
  谶: 'C',
  柽: 'C',
  蛏: 'C',
  铛: 'D',
  撑: 'C',
  瞠: 'C',
  丞: 'C',
  成: 'C',
  呈: 'C',
  承: 'C',
  枨: 'C',
  诚: 'C',
  城: 'C',
  乘: 'C',
  埕: 'C',
  铖: 'C',
  惩: 'C',
  程: 'C',
  裎: 'C',
  塍: 'C',
  酲: 'C',
  澄: 'C',
  橙: 'C',
  逞: 'C',
  骋: 'C',
  秤: 'C',
  吃: 'C',
  哧: 'C',
  蚩: 'C',
  鸱: 'C',
  眵: 'C',
  笞: 'C',
  嗤: 'C',
  媸: 'C',
  痴: 'C',
  螭: 'C',
  魑: 'C',
  弛: 'C',
  池: 'C',
  驰: 'C',
  迟: 'C',
  茌: 'C',
  持: 'C',
  匙: 'C',
  墀: 'C',
  踟: 'C',
  篪: 'C',
  尺: 'C',
  侈: 'C',
  齿: 'C',
  耻: 'C',
  豉: 'C',
  褫: 'C',
  彳: 'C',
  叱: 'C',
  斥: 'C',
  赤: 'C',
  饬: 'C',
  炽: 'C',
  翅: 'C',
  敕: 'C',
  啻: 'C',
  傺: 'C',
  瘛: 'C',
  充: 'C',
  冲: 'C',
  忡: 'C',
  茺: 'C',
  舂: 'C',
  憧: 'C',
  艟: 'C',
  虫: 'C',
  崇: 'C',
  宠: 'C',
  铳: 'C',
  抽: 'C',
  瘳: 'C',
  仇: 'C',
  俦: 'C',
  帱: 'C',
  惆: 'C',
  绸: 'C',
  畴: 'C',
  愁: 'C',
  稠: 'C',
  筹: 'C',
  酬: 'C',
  踌: 'C',
  雠: 'C',
  丑: 'C',
  瞅: 'C',
  臭: 'C',
  出: 'C',
  初: 'C',
  樗: 'C',
  刍: 'C',
  除: 'C',
  厨: 'C',
  滁: 'C',
  锄: 'C',
  蜍: 'C',
  雏: 'C',
  橱: 'C',
  躇: 'C',
  蹰: 'C',
  杵: 'C',
  础: 'C',
  储: 'C',
  楮: 'C',
  楚: 'C',
  褚: 'C',
  亍: 'C',
  处: 'C',
  怵: 'C',
  绌: 'C',
  搐: 'C',
  触: 'C',
  憷: 'C',
  黜: 'C',
  矗: 'C',
  搋: 'C',
  揣: 'C',
  啜: 'C',
  嘬: 'Z',
  踹: 'C',
  川: 'C',
  氚: 'C',
  穿: 'C',
  传: 'C',
  舡: 'C',
  船: 'C',
  遄: 'C',
  椽: 'C',
  舛: 'C',
  喘: 'C',
  串: 'C',
  钏: 'C',
  囱: 'C',
  疮: 'C',
  窗: 'C',
  床: 'C',
  创: 'C',
  怆: 'C',
  吹: 'C',
  炊: 'C',
  垂: 'C',
  陲: 'C',
  捶: 'C',
  棰: 'C',
  槌: 'C',
  锤: 'C',
  春: 'C',
  椿: 'C',
  蝽: 'C',
  纯: 'C',
  唇: 'C',
  莼: 'C',
  淳: 'C',
  鹑: 'C',
  醇: 'C',
  蠢: 'C',
  踔: 'C',
  戳: 'C',
  绰: 'C',
  辍: 'C',
  龊: 'C',
  呲: 'C',
  疵: 'C',
  词: 'C',
  祠: 'C',
  茈: 'C',
  茨: 'C',
  瓷: 'C',
  慈: 'C',
  辞: 'C',
  磁: 'C',
  雌: 'C',
  鹚: 'C',
  糍: 'C',
  此: 'C',
  次: 'C',
  刺: 'C',
  赐: 'C',
  从: 'C',
  匆: 'C',
  苁: 'C',
  枞: 'C',
  葱: 'C',
  骢: 'C',
  璁: 'C',
  聪: 'C',
  丛: 'C',
  淙: 'C',
  琮: 'C',
  凑: 'C',
  楱: 'C',
  腠: 'C',
  辏: 'C',
  粗: 'C',
  徂: 'C',
  殂: 'C',
  促: 'C',
  猝: 'C',
  酢: 'C',
  蔟: 'C',
  醋: 'C',
  簇: 'C',
  蹙: 'C',
  蹴: 'C',
  汆: 'C',
  撺: 'C',
  镩: 'C',
  蹿: 'C',
  窜: 'C',
  篡: 'C',
  爨: 'C',
  崔: 'C',
  催: 'C',
  摧: 'C',
  榱: 'C',
  璀: 'C',
  脆: 'C',
  啐: 'C',
  悴: 'C',
  淬: 'C',
  萃: 'C',
  毳: 'C',
  瘁: 'C',
  粹: 'C',
  翠: 'C',
  村: 'C',
  皴: 'C',
  存: 'C',
  忖: 'C',
  寸: 'C',
  搓: 'C',
  磋: 'C',
  撮: 'C',
  蹉: 'C',
  嵯: 'C',
  痤: 'C',
  矬: 'C',
  鹾: 'C',
  脞: 'C',
  厝: 'C',
  挫: 'C',
  措: 'C',
  锉: 'C',
  错: 'C',
  哒: 'D',
  耷: 'D',
  搭: 'D',
  嗒: 'D',
  褡: 'D',
  达: 'D',
  妲: 'D',
  怛: 'D',
  沓: 'T',
  笪: 'D',
  答: 'D',
  瘩: 'D',
  靼: 'D',
  鞑: 'D',
  打: 'D',
  大: 'D',
  呆: 'D',
  呔: 'D',
  歹: 'D',
  傣: 'D',
  代: 'D',
  岱: 'D',
  甙: 'D',
  绐: 'D',
  迨: 'D',
  带: 'D',
  待: 'D',
  怠: 'D',
  殆: 'D',
  玳: 'D',
  贷: 'D',
  埭: 'D',
  袋: 'D',
  逮: 'D',
  戴: 'D',
  黛: 'D',
  丹: 'D',
  单: 'D',
  担: 'D',
  眈: 'D',
  耽: 'D',
  郸: 'D',
  聃: 'D',
  殚: 'D',
  瘅: 'D',
  箪: 'D',
  儋: 'D',
  胆: 'D',
  疸: 'D',
  掸: 'D',
  旦: 'D',
  但: 'D',
  诞: 'D',
  啖: 'D',
  弹: 'D',
  惮: 'D',
  淡: 'D',
  萏: 'D',
  蛋: 'D',
  氮: 'D',
  澹: 'T',
  当: 'D',
  裆: 'D',
  挡: 'D',
  党: 'D',
  谠: 'D',
  凼: 'D',
  宕: 'D',
  砀: 'D',
  荡: 'D',
  档: 'D',
  菪: 'D',
  刀: 'D',
  叨: 'D',
  忉: 'D',
  氘: 'D',
  导: 'D',
  岛: 'D',
  倒: 'D',
  捣: 'D',
  祷: 'D',
  蹈: 'D',
  到: 'D',
  悼: 'D',
  焘: 'T',
  盗: 'D',
  道: 'D',
  稻: 'D',
  纛: 'D',
  得: 'D',
  锝: 'D',
  德: 'D',
  的: 'D',
  灯: 'D',
  登: 'D',
  噔: 'D',
  簦: 'D',
  蹬: 'D',
  等: 'D',
  戥: 'D',
  邓: 'D',
  凳: 'D',
  嶝: 'D',
  瞪: 'D',
  磴: 'D',
  镫: 'D',
  低: 'D',
  羝: 'D',
  堤: 'D',
  嘀: 'D',
  滴: 'D',
  镝: 'D',
  狄: 'D',
  籴: 'D',
  迪: 'D',
  敌: 'D',
  涤: 'D',
  荻: 'D',
  笛: 'D',
  觌: 'D',
  滌: 'D',
  嫡: 'D',
  氐: 'D',
  诋: 'D',
  邸: 'D',
  坻: 'C',
  底: 'D',
  抵: 'D',
  柢: 'D',
  砥: 'D',
  骶: 'D',
  地: 'D',
  弟: 'D',
  帝: 'D',
  娣: 'D',
  递: 'D',
  第: 'D',
  谛: 'D',
  棣: 'D',
  睇: 'D',
  缔: 'D',
  蒂: 'D',
  碲: 'D',
  嗲: 'D',
  掂: 'D',
  滇: 'D',
  颠: 'D',
  巅: 'D',
  癫: 'D',
  典: 'D',
  点: 'D',
  碘: 'D',
  踮: 'D',
  电: 'D',
  佃: 'D',
  甸: 'D',
  阽: 'D',
  坫: 'D',
  店: 'D',
  垫: 'D',
  玷: 'D',
  钿: 'D',
  惦: 'D',
  淀: 'D',
  奠: 'D',
  殿: 'D',
  靛: 'D',
  癜: 'D',
  簟: 'D',
  刁: 'D',
  叼: 'D',
  凋: 'D',
  貂: 'D',
  碉: 'D',
  雕: 'D',
  鲷: 'D',
  吊: 'D',
  钓: 'D',
  调: 'D',
  掉: 'D',
  铞: 'D',
  爹: 'D',
  跌: 'D',
  迭: 'D',
  垤: 'D',
  瓞: 'D',
  谍: 'D',
  喋: 'D',
  堞: 'D',
  揲: 'D',
  耋: 'D',
  叠: 'D',
  牒: 'D',
  碟: 'D',
  蝶: 'D',
  蹀: 'D',
  鲽: 'D',
  丁: 'D',
  仃: 'D',
  叮: 'D',
  玎: 'D',
  疔: 'D',
  盯: 'D',
  钉: 'D',
  耵: 'D',
  酊: 'D',
  顶: 'D',
  鼎: 'D',
  订: 'D',
  定: 'D',
  啶: 'D',
  腚: 'D',
  碇: 'D',
  锭: 'D',
  丢: 'D',
  铥: 'D',
  东: 'D',
  冬: 'D',
  咚: 'D',
  岽: 'D',
  氡: 'D',
  鸫: 'D',
  董: 'D',
  懂: 'D',
  动: 'D',
  冻: 'D',
  侗: 'D',
  垌: 'D',
  峒: 'D',
  恫: 'D',
  栋: 'D',
  洞: 'D',
  胨: 'D',
  胴: 'D',
  硐: 'D',
  都: 'D',
  兜: 'D',
  蔸: 'D',
  篼: 'D',
  斗: 'D',
  抖: 'D',
  钭: 'T',
  陡: 'D',
  蚪: 'D',
  豆: 'D',
  逗: 'D',
  痘: 'D',
  窦: 'D',
  嘟: 'D',
  督: 'D',
  毒: 'D',
  读: 'D',
  渎: 'D',
  椟: 'D',
  牍: 'D',
  犊: 'D',
  黩: 'D',
  髑: 'D',
  独: 'D',
  笃: 'D',
  堵: 'D',
  赌: 'D',
  睹: 'D',
  芏: 'D',
  妒: 'D',
  杜: 'D',
  肚: 'D',
  度: 'D',
  渡: 'D',
  镀: 'D',
  蠹: 'D',
  端: 'D',
  短: 'D',
  段: 'D',
  断: 'D',
  缎: 'D',
  椴: 'D',
  煅: 'D',
  锻: 'D',
  簖: 'D',
  堆: 'D',
  队: 'D',
  对: 'D',
  兑: 'D',
  怼: 'D',
  碓: 'D',
  憝: 'D',
  镦: 'D',
  吨: 'D',
  敦: 'D',
  墩: 'D',
  礅: 'D',
  蹲: 'D',
  盹: 'D',
  趸: 'D',
  囤: 'D',
  沌: 'D',
  炖: 'D',
  盾: 'D',
  砘: 'D',
  钝: 'D',
  顿: 'D',
  遁: 'D',
  多: 'D',
  咄: 'D',
  哆: 'D',
  裰: 'D',
  夺: 'D',
  铎: 'D',
  掇: 'D',
  踱: 'D',
  朵: 'D',
  哚: 'D',
  垛: 'D',
  缍: 'D',
  躲: 'D',
  剁: 'D',
  沲: 'D',
  堕: 'D',
  舵: 'D',
  惰: 'D',
  跺: 'D',
  屙: 'E',
  讹: 'E',
  俄: 'E',
  娥: 'E',
  峨: 'E',
  莪: 'E',
  锇: 'E',
  鹅: 'E',
  蛾: 'E',
  额: 'E',
  婀: 'E',
  厄: 'E',
  呃: 'E',
  扼: 'E',
  苊: 'E',
  轭: 'E',
  垩: 'E',
  恶: 'E',
  饿: 'E',
  谔: 'E',
  鄂: 'E',
  阏: 'Y',
  愕: 'E',
  萼: 'E',
  遏: 'E',
  腭: 'E',
  锷: 'E',
  鹗: 'E',
  颚: 'E',
  噩: 'E',
  鳄: 'E',
  恩: 'E',
  蒽: 'E',
  摁: 'E',
  儿: 'E',
  而: 'E',
  鸸: 'E',
  鲕: 'E',
  尔: 'E',
  耳: 'E',
  迩: 'E',
  洱: 'E',
  饵: 'E',
  珥: 'E',
  铒: 'E',
  二: 'E',
  佴: 'E',
  贰: 'E',
  发: 'F',
  乏: 'F',
  伐: 'F',
  垡: 'F',
  罚: 'F',
  阀: 'F',
  筏: 'F',
  法: 'F',
  砝: 'F',
  珐: 'F',
  帆: 'F',
  番: 'F',
  幡: 'F',
  翻: 'F',
  藩: 'F',
  凡: 'F',
  矾: 'F',
  钒: 'F',
  烦: 'F',
  樊: 'F',
  蕃: 'F',
  燔: 'F',
  繁: 'F',
  蹯: 'F',
  蘩: 'F',
  反: 'F',
  返: 'F',
  犯: 'F',
  泛: 'F',
  饭: 'F',
  范: 'F',
  贩: 'F',
  畈: 'F',
  梵: 'F',
  方: 'F',
  邡: 'F',
  坊: 'F',
  芳: 'F',
  枋: 'F',
  钫: 'F',
  防: 'F',
  妨: 'F',
  房: 'F',
  肪: 'F',
  鲂: 'F',
  仿: 'F',
  访: 'F',
  彷: 'P',
  纺: 'F',
  舫: 'F',
  放: 'F',
  飞: 'F',
  妃: 'F',
  非: 'F',
  啡: 'F',
  绯: 'F',
  菲: 'F',
  扉: 'F',
  蜚: 'F',
  霏: 'F',
  鲱: 'F',
  肥: 'F',
  淝: 'F',
  腓: 'F',
  匪: 'F',
  诽: 'F',
  悱: 'F',
  斐: 'F',
  榧: 'F',
  翡: 'F',
  篚: 'F',
  吠: 'F',
  废: 'F',
  沸: 'F',
  狒: 'F',
  肺: 'F',
  费: 'F',
  痱: 'F',
  镄: 'F',
  分: 'F',
  吩: 'F',
  纷: 'F',
  芬: 'F',
  氛: 'F',
  玢: 'B',
  酚: 'F',
  坟: 'F',
  汾: 'F',
  棼: 'F',
  焚: 'F',
  鼢: 'F',
  粉: 'F',
  份: 'F',
  奋: 'F',
  忿: 'F',
  偾: 'F',
  愤: 'F',
  粪: 'F',
  鲼: 'F',
  瀵: 'F',
  丰: 'F',
  风: 'F',
  沣: 'F',
  枫: 'F',
  封: 'F',
  疯: 'F',
  砜: 'F',
  峰: 'F',
  烽: 'F',
  葑: 'F',
  锋: 'F',
  蜂: 'F',
  酆: 'F',
  冯: 'F',
  逢: 'F',
  缝: 'F',
  讽: 'F',
  唪: 'F',
  凤: 'F',
  奉: 'F',
  俸: 'F',
  佛: 'F',
  缶: 'F',
  否: 'F',
  夫: 'F',
  呋: 'F',
  肤: 'F',
  趺: 'F',
  麸: 'F',
  稃: 'F',
  跗: 'F',
  孵: 'F',
  敷: 'F',
  弗: 'F',
  伏: 'F',
  凫: 'F',
  孚: 'F',
  扶: 'F',
  芙: 'F',
  芾: 'F',
  怫: 'F',
  拂: 'F',
  服: 'F',
  绂: 'F',
  绋: 'F',
  苻: 'F',
  俘: 'F',
  氟: 'F',
  祓: 'F',
  罘: 'F',
  茯: 'F',
  郛: 'F',
  浮: 'F',
  砩: 'F',
  莩: 'F',
  蚨: 'F',
  匐: 'F',
  桴: 'F',
  涪: 'F',
  符: 'F',
  艴: 'F',
  菔: 'F',
  袱: 'F',
  幅: 'F',
  福: 'F',
  蜉: 'F',
  辐: 'F',
  幞: 'F',
  蝠: 'F',
  黻: 'F',
  呒: 'M',
  抚: 'F',
  甫: 'F',
  府: 'F',
  拊: 'F',
  斧: 'F',
  俯: 'F',
  釜: 'F',
  脯: 'F',
  辅: 'F',
  腑: 'F',
  滏: 'F',
  腐: 'F',
  黼: 'F',
  父: 'F',
  讣: 'F',
  付: 'F',
  妇: 'F',
  负: 'F',
  附: 'F',
  咐: 'F',
  阜: 'F',
  驸: 'F',
  复: 'F',
  赴: 'F',
  副: 'F',
  傅: 'F',
  富: 'F',
  赋: 'F',
  缚: 'F',
  腹: 'F',
  鲋: 'F',
  赙: 'F',
  蝮: 'F',
  鳆: 'F',
  覆: 'F',
  馥: 'F',
  旮: 'G',
  伽: 'J',
  钆: 'G',
  尜: 'G',
  嘎: 'G',
  噶: 'G',
  尕: 'G',
  尬: 'G',
  该: 'G',
  陔: 'G',
  垓: 'G',
  赅: 'G',
  改: 'G',
  丐: 'G',
  钙: 'G',
  盖: 'G',
  溉: 'G',
  戤: 'G',
  概: 'G',
  干: 'G',
  甘: 'G',
  杆: 'G',
  肝: 'G',
  坩: 'G',
  泔: 'G',
  苷: 'G',
  柑: 'G',
  竿: 'G',
  疳: 'G',
  酐: 'G',
  尴: 'G',
  秆: 'G',
  赶: 'G',
  敢: 'G',
  感: 'G',
  澉: 'G',
  橄: 'G',
  擀: 'G',
  旰: 'G',
  矸: 'G',
  绀: 'G',
  淦: 'G',
  赣: 'G',
  冈: 'G',
  刚: 'G',
  岗: 'G',
  纲: 'G',
  肛: 'G',
  缸: 'G',
  钢: 'G',
  罡: 'G',
  港: 'G',
  杠: 'G',
  筻: 'G',
  戆: 'G',
  皋: 'G',
  羔: 'G',
  高: 'G',
  槔: 'G',
  睾: 'G',
  膏: 'G',
  篙: 'G',
  糕: 'G',
  杲: 'G',
  搞: 'G',
  缟: 'G',
  槁: 'G',
  稿: 'G',
  镐: 'G',
  藁: 'G',
  告: 'G',
  诰: 'G',
  郜: 'G',
  锆: 'G',
  戈: 'G',
  圪: 'G',
  纥: 'H',
  疙: 'G',
  哥: 'G',
  胳: 'G',
  袼: 'G',
  鸽: 'G',
  割: 'G',
  搁: 'G',
  歌: 'G',
  阁: 'G',
  革: 'G',
  格: 'G',
  鬲: 'G',
  葛: 'G',
  蛤: 'G',
  隔: 'G',
  嗝: 'G',
  塥: 'G',
  搿: 'G',
  膈: 'G',
  镉: 'G',
  骼: 'G',
  哿: 'G',
  舸: 'G',
  个: 'G',
  各: 'G',
  虼: 'G',
  硌: 'G',
  铬: 'G',
  给: 'G',
  根: 'G',
  跟: 'G',
  哏: 'G',
  亘: 'G',
  艮: 'G',
  茛: 'G',
  更: 'G',
  庚: 'G',
  耕: 'G',
  赓: 'G',
  羹: 'G',
  哽: 'G',
  埂: 'G',
  绠: 'G',
  耿: 'G',
  梗: 'G',
  鲠: 'G',
  工: 'G',
  弓: 'G',
  公: 'G',
  功: 'G',
  攻: 'G',
  供: 'G',
  肱: 'G',
  宫: 'G',
  恭: 'G',
  蚣: 'G',
  躬: 'G',
  龚: 'G',
  觥: 'G',
  巩: 'G',
  汞: 'G',
  拱: 'G',
  珙: 'G',
  共: 'G',
  贡: 'G',
  勾: 'G',
  佝: 'G',
  沟: 'G',
  钩: 'G',
  缑: 'G',
  篝: 'G',
  鞲: 'G',
  岣: 'G',
  狗: 'G',
  苟: 'G',
  枸: 'G',
  笱: 'G',
  构: 'G',
  诟: 'G',
  购: 'G',
  垢: 'G',
  够: 'G',
  媾: 'G',
  彀: 'G',
  遘: 'G',
  觏: 'G',
  估: 'G',
  咕: 'G',
  姑: 'G',
  孤: 'G',
  沽: 'G',
  轱: 'G',
  鸪: 'G',
  菇: 'G',
  菰: 'G',
  蛄: 'G',
  觚: 'G',
  辜: 'G',
  酤: 'G',
  毂: 'G',
  箍: 'G',
  鹘: 'G',
  古: 'G',
  汩: 'G',
  诂: 'G',
  谷: 'G',
  股: 'G',
  牯: 'G',
  骨: 'G',
  罟: 'G',
  钴: 'G',
  蛊: 'G',
  鹄: 'H',
  鼓: 'G',
  嘏: 'G',
  臌: 'G',
  瞽: 'G',
  固: 'G',
  故: 'G',
  顾: 'G',
  崮: 'G',
  梏: 'G',
  牿: 'G',
  雇: 'G',
  痼: 'G',
  锢: 'G',
  鲴: 'G',
  瓜: 'G',
  刮: 'G',
  胍: 'G',
  鸹: 'G',
  呱: 'G',
  剐: 'G',
  寡: 'G',
  卦: 'G',
  诖: 'G',
  挂: 'G',
  褂: 'G',
  乖: 'G',
  拐: 'G',
  怪: 'G',
  关: 'G',
  观: 'G',
  官: 'G',
  冠: 'G',
  倌: 'G',
  棺: 'G',
  鳏: 'G',
  馆: 'G',
  管: 'G',
  贯: 'G',
  惯: 'G',
  掼: 'G',
  涫: 'G',
  盥: 'G',
  灌: 'G',
  鹳: 'G',
  罐: 'G',
  光: 'G',
  咣: 'G',
  桄: 'G',
  胱: 'G',
  广: 'G',
  犷: 'G',
  逛: 'G',
  归: 'G',
  圭: 'G',
  妫: 'G',
  龟: 'G',
  规: 'G',
  皈: 'G',
  闺: 'G',
  硅: 'G',
  瑰: 'G',
  鲑: 'G',
  宄: 'G',
  轨: 'G',
  庋: 'G',
  匦: 'G',
  诡: 'G',
  癸: 'G',
  鬼: 'G',
  晷: 'G',
  簋: 'G',
  刽: 'G',
  刿: 'G',
  柜: 'G',
  炅: 'G',
  贵: 'G',
  桂: 'G',
  跪: 'G',
  鳜: 'G',
  衮: 'G',
  绲: 'G',
  辊: 'G',
  滚: 'G',
  磙: 'G',
  鲧: 'G',
  棍: 'G',
  呙: 'G',
  埚: 'G',
  郭: 'G',
  崞: 'G',
  聒: 'G',
  锅: 'G',
  蝈: 'G',
  国: 'G',
  帼: 'G',
  掴: 'G',
  虢: 'G',
  馘: 'G',
  果: 'G',
  猓: 'G',
  椁: 'G',
  蜾: 'G',
  裹: 'G',
  过: 'G',
  铪: 'H',
  哈: 'H',
  嗨: 'H',
  孩: 'H',
  骸: 'H',
  海: 'H',
  胲: 'H',
  醢: 'H',
  亥: 'H',
  骇: 'H',
  害: 'H',
  氦: 'H',
  顸: 'H',
  蚶: 'H',
  酣: 'H',
  憨: 'H',
  鼾: 'H',
  邗: 'H',
  含: 'H',
  邯: 'H',
  函: 'H',
  晗: 'H',
  涵: 'H',
  焓: 'H',
  寒: 'H',
  韩: 'H',
  罕: 'H',
  喊: 'H',
  汉: 'H',
  汗: 'H',
  旱: 'H',
  悍: 'H',
  捍: 'H',
  焊: 'H',
  菡: 'H',
  颔: 'H',
  撖: 'H',
  憾: 'H',
  撼: 'H',
  翰: 'H',
  瀚: 'H',
  夯: 'H',
  杭: 'H',
  绗: 'H',
  航: 'H',
  颃: 'H',
  沆: 'H',
  蒿: 'H',
  嚆: 'H',
  薅: 'H',
  蚝: 'H',
  毫: 'H',
  嗥: 'H',
  豪: 'H',
  嚎: 'H',
  壕: 'H',
  濠: 'H',
  好: 'H',
  郝: 'H',
  号: 'H',
  昊: 'H',
  浩: 'H',
  耗: 'H',
  皓: 'H',
  颢: 'H',
  灏: 'H',
  诃: 'H',
  呵: 'H',
  喝: 'H',
  嗬: 'H',
  禾: 'H',
  合: 'H',
  何: 'H',
  劾: 'H',
  和: 'H',
  河: 'H',
  曷: 'H',
  阂: 'H',
  核: 'H',
  盍: 'H',
  荷: 'H',
  涸: 'H',
  盒: 'H',
  菏: 'H',
  蚵: 'H',
  颌: 'H',
  貉: 'H',
  阖: 'H',
  翮: 'H',
  贺: 'H',
  褐: 'H',
  赫: 'H',
  鹤: 'H',
  壑: 'H',
  黑: 'H',
  嘿: 'H',
  痕: 'H',
  很: 'H',
  狠: 'H',
  恨: 'H',
  亨: 'H',
  哼: 'H',
  恒: 'H',
  桁: 'H',
  珩: 'H',
  横: 'H',
  衡: 'H',
  蘅: 'H',
  轰: 'H',
  哄: 'H',
  訇: 'H',
  烘: 'H',
  薨: 'H',
  弘: 'H',
  红: 'H',
  宏: 'H',
  闳: 'H',
  泓: 'H',
  洪: 'H',
  荭: 'H',
  虹: 'H',
  鸿: 'H',
  蕻: 'H',
  黉: 'H',
  讧: 'H',
  侯: 'H',
  喉: 'H',
  猴: 'H',
  瘊: 'H',
  篌: 'H',
  糇: 'H',
  骺: 'H',
  吼: 'H',
  后: 'H',
  厚: 'H',
  後: 'H',
  逅: 'H',
  候: 'H',
  堠: 'H',
  鲎: 'H',
  乎: 'H',
  呼: 'H',
  忽: 'H',
  烀: 'H',
  轷: 'H',
  唿: 'H',
  惚: 'H',
  滹: 'H',
  囫: 'H',
  弧: 'H',
  狐: 'H',
  胡: 'H',
  壶: 'H',
  斛: 'H',
  湖: 'H',
  猢: 'H',
  葫: 'H',
  煳: 'H',
  瑚: 'H',
  鹕: 'H',
  槲: 'H',
  糊: 'H',
  蝴: 'H',
  醐: 'H',
  觳: 'H',
  虎: 'H',
  浒: 'H',
  唬: 'H',
  琥: 'H',
  互: 'H',
  户: 'H',
  冱: 'H',
  护: 'H',
  沪: 'H',
  岵: 'H',
  怙: 'H',
  戽: 'H',
  祜: 'H',
  笏: 'H',
  扈: 'H',
  瓠: 'H',
  鹱: 'H',
  花: 'H',
  华: 'H',
  哗: 'H',
  骅: 'H',
  铧: 'H',
  滑: 'H',
  猾: 'H',
  化: 'H',
  划: 'H',
  画: 'H',
  话: 'H',
  桦: 'H',
  怀: 'H',
  徊: 'H',
  淮: 'H',
  槐: 'H',
  踝: 'H',
  坏: 'H',
  欢: 'H',
  獾: 'H',
  还: 'H',
  环: 'H',
  郇: 'H',
  洹: 'H',
  桓: 'H',
  萑: 'H',
  锾: 'H',
  寰: 'H',
  缳: 'H',
  鬟: 'H',
  缓: 'H',
  幻: 'H',
  奂: 'H',
  宦: 'H',
  唤: 'H',
  换: 'H',
  浣: 'H',
  涣: 'H',
  患: 'H',
  焕: 'H',
  逭: 'H',
  痪: 'H',
  豢: 'H',
  漶: 'H',
  鲩: 'H',
  擐: 'H',
  肓: 'H',
  荒: 'H',
  慌: 'H',
  皇: 'H',
  凰: 'H',
  隍: 'H',
  黄: 'H',
  徨: 'H',
  惶: 'H',
  湟: 'H',
  遑: 'H',
  煌: 'H',
  潢: 'H',
  璜: 'H',
  篁: 'H',
  蝗: 'H',
  癀: 'H',
  磺: 'H',
  簧: 'H',
  蟥: 'H',
  鳇: 'H',
  恍: 'H',
  晃: 'H',
  谎: 'H',
  幌: 'H',
  灰: 'H',
  诙: 'H',
  咴: 'H',
  恢: 'H',
  挥: 'H',
  虺: 'H',
  晖: 'H',
  珲: 'H',
  辉: 'H',
  麾: 'H',
  徽: 'H',
  隳: 'H',
  回: 'H',
  洄: 'H',
  茴: 'H',
  蛔: 'H',
  悔: 'H',
  卉: 'H',
  汇: 'H',
  会: 'H',
  讳: 'H',
  哕: 'H',
  浍: 'H',
  绘: 'H',
  荟: 'H',
  诲: 'H',
  恚: 'H',
  桧: 'G',
  烩: 'H',
  贿: 'H',
  彗: 'H',
  晦: 'H',
  秽: 'H',
  喙: 'H',
  惠: 'H',
  缋: 'H',
  毁: 'H',
  慧: 'H',
  蕙: 'H',
  蟪: 'H',
  昏: 'H',
  荤: 'H',
  婚: 'H',
  阍: 'H',
  浑: 'H',
  馄: 'H',
  魂: 'H',
  诨: 'H',
  混: 'H',
  溷: 'H',
  耠: 'H',
  锪: 'H',
  劐: 'H',
  豁: 'H',
  攉: 'H',
  活: 'H',
  火: 'H',
  伙: 'H',
  钬: 'H',
  夥: 'H',
  或: 'H',
  货: 'H',
  获: 'H',
  祸: 'H',
  惑: 'H',
  霍: 'H',
  镬: 'H',
  嚯: 'H',
  藿: 'H',
  蠖: 'H',
  丌: 'J',
  讥: 'J',
  击: 'J',
  叽: 'J',
  饥: 'J',
  乩: 'J',
  圾: 'J',
  机: 'J',
  玑: 'J',
  肌: 'J',
  芨: 'J',
  矶: 'J',
  鸡: 'J',
  咭: 'J',
  迹: 'J',
  剞: 'J',
  唧: 'J',
  姬: 'J',
  屐: 'J',
  积: 'J',
  笄: 'J',
  基: 'J',
  绩: 'J',
  嵇: 'J',
  犄: 'J',
  缉: 'J',
  赍: 'J',
  畸: 'J',
  跻: 'J',
  箕: 'J',
  畿: 'J',
  稽: 'J',
  齑: 'J',
  墼: 'J',
  激: 'J',
  羁: 'J',
  及: 'J',
  吉: 'J',
  岌: 'J',
  汲: 'J',
  级: 'J',
  即: 'J',
  极: 'J',
  亟: 'J',
  佶: 'J',
  急: 'J',
  笈: 'J',
  疾: 'J',
  戢: 'J',
  棘: 'J',
  殛: 'J',
  集: 'J',
  嫉: 'J',
  楫: 'J',
  蒺: 'J',
  辑: 'J',
  瘠: 'J',
  蕺: 'J',
  籍: 'J',
  几: 'J',
  己: 'J',
  虮: 'J',
  挤: 'J',
  脊: 'J',
  掎: 'J',
  戟: 'J',
  嵴: 'J',
  麂: 'J',
  计: 'J',
  记: 'J',
  伎: 'J',
  纪: 'J',
  妓: 'J',
  忌: 'J',
  技: 'J',
  芰: 'J',
  际: 'J',
  剂: 'J',
  季: 'J',
  哜: 'Q',
  既: 'J',
  洎: 'J',
  济: 'J',
  继: 'J',
  觊: 'J',
  偈: 'J',
  寂: 'J',
  寄: 'J',
  悸: 'J',
  祭: 'J',
  蓟: 'J',
  暨: 'J',
  跽: 'J',
  霁: 'J',
  鲚: 'J',
  稷: 'J',
  鲫: 'J',
  冀: 'J',
  髻: 'J',
  骥: 'J',
  加: 'J',
  夹: 'J',
  佳: 'J',
  迦: 'J',
  枷: 'J',
  浃: 'J',
  珈: 'J',
  家: 'J',
  痂: 'J',
  笳: 'J',
  袈: 'J',
  袷: 'J',
  葭: 'J',
  跏: 'J',
  嘉: 'J',
  镓: 'J',
  岬: 'J',
  郏: 'J',
  荚: 'J',
  恝: 'J',
  戛: 'J',
  铗: 'J',
  蛱: 'J',
  颊: 'J',
  甲: 'J',
  胛: 'J',
  贾: 'J',
  钾: 'J',
  瘕: 'J',
  价: 'J',
  驾: 'J',
  架: 'J',
  假: 'J',
  嫁: 'J',
  稼: 'J',
  戋: 'J',
  奸: 'J',
  尖: 'J',
  坚: 'J',
  歼: 'J',
  间: 'J',
  肩: 'J',
  艰: 'J',
  兼: 'J',
  监: 'J',
  笺: 'J',
  菅: 'J',
  湔: 'J',
  犍: 'J',
  缄: 'J',
  搛: 'J',
  煎: 'J',
  缣: 'J',
  蒹: 'J',
  鲣: 'J',
  鹣: 'J',
  鞯: 'J',
  囝: 'N',
  拣: 'J',
  枧: 'J',
  俭: 'J',
  柬: 'J',
  茧: 'J',
  捡: 'J',
  笕: 'J',
  减: 'J',
  剪: 'J',
  检: 'J',
  趼: 'J',
  睑: 'J',
  硷: 'J',
  裥: 'J',
  锏: 'J',
  简: 'J',
  谫: 'J',
  戬: 'J',
  碱: 'J',
  翦: 'J',
  謇: 'J',
  蹇: 'J',
  见: 'J',
  件: 'J',
  建: 'J',
  饯: 'J',
  剑: 'J',
  牮: 'J',
  荐: 'J',
  贱: 'J',
  健: 'J',
  涧: 'J',
  舰: 'J',
  渐: 'J',
  谏: 'J',
  楗: 'J',
  毽: 'J',
  溅: 'J',
  腱: 'J',
  践: 'J',
  鉴: 'J',
  键: 'J',
  僭: 'J',
  槛: 'J',
  箭: 'J',
  踺: 'J',
  江: 'J',
  姜: 'J',
  将: 'J',
  茳: 'J',
  浆: 'J',
  豇: 'J',
  僵: 'J',
  缰: 'J',
  礓: 'J',
  疆: 'J',
  讲: 'J',
  奖: 'J',
  桨: 'J',
  蒋: 'J',
  耩: 'J',
  匠: 'J',
  降: 'J',
  洚: 'J',
  绛: 'J',
  酱: 'J',
  犟: 'J',
  糨: 'J',
  艽: 'J',
  交: 'J',
  郊: 'J',
  姣: 'J',
  娇: 'J',
  浇: 'J',
  茭: 'J',
  骄: 'J',
  胶: 'J',
  椒: 'J',
  焦: 'J',
  蛟: 'J',
  跤: 'J',
  僬: 'J',
  鲛: 'J',
  蕉: 'J',
  礁: 'J',
  鹪: 'J',
  角: 'J',
  佼: 'J',
  侥: 'J',
  挢: 'J',
  狡: 'J',
  绞: 'J',
  饺: 'J',
  皎: 'J',
  矫: 'J',
  脚: 'J',
  铰: 'J',
  搅: 'J',
  湫: 'Q',
  剿: 'J',
  敫: 'J',
  徼: 'J',
  缴: 'J',
  叫: 'J',
  峤: 'J',
  轿: 'J',
  较: 'J',
  教: 'J',
  窖: 'J',
  酵: 'J',
  噍: 'J',
  醮: 'J',
  阶: 'J',
  疖: 'J',
  皆: 'J',
  接: 'J',
  秸: 'J',
  喈: 'J',
  嗟: 'J',
  揭: 'J',
  街: 'J',
  孑: 'J',
  节: 'J',
  讦: 'J',
  劫: 'J',
  杰: 'J',
  诘: 'J',
  拮: 'J',
  洁: 'J',
  结: 'J',
  桀: 'J',
  婕: 'J',
  捷: 'J',
  颉: 'J',
  睫: 'J',
  截: 'J',
  碣: 'J',
  竭: 'J',
  鲒: 'J',
  羯: 'J',
  她: 'T',
  姐: 'J',
  解: 'J',
  介: 'J',
  戒: 'J',
  芥: 'J',
  届: 'J',
  界: 'J',
  疥: 'J',
  诫: 'J',
  借: 'J',
  蚧: 'J',
  骱: 'J',
  藉: 'J',
  巾: 'J',
  今: 'J',
  斤: 'J',
  金: 'J',
  津: 'J',
  矜: 'G',
  衿: 'J',
  筋: 'J',
  襟: 'J',
  仅: 'J',
  卺: 'J',
  紧: 'J',
  堇: 'J',
  谨: 'J',
  锦: 'J',
  廑: 'J',
  馑: 'J',
  槿: 'J',
  瑾: 'J',
  尽: 'J',
  劲: 'J',
  妗: 'J',
  近: 'J',
  进: 'J',
  荩: 'J',
  晋: 'J',
  浸: 'J',
  烬: 'J',
  赆: 'J',
  缙: 'J',
  禁: 'J',
  靳: 'J',
  觐: 'J',
  噤: 'J',
  京: 'J',
  泾: 'J',
  经: 'J',
  茎: 'J',
  荆: 'J',
  惊: 'J',
  旌: 'J',
  菁: 'J',
  晶: 'J',
  腈: 'J',
  睛: 'J',
  粳: 'J',
  兢: 'J',
  精: 'J',
  鲸: 'J',
  井: 'J',
  阱: 'J',
  刭: 'Y',
  肼: 'J',
  颈: 'J',
  景: 'J',
  儆: 'J',
  憬: 'J',
  警: 'J',
  净: 'J',
  弪: 'J',
  径: 'J',
  迳: 'J',
  胫: 'J',
  痉: 'J',
  竞: 'J',
  婧: 'J',
  竟: 'J',
  敬: 'J',
  靓: 'L',
  靖: 'J',
  境: 'J',
  獍: 'J',
  静: 'J',
  镜: 'J',
  扃: 'J',
  迥: 'J',
  炯: 'J',
  窘: 'J',
  纠: 'J',
  究: 'J',
  鸠: 'J',
  赳: 'J',
  阄: 'J',
  啾: 'J',
  揪: 'J',
  鬏: 'J',
  九: 'J',
  久: 'J',
  灸: 'J',
  玖: 'J',
  韭: 'J',
  酒: 'J',
  旧: 'J',
  臼: 'J',
  咎: 'J',
  疚: 'J',
  柩: 'J',
  桕: 'J',
  厩: 'J',
  救: 'J',
  就: 'J',
  舅: 'J',
  僦: 'J',
  鹫: 'J',
  居: 'J',
  拘: 'J',
  狙: 'J',
  苴: 'J',
  驹: 'J',
  疽: 'J',
  掬: 'J',
  椐: 'J',
  琚: 'J',
  趄: 'J',
  锔: 'J',
  裾: 'J',
  雎: 'J',
  鞠: 'J',
  鞫: 'J',
  局: 'J',
  桔: 'J',
  菊: 'J',
  橘: 'J',
  咀: 'J',
  沮: 'J',
  举: 'J',
  矩: 'J',
  莒: 'J',
  榉: 'J',
  榘: 'J',
  龃: 'J',
  踽: 'J',
  句: 'J',
  巨: 'J',
  讵: 'J',
  拒: 'J',
  苣: 'J',
  具: 'J',
  炬: 'J',
  钜: 'J',
  俱: 'J',
  倨: 'J',
  剧: 'J',
  惧: 'J',
  据: 'J',
  距: 'J',
  犋: 'J',
  飓: 'J',
  锯: 'J',
  窭: 'J',
  聚: 'J',
  踞: 'J',
  遽: 'J',
  瞿: 'Q',
  醵: 'J',
  娟: 'J',
  捐: 'J',
  涓: 'J',
  鹃: 'J',
  镌: 'J',
  蠲: 'J',
  卷: 'J',
  锩: 'J',
  倦: 'J',
  桊: 'J',
  狷: 'J',
  绢: 'J',
  隽: 'J',
  眷: 'J',
  鄄: 'J',
  噘: 'J',
  撅: 'J',
  孓: 'J',
  决: 'J',
  诀: 'J',
  抉: 'J',
  珏: 'J',
  绝: 'J',
  觉: 'J',
  倔: 'J',
  崛: 'J',
  掘: 'J',
  桷: 'J',
  觖: 'J',
  厥: 'J',
  劂: 'J',
  谲: 'J',
  獗: 'J',
  蕨: 'J',
  噱: 'J',
  橛: 'J',
  爵: 'J',
  镢: 'J',
  蹶: 'J',
  嚼: 'J',
  矍: 'J',
  爝: 'J',
  攫: 'J',
  军: 'J',
  君: 'J',
  均: 'J',
  钧: 'J',
  皲: 'J',
  菌: 'J',
  筠: 'J',
  麇: 'J',
  俊: 'J',
  郡: 'J',
  峻: 'J',
  捃: 'J',
  浚: 'J',
  骏: 'J',
  竣: 'J',
  咔: 'K',
  咖: 'K',
  喀: 'K',
  卡: 'K',
  佧: 'K',
  胩: 'K',
  开: 'K',
  揩: 'K',
  锎: 'K',
  凯: 'K',
  剀: 'K',
  垲: 'K',
  恺: 'K',
  铠: 'K',
  慨: 'K',
  蒈: 'K',
  楷: 'K',
  锴: 'K',
  忾: 'K',
  刊: 'K',
  勘: 'K',
  龛: 'K',
  堪: 'K',
  戡: 'K',
  坎: 'K',
  侃: 'K',
  砍: 'K',
  莰: 'K',
  看: 'K',
  阚: 'H',
  瞰: 'K',
  康: 'K',
  慷: 'K',
  糠: 'K',
  扛: 'K',
  亢: 'K',
  伉: 'K',
  抗: 'K',
  闶: 'K',
  炕: 'K',
  钪: 'K',
  尻: 'K',
  考: 'K',
  拷: 'K',
  栲: 'K',
  烤: 'K',
  铐: 'K',
  犒: 'K',
  靠: 'K',
  坷: 'K',
  苛: 'K',
  柯: 'K',
  珂: 'K',
  科: 'K',
  轲: 'K',
  疴: 'K',
  钶: 'K',
  棵: 'K',
  颏: 'K',
  稞: 'K',
  窠: 'K',
  颗: 'K',
  瞌: 'K',
  磕: 'K',
  蝌: 'K',
  髁: 'K',
  壳: 'K',
  咳: 'K',
  可: 'K',
  岢: 'K',
  渴: 'K',
  克: 'K',
  刻: 'K',
  客: 'K',
  恪: 'K',
  课: 'K',
  氪: 'K',
  骒: 'K',
  缂: 'K',
  嗑: 'K',
  溘: 'K',
  锞: 'K',
  肯: 'K',
  垦: 'K',
  恳: 'K',
  啃: 'K',
  裉: 'K',
  吭: 'K',
  坑: 'K',
  铿: 'K',
  空: 'K',
  倥: 'K',
  崆: 'K',
  箜: 'K',
  孔: 'K',
  恐: 'K',
  控: 'K',
  抠: 'K',
  芤: 'K',
  眍: 'K',
  口: 'K',
  叩: 'K',
  扣: 'K',
  寇: 'K',
  筘: 'K',
  蔻: 'K',
  刳: 'K',
  枯: 'K',
  哭: 'K',
  堀: 'K',
  窟: 'K',
  骷: 'K',
  苦: 'K',
  库: 'K',
  绔: 'K',
  喾: 'K',
  裤: 'K',
  酷: 'K',
  夸: 'K',
  侉: 'K',
  垮: 'K',
  挎: 'K',
  胯: 'K',
  跨: 'K',
  蒯: 'K',
  块: 'K',
  快: 'K',
  侩: 'K',
  郐: 'K',
  哙: 'K',
  狯: 'K',
  脍: 'K',
  筷: 'K',
  宽: 'K',
  髋: 'K',
  款: 'K',
  匡: 'K',
  诓: 'K',
  哐: 'K',
  筐: 'K',
  狂: 'K',
  诳: 'K',
  夼: 'K',
  邝: 'K',
  圹: 'K',
  纩: 'K',
  况: 'K',
  旷: 'K',
  矿: 'K',
  贶: 'K',
  框: 'K',
  眶: 'K',
  亏: 'K',
  岿: 'K',
  悝: 'K',
  盔: 'K',
  窥: 'K',
  奎: 'K',
  逵: 'K',
  馗: 'K',
  喹: 'K',
  揆: 'K',
  葵: 'K',
  暌: 'K',
  魁: 'K',
  睽: 'K',
  蝰: 'K',
  夔: 'K',
  傀: 'K',
  跬: 'K',
  匮: 'K',
  喟: 'K',
  愦: 'K',
  愧: 'K',
  溃: 'K',
  蒉: 'K',
  馈: 'K',
  篑: 'K',
  聩: 'K',
  坤: 'K',
  昆: 'K',
  琨: 'K',
  锟: 'K',
  髡: 'K',
  醌: 'K',
  鲲: 'K',
  悃: 'K',
  捆: 'K',
  阃: 'K',
  困: 'K',
  扩: 'K',
  括: 'K',
  栝: 'G',
  蛞: 'K',
  阔: 'K',
  廓: 'K',
  垃: 'L',
  拉: 'L',
  啦: 'L',
  邋: 'L',
  旯: 'L',
  砬: 'L',
  喇: 'L',
  剌: 'L',
  腊: 'L',
  瘌: 'L',
  蜡: 'L',
  辣: 'L',
  来: 'L',
  崃: 'L',
  徕: 'L',
  涞: 'L',
  莱: 'L',
  铼: 'L',
  赉: 'L',
  睐: 'L',
  赖: 'L',
  濑: 'L',
  癞: 'L',
  籁: 'L',
  兰: 'L',
  岚: 'L',
  拦: 'L',
  栏: 'L',
  婪: 'L',
  阑: 'L',
  蓝: 'L',
  谰: 'L',
  澜: 'L',
  褴: 'L',
  斓: 'L',
  篮: 'L',
  镧: 'L',
  览: 'L',
  揽: 'L',
  缆: 'L',
  榄: 'L',
  漤: 'L',
  罱: 'L',
  懒: 'L',
  烂: 'L',
  滥: 'L',
  啷: 'L',
  郎: 'L',
  狼: 'L',
  莨: 'L',
  廊: 'L',
  琅: 'L',
  榔: 'L',
  稂: 'L',
  锒: 'L',
  螂: 'L',
  朗: 'L',
  阆: 'L',
  浪: 'L',
  蒗: 'L',
  捞: 'L',
  劳: 'L',
  牢: 'L',
  唠: 'L',
  崂: 'L',
  痨: 'L',
  铹: 'L',
  醪: 'L',
  老: 'L',
  佬: 'L',
  姥: 'L',
  栳: 'L',
  铑: 'L',
  潦: 'L',
  涝: 'L',
  烙: 'L',
  耢: 'L',
  酪: 'L',
  仂: 'L',
  乐: 'L',
  叻: 'L',
  泐: 'L',
  勒: 'L',
  鳓: 'L',
  雷: 'L',
  嫘: 'L',
  缧: 'L',
  檑: 'L',
  镭: 'L',
  羸: 'L',
  耒: 'L',
  诔: 'L',
  垒: 'L',
  磊: 'L',
  蕾: 'L',
  儡: 'L',
  肋: 'L',
  泪: 'L',
  类: 'L',
  累: 'L',
  酹: 'L',
  擂: 'L',
  嘞: 'L',
  塄: 'L',
  棱: 'L',
  楞: 'L',
  冷: 'L',
  愣: 'L',
  厘: 'L',
  梨: 'L',
  狸: 'L',
  离: 'L',
  莉: 'L',
  骊: 'L',
  犁: 'L',
  喱: 'L',
  鹂: 'L',
  漓: 'L',
  缡: 'L',
  蓠: 'L',
  蜊: 'L',
  嫠: 'L',
  璃: 'L',
  鲡: 'L',
  黎: 'L',
  篱: 'L',
  罹: 'L',
  藜: 'L',
  黧: 'L',
  蠡: 'L',
  礼: 'L',
  李: 'L',
  里: 'L',
  俚: 'L',
  哩: 'L',
  娌: 'L',
  逦: 'L',
  理: 'L',
  锂: 'L',
  鲤: 'L',
  澧: 'L',
  醴: 'L',
  鳢: 'L',
  力: 'L',
  历: 'L',
  厉: 'L',
  立: 'L',
  吏: 'L',
  丽: 'L',
  利: 'L',
  励: 'L',
  呖: 'L',
  坜: 'L',
  沥: 'L',
  苈: 'L',
  例: 'L',
  戾: 'L',
  枥: 'L',
  疠: 'L',
  隶: 'L',
  俐: 'L',
  俪: 'L',
  栎: 'L',
  疬: 'L',
  荔: 'L',
  轹: 'L',
  郦: 'L',
  栗: 'L',
  猁: 'L',
  砺: 'L',
  砾: 'L',
  莅: 'L',
  唳: 'L',
  笠: 'L',
  粒: 'L',
  粝: 'L',
  蛎: 'L',
  傈: 'L',
  痢: 'L',
  詈: 'L',
  跞: 'L',
  雳: 'L',
  溧: 'L',
  篥: 'L',
  俩: 'L',
  奁: 'L',
  连: 'L',
  帘: 'L',
  怜: 'L',
  涟: 'L',
  莲: 'L',
  联: 'L',
  裢: 'L',
  廉: 'L',
  鲢: 'L',
  濂: 'L',
  臁: 'L',
  镰: 'L',
  蠊: 'L',
  敛: 'L',
  琏: 'L',
  脸: 'L',
  裣: 'L',
  蔹: 'L',
  练: 'L',
  娈: 'L',
  炼: 'L',
  恋: 'L',
  殓: 'L',
  链: 'L',
  楝: 'L',
  潋: 'L',
  良: 'L',
  凉: 'L',
  梁: 'L',
  椋: 'L',
  粮: 'L',
  粱: 'L',
  墚: 'L',
  踉: 'L',
  两: 'L',
  魉: 'L',
  亮: 'L',
  谅: 'L',
  辆: 'L',
  晾: 'L',
  量: 'L',
  辽: 'L',
  疗: 'L',
  聊: 'L',
  僚: 'L',
  寥: 'L',
  廖: 'L',
  嘹: 'L',
  寮: 'L',
  撩: 'L',
  獠: 'L',
  缭: 'L',
  燎: 'L',
  镣: 'L',
  鹩: 'L',
  钌: 'L',
  蓼: 'L',
  了: 'L',
  尥: 'L',
  料: 'L',
  撂: 'L',
  咧: 'L',
  列: 'L',
  劣: 'L',
  冽: 'L',
  洌: 'L',
  埒: 'L',
  烈: 'L',
  捩: 'L',
  猎: 'L',
  裂: 'L',
  趔: 'L',
  躐: 'L',
  鬣: 'L',
  邻: 'L',
  林: 'L',
  临: 'L',
  啉: 'L',
  淋: 'L',
  琳: 'L',
  粼: 'L',
  嶙: 'L',
  遴: 'L',
  辚: 'L',
  霖: 'L',
  瞵: 'L',
  磷: 'L',
  鳞: 'L',
  麟: 'L',
  凛: 'L',
  廪: 'L',
  懔: 'L',
  檩: 'L',
  吝: 'L',
  赁: 'L',
  蔺: 'L',
  膦: 'L',
  躏: 'L',
  拎: 'L',
  伶: 'L',
  灵: 'L',
  囹: 'L',
  岭: 'L',
  泠: 'L',
  苓: 'L',
  柃: 'L',
  玲: 'L',
  瓴: 'L',
  凌: 'L',
  铃: 'L',
  陵: 'L',
  棂: 'L',
  绫: 'L',
  羚: 'L',
  翎: 'L',
  聆: 'L',
  菱: 'L',
  蛉: 'L',
  零: 'L',
  龄: 'L',
  鲮: 'L',
  酃: 'L',
  领: 'L',
  令: 'L',
  另: 'L',
  呤: 'L',
  溜: 'L',
  熘: 'L',
  刘: 'L',
  浏: 'L',
  流: 'L',
  留: 'L',
  琉: 'L',
  硫: 'L',
  旒: 'L',
  遛: 'L',
  馏: 'L',
  骝: 'L',
  榴: 'L',
  瘤: 'L',
  镏: 'L',
  鎏: 'L',
  柳: 'L',
  绺: 'L',
  锍: 'L',
  六: 'L',
  鹨: 'L',
  咯: 'K',
  龙: 'L',
  咙: 'L',
  泷: 'L',
  茏: 'L',
  栊: 'L',
  珑: 'L',
  胧: 'L',
  砻: 'L',
  笼: 'L',
  聋: 'L',
  隆: 'L',
  癃: 'L',
  窿: 'L',
  礲: 'W',
  陇: 'L',
  垄: 'L',
  垅: 'L',
  拢: 'L',
  娄: 'L',
  偻: 'L',
  喽: 'L',
  蒌: 'L',
  楼: 'L',
  耧: 'L',
  蝼: 'L',
  髅: 'L',
  嵝: 'L',
  搂: 'L',
  篓: 'L',
  陋: 'L',
  漏: 'L',
  瘘: 'L',
  镂: 'L',
  露: 'L',
  噜: 'L',
  撸: 'L',
  卢: 'L',
  庐: 'L',
  芦: 'L',
  垆: 'L',
  泸: 'L',
  炉: 'L',
  栌: 'L',
  胪: 'L',
  轳: 'J',
  鸬: 'L',
  舻: 'L',
  颅: 'L',
  鲈: 'L',
  卤: 'L',
  虏: 'L',
  掳: 'L',
  鲁: 'L',
  橹: 'L',
  镥: 'L',
  陆: 'L',
  录: 'L',
  赂: 'L',
  辂: 'L',
  渌: 'L',
  逯: 'L',
  鹿: 'L',
  禄: 'L',
  滤: 'L',
  碌: 'L',
  路: 'L',
  漉: 'L',
  戮: 'L',
  辘: 'L',
  潞: 'L',
  璐: 'L',
  簏: 'L',
  鹭: 'L',
  麓: 'L',
  氇: 'L',
  驴: 'L',
  闾: 'L',
  榈: 'L',
  吕: 'L',
  侣: 'L',
  旅: 'L',
  稆: 'L',
  铝: 'L',
  屡: 'L',
  缕: 'L',
  膂: 'L',
  褛: 'L',
  履: 'L',
  律: 'L',
  虑: 'L',
  率: 'L',
  绿: 'L',
  氯: 'L',
  孪: 'L',
  峦: 'L',
  挛: 'L',
  栾: 'L',
  鸾: 'L',
  脔: 'L',
  滦: 'L',
  銮: 'L',
  卵: 'L',
  乱: 'L',
  掠: 'L',
  略: 'L',
  锊: 'L',
  抡: 'L',
  仑: 'L',
  伦: 'L',
  囵: 'L',
  沦: 'L',
  纶: 'L',
  轮: 'L',
  论: 'L',
  捋: 'L',
  罗: 'L',
  猡: 'L',
  脶: 'L',
  萝: 'L',
  逻: 'L',
  椤: 'L',
  锣: 'L',
  箩: 'L',
  骡: 'L',
  镙: 'L',
  螺: 'L',
  倮: 'L',
  裸: 'L',
  瘰: 'L',
  蠃: 'L',
  泺: 'L',
  洛: 'L',
  络: 'L',
  荦: 'L',
  骆: 'L',
  珞: 'L',
  落: 'L',
  摞: 'L',
  漯: 'L',
  雒: 'L',
  妈: 'M',
  嬷: 'M',
  麻: 'M',
  蟆: 'M',
  马: 'M',
  犸: 'M',
  玛: 'M',
  码: 'M',
  蚂: 'M',
  杩: 'M',
  骂: 'M',
  唛: 'M',
  吗: 'M',
  嘛: 'M',
  埋: 'M',
  霾: 'M',
  买: 'M',
  荬: 'M',
  劢: 'M',
  迈: 'M',
  麦: 'M',
  卖: 'M',
  脉: 'M',
  颟: 'M',
  蛮: 'M',
  馒: 'M',
  瞒: 'M',
  鞔: 'M',
  鳗: 'M',
  满: 'M',
  螨: 'M',
  曼: 'M',
  谩: 'M',
  墁: 'M',
  幔: 'M',
  慢: 'M',
  漫: 'M',
  缦: 'M',
  蔓: 'M',
  熳: 'M',
  镘: 'M',
  邙: 'M',
  忙: 'M',
  芒: 'M',
  盲: 'M',
  茫: 'M',
  硭: 'M',
  莽: 'M',
  漭: 'M',
  蟒: 'M',
  猫: 'M',
  毛: 'M',
  矛: 'M',
  牦: 'M',
  茅: 'M',
  旄: 'M',
  蛑: 'M',
  锚: 'M',
  髦: 'M',
  蝥: 'M',
  蟊: 'M',
  卯: 'M',
  峁: 'M',
  泖: 'M',
  茆: 'M',
  昴: 'M',
  铆: 'M',
  茂: 'M',
  冒: 'M',
  贸: 'M',
  耄: 'M',
  袤: 'M',
  帽: 'M',
  瑁: 'M',
  瞀: 'M',
  貌: 'M',
  懋: 'M',
  么: 'M',
  没: 'M',
  枚: 'M',
  玫: 'M',
  眉: 'M',
  莓: 'M',
  梅: 'M',
  媒: 'M',
  嵋: 'M',
  湄: 'M',
  猸: 'M',
  楣: 'M',
  煤: 'M',
  酶: 'M',
  镅: 'M',
  鹛: 'M',
  霉: 'M',
  每: 'M',
  美: 'M',
  浼: 'M',
  镁: 'M',
  妹: 'M',
  昧: 'M',
  袂: 'M',
  媚: 'M',
  寐: 'M',
  魅: 'M',
  门: 'M',
  扪: 'M',
  钔: 'M',
  闷: 'M',
  焖: 'M',
  懑: 'M',
  们: 'M',
  氓: 'M',
  虻: 'M',
  萌: 'M',
  盟: 'M',
  甍: 'M',
  瞢: 'M',
  朦: 'M',
  檬: 'M',
  礞: 'M',
  艨: 'M',
  勐: 'M',
  猛: 'M',
  蒙: 'M',
  锰: 'M',
  艋: 'M',
  蜢: 'M',
  懵: 'M',
  蠓: 'M',
  孟: 'M',
  梦: 'M',
  咪: 'M',
  弥: 'M',
  祢: 'M',
  迷: 'M',
  猕: 'M',
  谜: 'M',
  醚: 'M',
  糜: 'M',
  縻: 'M',
  麋: 'M',
  靡: 'M',
  蘼: 'M',
  米: 'M',
  芈: 'M',
  弭: 'M',
  敉: 'M',
  脒: 'M',
  眯: 'M',
  汨: 'M',
  宓: 'M',
  泌: 'M',
  觅: 'M',
  秘: 'M',
  密: 'M',
  幂: 'M',
  谧: 'M',
  嘧: 'M',
  蜜: 'M',
  眠: 'M',
  绵: 'M',
  棉: 'M',
  免: 'M',
  沔: 'M',
  黾: 'M',
  勉: 'M',
  眄: 'M',
  娩: 'M',
  冕: 'M',
  湎: 'M',
  缅: 'M',
  腼: 'M',
  面: 'M',
  喵: 'M',
  苗: 'M',
  描: 'M',
  瞄: 'M',
  鹋: 'M',
  杪: 'M',
  眇: 'M',
  秒: 'M',
  淼: 'M',
  渺: 'M',
  缈: 'M',
  藐: 'M',
  邈: 'M',
  妙: 'M',
  庙: 'M',
  乜: 'N',
  咩: 'M',
  灭: 'M',
  蔑: 'M',
  篾: 'M',
  蠛: 'M',
  民: 'M',
  岷: 'M',
  玟: 'M',
  苠: 'M',
  珉: 'M',
  缗: 'M',
  皿: 'M',
  闵: 'M',
  抿: 'M',
  泯: 'M',
  闽: 'M',
  悯: 'M',
  敏: 'M',
  愍: 'M',
  鳘: 'M',
  名: 'M',
  明: 'M',
  鸣: 'M',
  茗: 'M',
  冥: 'M',
  铭: 'M',
  溟: 'M',
  暝: 'M',
  瞑: 'M',
  螟: 'M',
  酩: 'M',
  命: 'M',
  谬: 'M',
  缪: 'M',
  摸: 'M',
  谟: 'M',
  嫫: 'M',
  馍: 'M',
  摹: 'M',
  模: 'M',
  膜: 'M',
  麽: 'M',
  摩: 'M',
  磨: 'M',
  蘑: 'M',
  魔: 'M',
  抹: 'M',
  末: 'M',
  殁: 'M',
  沫: 'M',
  茉: 'M',
  陌: 'M',
  秣: 'M',
  莫: 'M',
  寞: 'M',
  漠: 'M',
  蓦: 'M',
  貊: 'M',
  墨: 'M',
  瘼: 'M',
  镆: 'M',
  默: 'M',
  貘: 'M',
  耱: 'M',
  哞: 'M',
  牟: 'M',
  侔: 'M',
  眸: 'M',
  谋: 'M',
  鍪: 'M',
  某: 'M',
  母: 'M',
  毪: 'M',
  亩: 'M',
  牡: 'M',
  姆: 'M',
  拇: 'M',
  木: 'M',
  仫: 'M',
  目: 'M',
  沐: 'M',
  坶: 'M',
  牧: 'M',
  苜: 'M',
  钼: 'M',
  募: 'M',
  墓: 'M',
  幕: 'M',
  睦: 'M',
  慕: 'M',
  暮: 'M',
  穆: 'M',
  拿: 'N',
  镎: 'N',
  哪: 'N',
  内: 'N',
  那: 'N',
  纳: 'N',
  肭: 'N',
  娜: 'N',
  衲: 'N',
  钠: 'N',
  捺: 'N',
  乃: 'N',
  奶: 'N',
  艿: 'N',
  氖: 'N',
  奈: 'N',
  柰: 'N',
  耐: 'N',
  萘: 'N',
  鼐: 'N',
  囡: 'N',
  男: 'N',
  南: 'N',
  难: 'N',
  喃: 'N',
  楠: 'N',
  赧: 'N',
  腩: 'N',
  蝻: 'N',
  囔: 'N',
  囊: 'N',
  馕: 'N',
  曩: 'N',
  攮: 'N',
  孬: 'N',
  呶: 'N',
  挠: 'N',
  硇: 'N',
  铙: 'N',
  猱: 'N',
  蛲: 'N',
  垴: 'N',
  恼: 'N',
  脑: 'N',
  瑙: 'N',
  闹: 'N',
  淖: 'N',
  讷: 'N',
  呐: 'N',
  呢: 'N',
  馁: 'N',
  嫩: 'N',
  能: 'N',
  嗯: 'N',
  妮: 'N',
  尼: 'N',
  坭: 'N',
  怩: 'N',
  泥: 'N',
  倪: 'N',
  铌: 'N',
  猊: 'N',
  霓: 'N',
  鲵: 'N',
  伲: 'N',
  你: 'N',
  拟: 'N',
  旎: 'N',
  昵: 'N',
  逆: 'N',
  匿: 'N',
  溺: 'N',
  睨: 'N',
  腻: 'N',
  拈: 'N',
  年: 'N',
  鲇: 'N',
  鲶: 'N',
  黏: 'N',
  捻: 'N',
  辇: 'N',
  撵: 'N',
  碾: 'N',
  廿: 'N',
  念: 'N',
  埝: 'N',
  娘: 'N',
  酿: 'N',
  鸟: 'N',
  茑: 'N',
  袅: 'N',
  嬲: 'N',
  尿: 'N',
  脲: 'N',
  捏: 'N',
  陧: 'N',
  涅: 'N',
  聂: 'N',
  臬: 'N',
  啮: 'N',
  嗫: 'N',
  镊: 'N',
  镍: 'N',
  颞: 'N',
  蹑: 'N',
  孽: 'N',
  蘖: 'N',
  您: 'N',
  宁: 'N',
  咛: 'N',
  拧: 'N',
  狞: 'N',
  柠: 'N',
  聍: 'N',
  凝: 'N',
  佞: 'N',
  泞: 'N',
  甯: 'N',
  妞: 'N',
  牛: 'N',
  忸: 'N',
  扭: 'N',
  狃: 'N',
  纽: 'N',
  钮: 'N',
  农: 'N',
  侬: 'N',
  哝: 'N',
  浓: 'N',
  脓: 'N',
  弄: 'N',
  耨: 'N',
  奴: 'N',
  孥: 'N',
  驽: 'N',
  努: 'N',
  弩: 'N',
  胬: 'N',
  怒: 'N',
  女: 'N',
  钕: 'N',
  恧: 'N',
  衄: 'N',
  疟: 'N',
  虐: 'N',
  暖: 'N',
  挪: 'N',
  傩: 'N',
  诺: 'N',
  喏: 'N',
  搦: 'N',
  锘: 'N',
  懦: 'N',
  糯: 'N',
  噢: 'O',
  哦: 'O',
  讴: 'O',
  欧: 'O',
  殴: 'O',
  瓯: 'O',
  鸥: 'O',
  呕: 'O',
  偶: 'O',
  耦: 'O',
  藕: 'O',
  怄: 'O',
  沤: 'O',
  趴: 'P',
  啪: 'P',
  葩: 'P',
  杷: 'P',
  爬: 'P',
  耙: 'B',
  琶: 'P',
  筢: 'P',
  帕: 'P',
  怕: 'P',
  拍: 'P',
  俳: 'P',
  徘: 'P',
  排: 'P',
  牌: 'P',
  哌: 'P',
  派: 'P',
  湃: 'P',
  蒎: 'P',
  潘: 'P',
  攀: 'P',
  爿: 'P',
  盘: 'P',
  磐: 'P',
  蹒: 'P',
  蟠: 'P',
  判: 'P',
  泮: 'P',
  叛: 'P',
  盼: 'P',
  畔: 'P',
  袢: 'P',
  襻: 'P',
  乓: 'P',
  滂: 'P',
  庞: 'P',
  逄: 'P',
  旁: 'P',
  螃: 'P',
  耪: 'P',
  胖: 'P',
  抛: 'P',
  脬: 'P',
  刨: 'P',
  咆: 'P',
  庖: 'P',
  狍: 'P',
  炮: 'P',
  袍: 'P',
  匏: 'P',
  跑: 'P',
  泡: 'P',
  疱: 'P',
  呸: 'P',
  胚: 'P',
  醅: 'P',
  陪: 'P',
  培: 'P',
  赔: 'P',
  锫: 'P',
  裴: 'P',
  沛: 'P',
  佩: 'P',
  帔: 'P',
  旆: 'P',
  配: 'P',
  辔: 'P',
  霈: 'P',
  喷: 'P',
  盆: 'P',
  湓: 'P',
  怦: 'P',
  抨: 'P',
  砰: 'P',
  烹: 'P',
  嘭: 'P',
  朋: 'P',
  堋: 'P',
  彭: 'P',
  棚: 'P',
  硼: 'P',
  蓬: 'P',
  鹏: 'P',
  澎: 'P',
  篷: 'P',
  膨: 'P',
  蟛: 'P',
  捧: 'P',
  碰: 'P',
  丕: 'P',
  批: 'P',
  纰: 'P',
  邳: 'P',
  坯: 'P',
  披: 'P',
  砒: 'P',
  铍: 'P',
  劈: 'P',
  噼: 'P',
  霹: 'P',
  皮: 'P',
  芘: 'P',
  枇: 'P',
  毗: 'P',
  疲: 'P',
  蚍: 'P',
  郫: 'P',
  陴: 'P',
  啤: 'P',
  埤: 'B',
  琵: 'P',
  脾: 'P',
  罴: 'P',
  蜱: 'P',
  貔: 'P',
  鼙: 'P',
  匹: 'P',
  庀: 'P',
  仳: 'P',
  圮: 'P',
  痞: 'P',
  擗: 'P',
  癖: 'P',
  屁: 'P',
  淠: 'P',
  媲: 'P',
  睥: 'P',
  僻: 'P',
  甓: 'P',
  譬: 'P',
  片: 'P',
  偏: 'P',
  犏: 'P',
  篇: 'P',
  翩: 'P',
  骈: 'P',
  胼: 'P',
  蹁: 'P',
  谝: 'P',
  骗: 'P',
  剽: 'P',
  漂: 'P',
  缥: 'P',
  飘: 'P',
  螵: 'P',
  瓢: 'P',
  殍: 'P',
  瞟: 'P',
  票: 'P',
  嘌: 'P',
  嫖: 'P',
  氕: 'P',
  撇: 'P',
  瞥: 'P',
  苤: 'P',
  姘: 'P',
  拼: 'P',
  贫: 'P',
  嫔: 'B',
  频: 'P',
  颦: 'P',
  品: 'P',
  榀: 'P',
  牝: 'P',
  娉: 'P',
  聘: 'P',
  乒: 'P',
  俜: 'P',
  平: 'P',
  评: 'P',
  凭: 'P',
  坪: 'P',
  苹: 'P',
  屏: 'P',
  枰: 'P',
  瓶: 'P',
  萍: 'P',
  鲆: 'P',
  钋: 'P',
  坡: 'P',
  泼: 'P',
  颇: 'P',
  婆: 'P',
  鄱: 'P',
  皤: 'P',
  叵: 'P',
  钷: 'P',
  笸: 'P',
  迫: 'P',
  珀: 'P',
  破: 'P',
  粕: 'P',
  魄: 'P',
  剖: 'P',
  掊: 'P',
  裒: 'P',
  仆: 'P',
  攴: 'P',
  扑: 'P',
  铺: 'P',
  噗: 'P',
  匍: 'P',
  莆: 'P',
  菩: 'P',
  葡: 'P',
  蒲: 'P',
  璞: 'P',
  濮: 'P',
  镤: 'P',
  朴: 'P',
  圃: 'P',
  埔: 'P',
  浦: 'P',
  普: 'P',
  溥: 'P',
  谱: 'P',
  氆: 'P',
  镨: 'P',
  蹼: 'P',
  瀑: 'P',
  曝: 'P',
  七: 'Q',
  沏: 'Q',
  妻: 'Q',
  柒: 'Q',
  凄: 'Q',
  栖: 'Q',
  桤: 'Q',
  戚: 'Q',
  萋: 'Q',
  期: 'Q',
  欺: 'Q',
  嘁: 'Q',
  槭: 'Q',
  漆: 'Q',
  蹊: 'Q',
  亓: 'Q',
  祁: 'Q',
  齐: 'Q',
  圻: 'Q',
  岐: 'Q',
  芪: 'Q',
  其: 'Q',
  奇: 'Q',
  歧: 'Q',
  祈: 'Q',
  耆: 'Q',
  脐: 'Q',
  颀: 'Q',
  崎: 'Q',
  淇: 'Q',
  畦: 'Q',
  萁: 'Q',
  骐: 'Q',
  骑: 'Q',
  棋: 'Q',
  琦: 'Q',
  琪: 'Q',
  祺: 'Q',
  蛴: 'Q',
  旗: 'Q',
  綦: 'Q',
  蜞: 'Q',
  蕲: 'Q',
  鳍: 'Q',
  麒: 'Q',
  乞: 'Q',
  企: 'Q',
  屺: 'Q',
  岂: 'Q',
  芑: 'Q',
  启: 'Q',
  杞: 'Q',
  起: 'Q',
  绮: 'Q',
  綮: 'Q',
  气: 'Q',
  讫: 'Q',
  汔: 'Q',
  迄: 'Q',
  弃: 'Q',
  汽: 'Q',
  泣: 'Q',
  契: 'Q',
  砌: 'Q',
  荠: 'J',
  葺: 'Q',
  碛: 'Q',
  器: 'Q',
  憩: 'Q',
  掐: 'Q',
  葜: 'Q',
  恰: 'Q',
  洽: 'Q',
  髂: 'Q',
  千: 'Q',
  仟: 'Q',
  阡: 'Q',
  扦: 'Q',
  芊: 'Q',
  迁: 'Q',
  佥: 'Q',
  岍: 'Q',
  钎: 'Q',
  牵: 'Q',
  悭: 'Q',
  铅: 'Q',
  谦: 'Q',
  愆: 'Q',
  签: 'Q',
  骞: 'Q',
  搴: 'Q',
  褰: 'Q',
  前: 'Q',
  荨: 'X',
  钤: 'Q',
  虔: 'Q',
  钱: 'Q',
  钳: 'Q',
  乾: 'Q',
  掮: 'Q',
  箝: 'Q',
  潜: 'Q',
  黔: 'Q',
  浅: 'Q',
  肷: 'Q',
  慊: 'Q',
  遣: 'Q',
  谴: 'Q',
  缱: 'Q',
  欠: 'Q',
  芡: 'Q',
  茜: 'Q',
  倩: 'Q',
  堑: 'Q',
  嵌: 'Q',
  椠: 'Q',
  歉: 'Q',
  呛: 'Q',
  羌: 'Q',
  戕: 'Q',
  戗: 'Q',
  枪: 'Q',
  跄: 'Q',
  腔: 'Q',
  蜣: 'Q',
  锖: 'Q',
  锵: 'Q',
  镪: 'Q',
  强: 'Q',
  墙: 'Q',
  嫱: 'Q',
  蔷: 'Q',
  樯: 'Q',
  抢: 'Q',
  羟: 'Q',
  襁: 'Q',
  炝: 'Q',
  悄: 'Q',
  硗: 'Q',
  跷: 'Q',
  劁: 'Q',
  敲: 'Q',
  锹: 'Q',
  橇: 'Q',
  缲: 'S',
  乔: 'Q',
  侨: 'Q',
  荞: 'Q',
  桥: 'Q',
  谯: 'Q',
  憔: 'Q',
  鞒: 'Q',
  樵: 'Q',
  瞧: 'Q',
  巧: 'Q',
  愀: 'Q',
  俏: 'Q',
  诮: 'Q',
  峭: 'Q',
  窍: 'Q',
  翘: 'Q',
  撬: 'Q',
  鞘: 'Q',
  切: 'Q',
  茄: 'Q',
  且: 'Q',
  妾: 'Q',
  怯: 'Q',
  窃: 'Q',
  挈: 'Q',
  惬: 'Q',
  箧: 'Q',
  锲: 'Q',
  亲: 'Q',
  侵: 'Q',
  钦: 'Q',
  衾: 'Q',
  芩: 'Q',
  芹: 'Q',
  秦: 'Q',
  琴: 'Q',
  禽: 'Q',
  勤: 'Q',
  嗪: 'Q',
  溱: 'Q',
  噙: 'Q',
  擒: 'Q',
  檎: 'Q',
  螓: 'Q',
  锓: 'Q',
  寝: 'Q',
  吣: 'Q',
  沁: 'Q',
  揿: 'Q',
  青: 'Q',
  氢: 'Q',
  轻: 'Q',
  倾: 'Q',
  卿: 'Q',
  圊: 'Q',
  清: 'Q',
  蜻: 'Q',
  鲭: 'Q',
  情: 'Q',
  晴: 'Q',
  氰: 'Q',
  擎: 'Q',
  檠: 'Q',
  黥: 'Q',
  苘: 'Q',
  顷: 'Q',
  请: 'Q',
  謦: 'Q',
  庆: 'Q',
  箐: 'Q',
  磬: 'Q',
  罄: 'Q',
  跫: 'Q',
  銎: 'Q',
  邛: 'Q',
  穷: 'Q',
  穹: 'Q',
  茕: 'Q',
  筇: 'Q',
  琼: 'Q',
  蛩: 'Q',
  丘: 'Q',
  邱: 'Q',
  秋: 'Q',
  蚯: 'Q',
  楸: 'Q',
  鳅: 'Q',
  囚: 'Q',
  犰: 'Q',
  求: 'Q',
  虬: 'Q',
  泅: 'Q',
  俅: 'Q',
  酋: 'Q',
  逑: 'Q',
  球: 'Q',
  赇: 'Q',
  巯: 'Q',
  遒: 'Q',
  裘: 'Q',
  蝤: 'Q',
  鼽: 'Q',
  糗: 'Q',
  区: 'Q',
  曲: 'Q',
  岖: 'Q',
  诎: 'Q',
  驱: 'Q',
  屈: 'Q',
  祛: 'Q',
  蛆: 'Q',
  躯: 'Q',
  蛐: 'Q',
  趋: 'Q',
  麴: 'Q',
  黢: 'Q',
  劬: 'Q',
  朐: 'Q',
  鸲: 'J',
  渠: 'Q',
  蕖: 'Q',
  磲: 'Q',
  璩: 'Q',
  蘧: 'Q',
  氍: 'Q',
  癯: 'Q',
  衢: 'Q',
  蠼: 'Q',
  取: 'Q',
  娶: 'Q',
  龋: 'Q',
  去: 'Q',
  阒: 'Q',
  觑: 'Q',
  趣: 'Q',
  悛: 'Q',
  圈: 'Q',
  全: 'Q',
  权: 'Q',
  诠: 'Q',
  泉: 'Q',
  荃: 'Q',
  拳: 'Q',
  辁: 'Q',
  痊: 'Q',
  铨: 'Q',
  筌: 'Q',
  蜷: 'Q',
  醛: 'Q',
  鬈: 'Q',
  颧: 'Q',
  犬: 'Q',
  畎: 'Q',
  绻: 'Q',
  劝: 'Q',
  券: 'Q',
  炔: 'Q',
  缺: 'Q',
  瘸: 'Q',
  却: 'Q',
  悫: 'Q',
  雀: 'Q',
  确: 'Q',
  阕: 'Q',
  阙: 'Q',
  鹊: 'Q',
  榷: 'Q',
  逡: 'Q',
  裙: 'Q',
  群: 'Q',
  蚺: 'R',
  然: 'R',
  髯: 'R',
  燃: 'R',
  冉: 'R',
  苒: 'R',
  染: 'R',
  禳: 'R',
  瓤: 'R',
  穰: 'R',
  嚷: 'R',
  壤: 'R',
  攘: 'R',
  让: 'R',
  荛: 'R',
  饶: 'R',
  桡: 'R',
  扰: 'R',
  娆: 'R',
  绕: 'R',
  惹: 'R',
  热: 'R',
  人: 'R',
  仁: 'R',
  壬: 'R',
  忍: 'R',
  荏: 'R',
  稔: 'R',
  刃: 'R',
  认: 'R',
  仞: 'R',
  任: 'R',
  纫: 'R',
  妊: 'R',
  轫: 'R',
  韧: 'R',
  饪: 'R',
  衽: 'R',
  恁: 'N',
  葚: 'R',
  扔: 'R',
  仍: 'R',
  日: 'R',
  戎: 'R',
  肜: 'R',
  狨: 'R',
  绒: 'R',
  茸: 'R',
  荣: 'R',
  容: 'R',
  嵘: 'R',
  溶: 'R',
  蓉: 'R',
  榕: 'R',
  熔: 'R',
  蝾: 'R',
  融: 'R',
  冗: 'R',
  柔: 'R',
  揉: 'R',
  糅: 'R',
  蹂: 'R',
  鞣: 'R',
  肉: 'R',
  如: 'R',
  茹: 'R',
  铷: 'R',
  儒: 'R',
  嚅: 'R',
  孺: 'R',
  濡: 'R',
  薷: 'R',
  襦: 'R',
  蠕: 'R',
  颥: 'R',
  汝: 'R',
  乳: 'R',
  辱: 'R',
  入: 'R',
  洳: 'R',
  溽: 'R',
  缛: 'R',
  蓐: 'R',
  褥: 'R',
  阮: 'R',
  朊: 'R',
  软: 'R',
  蕤: 'R',
  蕊: 'R',
  芮: 'R',
  枘: 'R',
  蚋: 'R',
  锐: 'R',
  瑞: 'R',
  睿: 'R',
  闰: 'R',
  润: 'R',
  若: 'R',
  偌: 'R',
  弱: 'R',
  箬: 'R',
  仨: 'S',
  撒: 'S',
  洒: 'S',
  卅: 'S',
  飒: 'S',
  脎: 'S',
  萨: 'S',
  塞: 'S',
  腮: 'S',
  噻: 'S',
  鳃: 'S',
  赛: 'S',
  三: 'S',
  叁: 'S',
  毵: 'S',
  伞: 'S',
  散: 'S',
  糁: 'S',
  馓: 'S',
  桑: 'S',
  嗓: 'S',
  搡: 'S',
  磉: 'S',
  颡: 'S',
  丧: 'S',
  搔: 'S',
  骚: 'S',
  缫: 'S',
  臊: 'S',
  鳋: 'S',
  扫: 'S',
  嫂: 'S',
  埽: 'S',
  瘙: 'S',
  色: 'S',
  啬: 'S',
  铯: 'S',
  瑟: 'S',
  穑: 'S',
  森: 'S',
  僧: 'S',
  杀: 'S',
  沙: 'S',
  纱: 'S',
  刹: 'S',
  砂: 'S',
  莎: 'S',
  铩: 'S',
  痧: 'S',
  裟: 'S',
  鲨: 'S',
  傻: 'S',
  唼: 'S',
  啥: 'S',
  歃: 'S',
  煞: 'S',
  霎: 'S',
  筛: 'S',
  晒: 'S',
  山: 'S',
  删: 'S',
  杉: 'S',
  芟: 'S',
  姗: 'S',
  衫: 'S',
  钐: 'S',
  埏: 'S',
  珊: 'S',
  舢: 'S',
  跚: 'S',
  煽: 'S',
  潸: 'S',
  膻: 'S',
  闪: 'S',
  陕: 'S',
  讪: 'S',
  汕: 'S',
  疝: 'S',
  苫: 'S',
  剡: 'S',
  扇: 'S',
  善: 'S',
  骟: 'S',
  鄯: 'S',
  缮: 'S',
  嬗: 'S',
  擅: 'S',
  膳: 'S',
  赡: 'S',
  蟮: 'S',
  鳝: 'S',
  伤: 'S',
  殇: 'S',
  商: 'S',
  觞: 'S',
  墒: 'S',
  熵: 'S',
  裳: 'S',
  垧: 'S',
  晌: 'S',
  赏: 'S',
  上: 'S',
  尚: 'S',
  绱: 'S',
  捎: 'S',
  梢: 'S',
  烧: 'S',
  稍: 'S',
  筲: 'S',
  艄: 'S',
  蛸: 'S',
  勺: 'S',
  芍: 'S',
  苕: 'S',
  韶: 'S',
  少: 'S',
  劭: 'S',
  邵: 'S',
  绍: 'S',
  哨: 'S',
  潲: 'S',
  奢: 'S',
  猞: 'S',
  赊: 'S',
  畲: 'S',
  舌: 'S',
  佘: 'S',
  蛇: 'S',
  舍: 'S',
  厍: 'S',
  设: 'S',
  社: 'S',
  射: 'S',
  涉: 'S',
  赦: 'S',
  慑: 'S',
  摄: 'S',
  滠: 'S',
  麝: 'S',
  申: 'S',
  伸: 'S',
  身: 'S',
  呻: 'S',
  绅: 'S',
  诜: 'X',
  娠: 'S',
  砷: 'S',
  深: 'S',
  神: 'S',
  沈: 'S',
  审: 'S',
  哂: 'S',
  矧: 'S',
  谂: 'S',
  婶: 'S',
  渖: 'S',
  肾: 'S',
  甚: 'S',
  胂: 'S',
  渗: 'S',
  慎: 'S',
  椹: 'S',
  蜃: 'S',
  升: 'S',
  生: 'S',
  声: 'S',
  牲: 'S',
  胜: 'S',
  笙: 'S',
  甥: 'S',
  渑: 'M',
  绳: 'S',
  省: 'S',
  眚: 'S',
  圣: 'S',
  晟: 'S',
  盛: 'S',
  剩: 'S',
  嵊: 'S',
  尸: 'S',
  失: 'S',
  师: 'S',
  虱: 'S',
  诗: 'S',
  施: 'S',
  狮: 'S',
  湿: 'S',
  蓍: 'S',
  酾: 'S',
  鲺: 'S',
  十: 'S',
  什: 'S',
  石: 'S',
  时: 'S',
  识: 'S',
  实: 'S',
  拾: 'S',
  炻: 'S',
  蚀: 'S',
  食: 'S',
  埘: 'S',
  莳: 'S',
  鲥: 'S',
  史: 'S',
  矢: 'S',
  豕: 'S',
  使: 'S',
  始: 'S',
  驶: 'S',
  屎: 'S',
  士: 'S',
  氏: 'S',
  世: 'S',
  仕: 'S',
  市: 'S',
  示: 'S',
  式: 'S',
  事: 'S',
  侍: 'S',
  势: 'S',
  视: 'S',
  试: 'S',
  饰: 'S',
  室: 'S',
  恃: 'S',
  拭: 'S',
  是: 'S',
  柿: 'S',
  贳: 'S',
  适: 'S',
  舐: 'S',
  轼: 'S',
  逝: 'S',
  铈: 'S',
  弑: 'S',
  谥: 'S',
  释: 'S',
  嗜: 'S',
  筮: 'S',
  誓: 'S',
  噬: 'S',
  螫: 'S',
  收: 'S',
  手: 'S',
  守: 'S',
  首: 'S',
  艏: 'S',
  寿: 'S',
  受: 'S',
  狩: 'S',
  兽: 'S',
  售: 'S',
  授: 'S',
  绶: 'S',
  瘦: 'S',
  书: 'S',
  殳: 'S',
  抒: 'S',
  纾: 'S',
  叔: 'S',
  枢: 'S',
  姝: 'S',
  倏: 'S',
  殊: 'S',
  梳: 'S',
  淑: 'S',
  菽: 'S',
  疏: 'S',
  舒: 'S',
  摅: 'S',
  毹: 'S',
  输: 'S',
  蔬: 'S',
  秫: 'S',
  孰: 'S',
  赎: 'S',
  塾: 'S',
  熟: 'S',
  暑: 'S',
  黍: 'S',
  署: 'S',
  鼠: 'S',
  蜀: 'S',
  薯: 'S',
  曙: 'S',
  术: 'S',
  戍: 'S',
  束: 'S',
  沭: 'S',
  述: 'S',
  树: 'S',
  竖: 'S',
  恕: 'S',
  庶: 'S',
  数: 'S',
  腧: 'S',
  墅: 'S',
  漱: 'S',
  澍: 'S',
  刷: 'S',
  唰: 'S',
  耍: 'S',
  衰: 'S',
  摔: 'S',
  甩: 'S',
  帅: 'S',
  蟀: 'S',
  闩: 'S',
  拴: 'S',
  栓: 'S',
  涮: 'S',
  双: 'S',
  霜: 'S',
  孀: 'S',
  爽: 'S',
  谁: 'S',
  水: 'S',
  氺: 'S',
  税: 'S',
  睡: 'S',
  吮: 'S',
  顺: 'S',
  舜: 'S',
  瞬: 'S',
  说: 'S',
  妁: 'S',
  烁: 'S',
  朔: 'S',
  铄: 'S',
  硕: 'S',
  嗍: 'S',
  搠: 'S',
  蒴: 'S',
  嗽: 'S',
  槊: 'S',
  厶: 'S',
  丝: 'S',
  司: 'S',
  私: 'S',
  咝: 'S',
  思: 'S',
  鸶: 'S',
  斯: 'S',
  缌: 'S',
  蛳: 'S',
  厮: 'S',
  锶: 'S',
  嘶: 'S',
  撕: 'S',
  澌: 'S',
  死: 'S',
  巳: 'S',
  四: 'S',
  寺: 'S',
  汜: 'S',
  伺: 'S',
  似: 'S',
  兕: 'S',
  姒: 'S',
  祀: 'S',
  泗: 'S',
  饲: 'S',
  驷: 'S',
  俟: 'Q',
  笥: 'S',
  耜: 'S',
  嗣: 'S',
  肆: 'S',
  忪: 'S',
  松: 'S',
  凇: 'S',
  崧: 'S',
  淞: 'S',
  菘: 'S',
  嵩: 'S',
  怂: 'S',
  悚: 'S',
  耸: 'S',
  竦: 'S',
  讼: 'S',
  宋: 'S',
  诵: 'S',
  送: 'S',
  颂: 'S',
  嗖: 'S',
  搜: 'S',
  溲: 'S',
  馊: 'S',
  飕: 'S',
  锼: 'S',
  艘: 'S',
  螋: 'S',
  叟: 'S',
  嗾: 'S',
  瞍: 'S',
  擞: 'S',
  薮: 'S',
  苏: 'S',
  酥: 'S',
  稣: 'S',
  俗: 'S',
  夙: 'S',
  诉: 'S',
  肃: 'S',
  涑: 'S',
  素: 'S',
  速: 'S',
  宿: 'S',
  粟: 'S',
  谡: 'S',
  嗉: 'S',
  塑: 'S',
  愫: 'S',
  溯: 'S',
  僳: 'S',
  蔌: 'S',
  觫: 'S',
  簌: 'S',
  狻: 'S',
  酸: 'S',
  蒜: 'S',
  算: 'S',
  虽: 'S',
  荽: 'S',
  眭: 'S',
  睢: 'S',
  濉: 'S',
  绥: 'S',
  隋: 'S',
  随: 'S',
  髓: 'S',
  岁: 'S',
  祟: 'S',
  谇: 'S',
  遂: 'S',
  碎: 'S',
  隧: 'S',
  燧: 'S',
  穗: 'S',
  邃: 'S',
  孙: 'S',
  狲: 'S',
  荪: 'S',
  飧: 'S',
  损: 'S',
  笋: 'S',
  隼: 'S',
  榫: 'S',
  唆: 'S',
  娑: 'S',
  挲: 'S',
  桫: 'S',
  梭: 'S',
  睃: 'S',
  嗦: 'S',
  羧: 'S',
  蓑: 'S',
  缩: 'S',
  所: 'S',
  唢: 'S',
  索: 'S',
  琐: 'S',
  锁: 'S',
  他: 'T',
  它: 'T',
  趿: 'T',
  铊: 'T',
  塌: 'T',
  溻: 'T',
  塔: 'T',
  獭: 'T',
  鳎: 'T',
  挞: 'T',
  闼: 'T',
  遢: 'T',
  榻: 'T',
  踏: 'T',
  蹋: 'T',
  骀: 'D',
  胎: 'T',
  台: 'T',
  邰: 'T',
  抬: 'T',
  苔: 'T',
  炱: 'T',
  跆: 'T',
  鲐: 'T',
  薹: 'T',
  太: 'T',
  汰: 'T',
  态: 'T',
  肽: 'T',
  钛: 'T',
  泰: 'T',
  酞: 'T',
  坍: 'T',
  贪: 'T',
  摊: 'T',
  滩: 'T',
  瘫: 'T',
  坛: 'T',
  昙: 'T',
  谈: 'T',
  郯: 'T',
  覃: 'Q',
  痰: 'T',
  锬: 'T',
  谭: 'T',
  潭: 'T',
  檀: 'T',
  忐: 'T',
  坦: 'T',
  袒: 'T',
  钽: 'T',
  毯: 'T',
  叹: 'T',
  炭: 'T',
  探: 'T',
  赕: 'D',
  碳: 'T',
  汤: 'T',
  铴: 'T',
  羰: 'T',
  镗: 'T',
  饧: 'C',
  唐: 'T',
  堂: 'T',
  棠: 'T',
  塘: 'T',
  搪: 'T',
  溏: 'T',
  瑭: 'T',
  樘: 'T',
  膛: 'T',
  糖: 'T',
  螗: 'T',
  螳: 'T',
  醣: 'T',
  帑: 'T',
  倘: 'T',
  淌: 'T',
  傥: 'T',
  耥: 'T',
  躺: 'T',
  烫: 'T',
  趟: 'T',
  涛: 'T',
  绦: 'T',
  掏: 'T',
  滔: 'T',
  韬: 'T',
  饕: 'T',
  洮: 'T',
  逃: 'T',
  桃: 'T',
  陶: 'T',
  啕: 'T',
  淘: 'T',
  萄: 'T',
  鼗: 'T',
  讨: 'T',
  套: 'T',
  忑: 'T',
  忒: 'T',
  特: 'T',
  铽: 'T',
  慝: 'N',
  疼: 'T',
  腾: 'T',
  誊: 'T',
  滕: 'T',
  藤: 'T',
  剔: 'T',
  梯: 'T',
  锑: 'T',
  踢: 'T',
  绨: 'D',
  啼: 'T',
  提: 'T',
  缇: 'T',
  鹈: 'T',
  题: 'T',
  蹄: 'T',
  醍: 'T',
  体: 'T',
  屉: 'T',
  剃: 'T',
  倜: 'T',
  悌: 'T',
  涕: 'T',
  逖: 'T',
  惕: 'T',
  替: 'T',
  裼: 'T',
  嚏: 'T',
  天: 'T',
  添: 'T',
  田: 'T',
  恬: 'T',
  畋: 'T',
  甜: 'T',
  填: 'T',
  阗: 'T',
  忝: 'T',
  殄: 'T',
  腆: 'T',
  舔: 'T',
  掭: 'T',
  佻: 'T',
  挑: 'T',
  祧: 'T',
  条: 'T',
  迢: 'T',
  笤: 'T',
  龆: 'T',
  蜩: 'T',
  髫: 'T',
  鲦: 'T',
  窕: 'T',
  眺: 'T',
  粜: 'T',
  铫: 'D',
  跳: 'T',
  贴: 'T',
  萜: 'T',
  铁: 'T',
  帖: 'T',
  餮: 'T',
  厅: 'T',
  汀: 'T',
  听: 'T',
  町: 'D',
  烃: 'T',
  廷: 'T',
  亭: 'T',
  庭: 'T',
  莛: 'T',
  停: 'T',
  婷: 'T',
  葶: 'T',
  蜓: 'T',
  霆: 'T',
  挺: 'T',
  梃: 'T',
  铤: 'T',
  艇: 'T',
  通: 'T',
  嗵: 'T',
  仝: 'T',
  同: 'T',
  佟: 'T',
  彤: 'T',
  茼: 'T',
  桐: 'T',
  砼: 'T',
  铜: 'T',
  童: 'T',
  酮: 'T',
  僮: 'Z',
  潼: 'T',
  瞳: 'T',
  统: 'T',
  捅: 'T',
  桶: 'T',
  筒: 'T',
  恸: 'T',
  痛: 'T',
  偷: 'T',
  头: 'T',
  投: 'T',
  骰: 'T',
  透: 'T',
  凸: 'T',
  秃: 'T',
  突: 'T',
  图: 'T',
  徒: 'T',
  涂: 'T',
  荼: 'T',
  途: 'T',
  屠: 'T',
  酴: 'T',
  土: 'T',
  吐: 'T',
  钍: 'T',
  兔: 'T',
  堍: 'T',
  菟: 'T',
  湍: 'T',
  团: 'T',
  抟: 'T',
  疃: 'T',
  彖: 'T',
  推: 'T',
  颓: 'T',
  腿: 'T',
  退: 'T',
  煺: 'T',
  蜕: 'T',
  褪: 'T',
  吞: 'T',
  暾: 'T',
  屯: 'T',
  饨: 'T',
  豚: 'T',
  臀: 'T',
  氽: 'T',
  乇: 'T',
  托: 'T',
  拖: 'T',
  脱: 'T',
  驮: 'T',
  佗: 'T',
  陀: 'T',
  坨: 'T',
  沱: 'T',
  驼: 'T',
  柁: 'D',
  砣: 'T',
  鸵: 'T',
  跎: 'T',
  酡: 'T',
  橐: 'T',
  鼍: 'T',
  妥: 'T',
  庹: 'T',
  椭: 'T',
  拓: 'T',
  柝: 'T',
  唾: 'T',
  箨: 'T',
  哇: 'W',
  娃: 'W',
  挖: 'W',
  洼: 'W',
  娲: 'W',
  蛙: 'W',
  瓦: 'W',
  佤: 'W',
  袜: 'W',
  腽: 'W',
  歪: 'W',
  崴: 'W',
  外: 'W',
  弯: 'W',
  剜: 'W',
  湾: 'W',
  蜿: 'W',
  豌: 'W',
  丸: 'W',
  纨: 'W',
  芄: 'W',
  完: 'W',
  玩: 'W',
  顽: 'W',
  烷: 'W',
  宛: 'W',
  挽: 'W',
  晚: 'W',
  莞: 'G',
  婉: 'W',
  惋: 'W',
  绾: 'W',
  脘: 'W',
  菀: 'W',
  琬: 'W',
  皖: 'W',
  畹: 'W',
  碗: 'W',
  万: 'W',
  腕: 'W',
  汪: 'W',
  亡: 'W',
  王: 'W',
  网: 'W',
  往: 'W',
  枉: 'W',
  罔: 'W',
  惘: 'W',
  辋: 'W',
  魍: 'W',
  妄: 'W',
  忘: 'W',
  旺: 'W',
  望: 'W',
  危: 'W',
  威: 'W',
  偎: 'W',
  逶: 'W',
  隈: 'W',
  葳: 'W',
  微: 'W',
  煨: 'W',
  薇: 'W',
  巍: 'W',
  为: 'W',
  韦: 'W',
  圩: 'X',
  围: 'W',
  帏: 'W',
  沩: 'W',
  违: 'W',
  闱: 'W',
  桅: 'W',
  涠: 'W',
  唯: 'W',
  帷: 'W',
  惟: 'W',
  维: 'W',
  嵬: 'W',
  潍: 'W',
  伟: 'W',
  伪: 'W',
  尾: 'W',
  纬: 'W',
  苇: 'W',
  委: 'W',
  炜: 'W',
  玮: 'W',
  洧: 'W',
  娓: 'W',
  诿: 'W',
  萎: 'W',
  隗: 'K',
  猥: 'W',
  痿: 'W',
  艉: 'W',
  韪: 'W',
  鲔: 'W',
  卫: 'W',
  未: 'W',
  位: 'W',
  味: 'W',
  畏: 'W',
  胃: 'W',
  軎: 'W',
  尉: 'W',
  谓: 'W',
  喂: 'W',
  渭: 'W',
  猬: 'W',
  蔚: 'W',
  慰: 'W',
  魏: 'W',
  温: 'W',
  瘟: 'W',
  文: 'W',
  纹: 'W',
  闻: 'W',
  蚊: 'W',
  阌: 'W',
  雯: 'W',
  刎: 'W',
  吻: 'W',
  紊: 'W',
  稳: 'W',
  问: 'W',
  汶: 'W',
  璺: 'W',
  翁: 'W',
  嗡: 'W',
  蓊: 'W',
  瓮: 'W',
  蕹: 'W',
  挝: 'W',
  倭: 'W',
  涡: 'W',
  莴: 'W',
  喔: 'O',
  窝: 'W',
  蜗: 'W',
  我: 'W',
  沃: 'W',
  肟: 'W',
  卧: 'W',
  幄: 'W',
  握: 'W',
  渥: 'W',
  硪: 'W',
  斡: 'W',
  龌: 'W',
  乌: 'W',
  圬: 'W',
  污: 'W',
  邬: 'W',
  呜: 'W',
  巫: 'W',
  屋: 'W',
  诬: 'W',
  钨: 'W',
  无: 'W',
  毋: 'W',
  吴: 'W',
  吾: 'W',
  芜: 'W',
  唔: 'W',
  梧: 'W',
  浯: 'W',
  蜈: 'W',
  鼯: 'W',
  五: 'W',
  午: 'W',
  仵: 'W',
  伍: 'W',
  坞: 'W',
  妩: 'W',
  庑: 'W',
  忤: 'W',
  怃: 'W',
  迕: 'W',
  武: 'W',
  侮: 'W',
  捂: 'W',
  牾: 'W',
  鹉: 'W',
  舞: 'W',
  兀: 'W',
  勿: 'W',
  务: 'W',
  戊: 'W',
  阢: 'W',
  杌: 'W',
  芴: 'W',
  物: 'W',
  误: 'W',
  悟: 'W',
  晤: 'W',
  焐: 'W',
  婺: 'W',
  痦: 'W',
  骛: 'W',
  雾: 'W',
  寤: 'W',
  鹜: 'W',
  鋈: 'W',
  齀: 'W',
  夕: 'X',
  兮: 'X',
  汐: 'X',
  西: 'X',
  吸: 'X',
  希: 'X',
  昔: 'X',
  析: 'X',
  矽: 'X',
  穸: 'X',
  诶: 'E',
  郗: 'X',
  唏: 'X',
  奚: 'X',
  息: 'X',
  浠: 'X',
  牺: 'X',
  悉: 'X',
  惜: 'X',
  欷: 'X',
  淅: 'X',
  烯: 'X',
  硒: 'X',
  菥: 'X',
  晰: 'X',
  犀: 'X',
  稀: 'X',
  粞: 'X',
  翕: 'X',
  舾: 'X',
  溪: 'X',
  皙: 'X',
  锡: 'X',
  僖: 'X',
  熄: 'X',
  熙: 'X',
  蜥: 'X',
  嘻: 'X',
  嬉: 'X',
  膝: 'X',
  樨: 'X',
  歙: 'S',
  熹: 'X',
  羲: 'X',
  螅: 'X',
  蟋: 'X',
  醯: 'X',
  曦: 'X',
  鼷: 'X',
  习: 'X',
  席: 'X',
  袭: 'X',
  觋: 'X',
  媳: 'X',
  隰: 'X',
  檄: 'X',
  洗: 'X',
  玺: 'X',
  徙: 'X',
  铣: 'X',
  喜: 'X',
  葸: 'X',
  屣: 'X',
  蓰: 'X',
  禧: 'X',
  戏: 'X',
  系: 'X',
  饩: 'X',
  细: 'X',
  郄: 'Q',
  阋: 'X',
  舄: 'X',
  隙: 'X',
  禊: 'X',
  呷: 'X',
  虾: 'X',
  瞎: 'X',
  匣: 'X',
  侠: 'X',
  狎: 'X',
  峡: 'X',
  柙: 'X',
  狭: 'X',
  硖: 'X',
  遐: 'X',
  暇: 'X',
  瑕: 'X',
  辖: 'X',
  霞: 'X',
  黠: 'X',
  下: 'X',
  吓: 'X',
  夏: 'X',
  厦: 'X',
  罅: 'F',
  仙: 'X',
  先: 'X',
  纤: 'X',
  氙: 'X',
  祆: 'X',
  籼: 'X',
  莶: 'X',
  掀: 'X',
  跹: 'X',
  酰: 'X',
  锨: 'X',
  鲜: 'X',
  暹: 'X',
  闲: 'X',
  弦: 'X',
  贤: 'X',
  咸: 'X',
  涎: 'X',
  娴: 'X',
  舷: 'X',
  衔: 'X',
  痫: 'X',
  鹇: 'X',
  嫌: 'X',
  冼: 'X',
  显: 'X',
  险: 'X',
  猃: 'X',
  蚬: 'X',
  筅: 'X',
  跣: 'X',
  藓: 'X',
  燹: 'X',
  县: 'X',
  岘: 'X',
  苋: 'X',
  现: 'X',
  线: 'X',
  限: 'X',
  宪: 'X',
  陷: 'X',
  馅: 'X',
  羡: 'X',
  献: 'X',
  腺: 'X',
  線: 'X',
  霰: 'X',
  乡: 'X',
  芗: 'X',
  相: 'X',
  香: 'X',
  厢: 'X',
  湘: 'X',
  缃: 'X',
  葙: 'X',
  箱: 'X',
  襄: 'X',
  骧: 'X',
  镶: 'X',
  详: 'X',
  庠: 'X',
  祥: 'X',
  翔: 'X',
  享: 'X',
  响: 'X',
  饷: 'X',
  飨: 'X',
  想: 'X',
  鲞: 'X',
  向: 'X',
  巷: 'X',
  项: 'X',
  象: 'X',
  像: 'X',
  橡: 'X',
  蟓: 'X',
  枭: 'X',
  削: 'X',
  哓: 'X',
  枵: 'X',
  骁: 'X',
  宵: 'X',
  消: 'X',
  绡: 'X',
  逍: 'X',
  萧: 'X',
  硝: 'X',
  销: 'X',
  潇: 'X',
  箫: 'X',
  霄: 'X',
  魈: 'X',
  嚣: 'X',
  崤: 'X',
  淆: 'X',
  小: 'X',
  晓: 'X',
  筱: 'X',
  孝: 'X',
  肖: 'X',
  哮: 'X',
  效: 'X',
  校: 'X',
  笑: 'X',
  啸: 'X',
  些: 'X',
  楔: 'X',
  歇: 'X',
  蝎: 'X',
  协: 'X',
  邪: 'X',
  胁: 'X',
  挟: 'X',
  偕: 'X',
  斜: 'X',
  谐: 'X',
  携: 'X',
  勰: 'X',
  撷: 'X',
  缬: 'X',
  鞋: 'X',
  写: 'X',
  泄: 'X',
  泻: 'X',
  绁: 'X',
  卸: 'X',
  屑: 'X',
  械: 'X',
  亵: 'X',
  渫: 'X',
  谢: 'X',
  榍: 'X',
  榭: 'X',
  廨: 'X',
  懈: 'X',
  獬: 'X',
  薢: 'X',
  薤: 'X',
  邂: 'X',
  燮: 'X',
  瀣: 'X',
  蟹: 'X',
  躞: 'X',
  心: 'X',
  忻: 'X',
  芯: 'X',
  辛: 'X',
  昕: 'X',
  欣: 'X',
  莘: 'S',
  锌: 'X',
  新: 'X',
  歆: 'X',
  薪: 'X',
  馨: 'X',
  鑫: 'X',
  囟: 'X',
  信: 'X',
  衅: 'X',
  兴: 'X',
  星: 'X',
  惺: 'X',
  猩: 'X',
  腥: 'X',
  刑: 'X',
  行: 'X',
  邢: 'X',
  形: 'X',
  陉: 'X',
  型: 'X',
  硎: 'X',
  醒: 'X',
  擤: 'X',
  杏: 'X',
  姓: 'X',
  幸: 'X',
  性: 'X',
  荇: 'X',
  悻: 'X',
  凶: 'X',
  兄: 'X',
  匈: 'X',
  芎: 'X',
  汹: 'X',
  胸: 'X',
  雄: 'X',
  熊: 'X',
  休: 'X',
  修: 'X',
  咻: 'X',
  庥: 'X',
  羞: 'X',
  鸺: 'X',
  貅: 'X',
  馐: 'X',
  髹: 'X',
  朽: 'X',
  秀: 'X',
  岫: 'X',
  绣: 'X',
  袖: 'X',
  锈: 'X',
  溴: 'X',
  戌: 'X',
  盱: 'X',
  砉: 'H',
  胥: 'X',
  须: 'X',
  顼: 'X',
  虚: 'X',
  嘘: 'X',
  需: 'X',
  墟: 'X',
  徐: 'X',
  许: 'X',
  诩: 'X',
  栩: 'X',
  糈: 'X',
  醑: 'X',
  旭: 'X',
  序: 'X',
  叙: 'X',
  恤: 'X',
  洫: 'X',
  畜: 'X',
  勖: 'X',
  绪: 'X',
  续: 'X',
  酗: 'X',
  婿: 'X',
  溆: 'X',
  絮: 'X',
  嗅: 'X',
  煦: 'X',
  蓄: 'X',
  蓿: 'X',
  轩: 'X',
  宣: 'X',
  谖: 'X',
  喧: 'X',
  揎: 'X',
  萱: 'X',
  暄: 'X',
  煊: 'X',
  瑄: 'X',
  儇: 'X',
  玄: 'X',
  痃: 'X',
  悬: 'X',
  旋: 'X',
  漩: 'X',
  璇: 'X',
  选: 'X',
  癣: 'X',
  泫: 'X',
  炫: 'X',
  绚: 'X',
  眩: 'X',
  铉: 'X',
  渲: 'X',
  楦: 'X',
  碹: 'X',
  镟: 'X',
  靴: 'X',
  薛: 'X',
  穴: 'X',
  学: 'X',
  泶: 'X',
  踅: 'X',
  雪: 'X',
  鳕: 'X',
  血: 'X',
  谑: 'X',
  勋: 'X',
  埙: 'X',
  熏: 'X',
  窨: 'Y',
  獯: 'X',
  薰: 'X',
  曛: 'X',
  醺: 'X',
  寻: 'X',
  巡: 'X',
  旬: 'X',
  驯: 'X',
  询: 'X',
  峋: 'X',
  恂: 'X',
  洵: 'X',
  浔: 'X',
  荀: 'X',
  循: 'X',
  鲟: 'X',
  训: 'X',
  讯: 'X',
  汛: 'X',
  迅: 'X',
  徇: 'X',
  逊: 'X',
  殉: 'X',
  巽: 'X',
  蕈: 'X',
  丫: 'Y',
  压: 'Y',
  呀: 'Y',
  押: 'Y',
  鸦: 'Y',
  桠: 'Y',
  鸭: 'Y',
  牙: 'Y',
  伢: 'Y',
  岈: 'Y',
  芽: 'Y',
  琊: 'Y',
  蚜: 'Y',
  崖: 'Y',
  涯: 'Y',
  睚: 'Y',
  衙: 'Y',
  疋: 'P',
  哑: 'Y',
  痖: 'Y',
  雅: 'Y',
  亚: 'Y',
  讶: 'Y',
  迓: 'Y',
  垭: 'Y',
  娅: 'Y',
  砑: 'Y',
  氩: 'Y',
  揠: 'Y',
  咽: 'Y',
  恹: 'Y',
  烟: 'Y',
  胭: 'Y',
  崦: 'Y',
  淹: 'Y',
  焉: 'Y',
  菸: 'Y',
  阉: 'Y',
  湮: 'Y',
  腌: 'A',
  鄢: 'Y',
  嫣: 'Y',
  蔫: 'N',
  延: 'Y',
  闫: 'Y',
  严: 'Y',
  妍: 'Y',
  芫: 'Y',
  言: 'Y',
  岩: 'Y',
  沿: 'Y',
  炎: 'Y',
  研: 'Y',
  盐: 'Y',
  阎: 'Y',
  筵: 'Y',
  蜒: 'Y',
  颜: 'Y',
  檐: 'Y',
  兖: 'Y',
  奄: 'Y',
  俨: 'Y',
  衍: 'Y',
  偃: 'Y',
  厣: 'Y',
  掩: 'Y',
  眼: 'Y',
  郾: 'Y',
  琰: 'Y',
  罨: 'Y',
  演: 'Y',
  魇: 'Y',
  鼹: 'Y',
  厌: 'Y',
  彦: 'Y',
  砚: 'Y',
  唁: 'Y',
  宴: 'Y',
  晏: 'Y',
  艳: 'Y',
  验: 'Y',
  谚: 'Y',
  堰: 'Y',
  焰: 'Y',
  焱: 'Y',
  雁: 'Y',
  滟: 'Y',
  酽: 'Y',
  谳: 'Y',
  餍: 'Y',
  燕: 'Y',
  赝: 'Y',
  央: 'Y',
  泱: 'Y',
  殃: 'Y',
  秧: 'Y',
  鸯: 'Y',
  鞅: 'Y',
  扬: 'Y',
  羊: 'Y',
  阳: 'Y',
  杨: 'Y',
  炀: 'Y',
  佯: 'Y',
  疡: 'Y',
  徉: 'Y',
  洋: 'Y',
  烊: 'Y',
  蛘: 'Y',
  仰: 'Y',
  养: 'Y',
  氧: 'Y',
  痒: 'Y',
  怏: 'Y',
  恙: 'Y',
  样: 'Y',
  漾: 'Y',
  幺: 'Y',
  夭: 'Y',
  吆: 'Y',
  妖: 'Y',
  腰: 'Y',
  邀: 'Y',
  爻: 'Y',
  尧: 'Y',
  肴: 'Y',
  姚: 'Y',
  轺: 'Y',
  珧: 'Y',
  窑: 'Y',
  谣: 'Y',
  徭: 'Y',
  摇: 'Y',
  遥: 'Y',
  瑶: 'Y',
  繇: 'Y',
  鳐: 'Y',
  杳: 'Y',
  咬: 'Y',
  窈: 'Y',
  舀: 'Y',
  崾: 'Y',
  药: 'Y',
  要: 'Y',
  鹞: 'Y',
  曜: 'Y',
  耀: 'Y',
  椰: 'Y',
  噎: 'Y',
  爷: 'Y',
  耶: 'Y',
  揶: 'Y',
  铘: 'Y',
  也: 'Y',
  冶: 'Y',
  野: 'Y',
  业: 'Y',
  叶: 'Y',
  曳: 'Y',
  页: 'Y',
  邺: 'Y',
  夜: 'Y',
  晔: 'Y',
  烨: 'Y',
  掖: 'Y',
  液: 'Y',
  谒: 'Y',
  腋: 'Y',
  靥: 'Y',
  一: 'Y',
  伊: 'Y',
  衣: 'Y',
  医: 'Y',
  依: 'Y',
  咿: 'Y',
  猗: 'Y',
  铱: 'Y',
  壹: 'Y',
  揖: 'Y',
  欹: 'Q',
  漪: 'Y',
  噫: 'Y',
  黟: 'Y',
  仪: 'Y',
  圯: 'Y',
  夷: 'Y',
  沂: 'Y',
  诒: 'Y',
  宜: 'Y',
  怡: 'Y',
  迤: 'Y',
  饴: 'Y',
  咦: 'Y',
  姨: 'Y',
  荑: 'Y',
  贻: 'Y',
  眙: 'Y',
  胰: 'Y',
  酏: 'Y',
  痍: 'Y',
  移: 'Y',
  遗: 'Y',
  颐: 'Y',
  疑: 'Y',
  嶷: 'Y',
  彝: 'Y',
  乙: 'Y',
  已: 'Y',
  以: 'Y',
  钇: 'Y',
  矣: 'Y',
  苡: 'Y',
  舣: 'Y',
  蚁: 'Y',
  倚: 'Y',
  椅: 'Y',
  旖: 'Y',
  义: 'Y',
  亿: 'Y',
  弋: 'Y',
  刈: 'Y',
  忆: 'Y',
  艺: 'Y',
  仡: 'G',
  议: 'Y',
  亦: 'Y',
  屹: 'Y',
  异: 'Y',
  佚: 'Y',
  呓: 'Y',
  役: 'Y',
  抑: 'Y',
  译: 'Y',
  邑: 'Y',
  佾: 'Y',
  峄: 'Y',
  怿: 'Y',
  易: 'Y',
  绎: 'Y',
  诣: 'Y',
  驿: 'Y',
  奕: 'Y',
  弈: 'Y',
  疫: 'Y',
  羿: 'Y',
  轶: 'Y',
  悒: 'Y',
  挹: 'Y',
  益: 'Y',
  谊: 'Y',
  埸: 'Y',
  翊: 'Y',
  翌: 'Y',
  逸: 'Y',
  意: 'Y',
  溢: 'Y',
  缢: 'Y',
  肄: 'Y',
  裔: 'Y',
  瘗: 'Y',
  蜴: 'Y',
  毅: 'Y',
  熠: 'Y',
  镒: 'Y',
  劓: 'Y',
  殪: 'Y',
  薏: 'Y',
  翳: 'Y',
  翼: 'Y',
  臆: 'Y',
  癔: 'Y',
  镱: 'Y',
  懿: 'Y',
  因: 'Y',
  阴: 'Y',
  姻: 'Y',
  洇: 'Y',
  茵: 'Y',
  荫: 'Y',
  音: 'Y',
  殷: 'Y',
  氤: 'Y',
  铟: 'Y',
  喑: 'Y',
  堙: 'Y',
  吟: 'Y',
  垠: 'Y',
  狺: 'Y',
  寅: 'Y',
  淫: 'Y',
  银: 'Y',
  鄞: 'Y',
  夤: 'Y',
  龈: 'Y',
  霪: 'Y',
  尹: 'Y',
  引: 'Y',
  吲: 'Y',
  饮: 'Y',
  蚓: 'Y',
  隐: 'Y',
  瘾: 'Y',
  印: 'Y',
  茚: 'Y',
  胤: 'Y',
  应: 'Y',
  英: 'Y',
  莺: 'Y',
  婴: 'Y',
  瑛: 'Y',
  嘤: 'Y',
  撄: 'Y',
  缨: 'Y',
  罂: 'Y',
  樱: 'Y',
  璎: 'Y',
  鹦: 'Y',
  膺: 'Y',
  鹰: 'Y',
  迎: 'Y',
  茔: 'Y',
  盈: 'Y',
  荥: 'X',
  荧: 'Y',
  莹: 'Y',
  萤: 'Y',
  营: 'Y',
  萦: 'Y',
  楹: 'Y',
  滢: 'Y',
  蓥: 'Y',
  潆: 'Y',
  蝇: 'Y',
  嬴: 'Y',
  赢: 'Y',
  瀛: 'Y',
  郢: 'Y',
  颍: 'Y',
  颖: 'Y',
  影: 'Y',
  瘿: 'Y',
  映: 'Y',
  硬: 'Y',
  媵: 'Y',
  哟: 'Y',
  唷: 'Y',
  佣: 'Y',
  拥: 'Y',
  痈: 'Y',
  邕: 'Y',
  庸: 'Y',
  雍: 'Y',
  墉: 'Y',
  慵: 'Y',
  壅: 'Y',
  镛: 'Y',
  臃: 'Y',
  鳙: 'Y',
  饔: 'Y',
  喁: 'Y',
  永: 'Y',
  甬: 'Y',
  咏: 'Y',
  泳: 'Y',
  俑: 'Y',
  勇: 'Y',
  涌: 'Y',
  恿: 'Y',
  蛹: 'Y',
  踊: 'Y',
  用: 'Y',
  优: 'Y',
  忧: 'Y',
  攸: 'Y',
  呦: 'Y',
  幽: 'Y',
  悠: 'Y',
  尢: 'W',
  尤: 'Y',
  由: 'Y',
  犹: 'Y',
  邮: 'Y',
  油: 'Y',
  柚: 'Y',
  疣: 'Y',
  莜: 'Y',
  莸: 'Y',
  铀: 'Y',
  蚰: 'Y',
  游: 'Y',
  鱿: 'Y',
  猷: 'Y',
  蝣: 'Y',
  友: 'Y',
  有: 'Y',
  卣: 'Y',
  酉: 'Y',
  莠: 'Y',
  铕: 'Y',
  牖: 'Y',
  黝: 'Y',
  又: 'Y',
  右: 'Y',
  幼: 'Y',
  佑: 'Y',
  侑: 'Y',
  囿: 'Y',
  宥: 'Y',
  诱: 'Y',
  蚴: 'Y',
  釉: 'Y',
  鼬: 'Y',
  纡: 'Y',
  迂: 'Y',
  淤: 'Y',
  渝: 'Y',
  瘀: 'Y',
  于: 'Y',
  予: 'Y',
  余: 'Y',
  妤: 'Y',
  欤: 'Y',
  於: 'Y',
  盂: 'Y',
  臾: 'Y',
  鱼: 'Y',
  俞: 'Y',
  禺: 'Y',
  竽: 'Y',
  舁: 'Y',
  娱: 'Y',
  狳: 'Y',
  谀: 'Y',
  馀: 'Y',
  渔: 'Y',
  萸: 'Y',
  隅: 'Y',
  雩: 'Y',
  嵛: 'Y',
  愉: 'Y',
  揄: 'Y',
  腴: 'Y',
  逾: 'Y',
  愚: 'Y',
  榆: 'Y',
  瑜: 'Y',
  虞: 'Y',
  觎: 'Y',
  窬: 'Y',
  舆: 'Y',
  蝓: 'Y',
  与: 'Y',
  伛: 'Y',
  宇: 'Y',
  屿: 'Y',
  羽: 'Y',
  雨: 'Y',
  俣: 'Y',
  禹: 'Y',
  语: 'Y',
  圄: 'Y',
  圉: 'Y',
  庾: 'Y',
  瘐: 'Y',
  窳: 'Y',
  龉: 'Y',
  玉: 'Y',
  驭: 'Y',
  吁: 'Y',
  聿: 'Y',
  芋: 'Y',
  妪: 'Y',
  饫: 'Y',
  育: 'Y',
  郁: 'Y',
  昱: 'Y',
  狱: 'Y',
  峪: 'Y',
  浴: 'Y',
  钰: 'Y',
  预: 'Y',
  域: 'Y',
  欲: 'Y',
  谕: 'Y',
  阈: 'Y',
  喻: 'Y',
  寓: 'Y',
  御: 'Y',
  裕: 'Y',
  遇: 'Y',
  鹆: 'Y',
  愈: 'Y',
  煜: 'Y',
  蓣: 'Y',
  誉: 'Y',
  毓: 'Y',
  蜮: 'Y',
  豫: 'Y',
  燠: 'Y',
  鹬: 'Y',
  鬻: 'Y',
  鸢: 'Y',
  冤: 'Y',
  眢: 'Y',
  鸳: 'Y',
  渊: 'Y',
  箢: 'Y',
  元: 'Y',
  员: 'Y',
  园: 'Y',
  沅: 'Y',
  垣: 'Y',
  爰: 'Y',
  原: 'Y',
  圆: 'Y',
  袁: 'Y',
  援: 'Y',
  缘: 'Y',
  鼋: 'Y',
  塬: 'Y',
  源: 'Y',
  猿: 'Y',
  辕: 'Y',
  圜: 'H',
  橼: 'Y',
  螈: 'Y',
  远: 'Y',
  苑: 'Y',
  怨: 'Y',
  院: 'Y',
  垸: 'Y',
  媛: 'Y',
  掾: 'Y',
  瑗: 'Y',
  愿: 'Y',
  曰: 'Y',
  约: 'Y',
  月: 'Y',
  刖: 'Y',
  岳: 'Y',
  钥: 'Y',
  悦: 'Y',
  钺: 'Y',
  阅: 'Y',
  跃: 'Y',
  粤: 'Y',
  越: 'Y',
  樾: 'Y',
  龠: 'Y',
  瀹: 'Y',
  云: 'Y',
  匀: 'Y',
  纭: 'Y',
  芸: 'Y',
  昀: 'Y',
  郧: 'Y',
  耘: 'Y',
  氲: 'Y',
  允: 'Y',
  狁: 'Y',
  陨: 'Y',
  殒: 'Y',
  孕: 'Y',
  运: 'Y',
  郓: 'Y',
  恽: 'Y',
  晕: 'Y',
  酝: 'Y',
  愠: 'Y',
  韫: 'Y',
  韵: 'Y',
  熨: 'Y',
  蕴: 'Y',
  匝: 'Z',
  咂: 'Z',
  拶: 'Z',
  杂: 'Z',
  砸: 'Z',
  灾: 'Z',
  甾: 'Z',
  哉: 'Z',
  栽: 'Z',
  宰: 'Z',
  载: 'Z',
  崽: 'Z',
  再: 'Z',
  在: 'Z',
  糌: 'Z',
  簪: 'Z',
  咱: 'Z',
  昝: 'Z',
  攒: 'Z',
  趱: 'Z',
  暂: 'Z',
  赞: 'Z',
  錾: 'Z',
  瓒: 'Z',
  赃: 'Z',
  臧: 'Z',
  驵: 'Z',
  奘: 'Z',
  脏: 'Z',
  葬: 'Z',
  遭: 'Z',
  糟: 'Z',
  凿: 'Z',
  早: 'Z',
  枣: 'Z',
  蚤: 'Z',
  澡: 'Z',
  藻: 'Z',
  灶: 'Z',
  皂: 'Z',
  唣: 'Z',
  造: 'Z',
  噪: 'Z',
  燥: 'Z',
  躁: 'Z',
  则: 'Z',
  择: 'Z',
  泽: 'Z',
  责: 'Z',
  迮: 'Z',
  啧: 'Z',
  帻: 'Z',
  笮: 'Z',
  舴: 'Z',
  箦: 'Z',
  赜: 'Z',
  仄: 'Z',
  昃: 'Z',
  贼: 'Z',
  怎: 'Z',
  谮: 'Z',
  曾: 'Z',
  增: 'Z',
  憎: 'Z',
  缯: 'Z',
  罾: 'Z',
  锃: 'Z',
  甑: 'Z',
  赠: 'Z',
  吒: 'Z',
  咋: 'Z',
  哳: 'Z',
  喳: 'Z',
  揸: 'Z',
  渣: 'Z',
  楂: 'Z',
  齄: 'Z',
  扎: 'Z',
  札: 'Z',
  轧: 'Z',
  闸: 'Z',
  铡: 'Z',
  眨: 'Z',
  砟: 'Z',
  乍: 'Z',
  诈: 'Z',
  咤: 'Z',
  栅: 'Z',
  炸: 'Z',
  痄: 'Z',
  蚱: 'Z',
  榨: 'Z',
  膪: 'C',
  斋: 'Z',
  摘: 'Z',
  宅: 'Z',
  翟: 'D',
  窄: 'Z',
  债: 'Z',
  砦: 'Z',
  寨: 'Z',
  瘵: 'Z',
  沾: 'Z',
  毡: 'Z',
  旃: 'Z',
  粘: 'N',
  詹: 'Z',
  谵: 'Z',
  澶: 'C',
  瞻: 'Z',
  斩: 'Z',
  展: 'Z',
  盏: 'Z',
  崭: 'Z',
  搌: 'Z',
  辗: 'Z',
  占: 'Z',
  战: 'Z',
  栈: 'Z',
  站: 'Z',
  绽: 'Z',
  湛: 'Z',
  骣: 'C',
  蘸: 'Z',
  张: 'Z',
  章: 'Z',
  鄣: 'Z',
  嫜: 'Z',
  彰: 'Z',
  漳: 'Z',
  獐: 'Z',
  樟: 'Z',
  璋: 'Z',
  蟑: 'Z',
  仉: 'Z',
  涨: 'Z',
  掌: 'Z',
  丈: 'Z',
  仗: 'Z',
  帐: 'Z',
  杖: 'Z',
  胀: 'Z',
  账: 'Z',
  障: 'Z',
  嶂: 'Z',
  幛: 'Z',
  瘴: 'Z',
  钊: 'Z',
  招: 'Z',
  昭: 'Z',
  啁: 'Z',
  找: 'Z',
  沼: 'Z',
  召: 'Z',
  兆: 'Z',
  诏: 'Z',
  赵: 'Z',
  笊: 'Z',
  棹: 'Z',
  照: 'Z',
  罩: 'Z',
  肇: 'Z',
  蜇: 'Z',
  遮: 'Z',
  折: 'Z',
  哲: 'Z',
  辄: 'Z',
  蛰: 'Z',
  谪: 'Z',
  摺: 'Z',
  磔: 'Z',
  辙: 'Z',
  者: 'Z',
  锗: 'Z',
  赭: 'Z',
  褶: 'Z',
  这: 'Z',
  柘: 'Z',
  浙: 'Z',
  蔗: 'Z',
  鹧: 'Z',
  贞: 'Z',
  针: 'Z',
  侦: 'Z',
  浈: 'Z',
  珍: 'Z',
  桢: 'Z',
  真: 'Z',
  砧: 'Z',
  祯: 'Z',
  斟: 'Z',
  甄: 'Z',
  蓁: 'Z',
  榛: 'Z',
  箴: 'Z',
  臻: 'Z',
  诊: 'Z',
  枕: 'Z',
  胗: 'Z',
  轸: 'Z',
  畛: 'Z',
  疹: 'Z',
  缜: 'Z',
  稹: 'Z',
  圳: 'Z',
  阵: 'Z',
  鸩: 'Z',
  振: 'Z',
  朕: 'Z',
  赈: 'Z',
  镇: 'Z',
  震: 'Z',
  争: 'Z',
  征: 'Z',
  怔: 'Z',
  峥: 'Z',
  挣: 'Z',
  狰: 'Z',
  钲: 'Z',
  睁: 'Z',
  铮: 'Z',
  筝: 'Z',
  蒸: 'Z',
  徵: 'Z',
  拯: 'Z',
  整: 'Z',
  正: 'Z',
  证: 'Z',
  诤: 'Z',
  郑: 'Z',
  帧: 'Z',
  政: 'Z',
  症: 'Z',
  之: 'Z',
  支: 'Z',
  卮: 'Z',
  汁: 'Z',
  芝: 'Z',
  吱: 'Z',
  枝: 'Z',
  知: 'Z',
  织: 'Z',
  肢: 'Z',
  栀: 'Z',
  祗: 'Z',
  胝: 'Z',
  脂: 'Z',
  蜘: 'Z',
  执: 'Z',
  侄: 'Z',
  直: 'Z',
  值: 'Z',
  埴: 'Z',
  职: 'Z',
  植: 'Z',
  殖: 'Z',
  絷: 'Z',
  跖: 'Z',
  摭: 'Z',
  踯: 'Z',
  止: 'Z',
  只: 'Z',
  旨: 'Z',
  址: 'Z',
  纸: 'Z',
  芷: 'Z',
  祉: 'Z',
  咫: 'Z',
  指: 'Z',
  枳: 'Z',
  轵: 'Z',
  趾: 'Z',
  黹: 'Z',
  酯: 'Z',
  至: 'Z',
  志: 'Z',
  忮: 'Z',
  豸: 'Z',
  制: 'Z',
  帙: 'Z',
  帜: 'Z',
  治: 'Z',
  炙: 'Z',
  质: 'Z',
  郅: 'Z',
  峙: 'Z',
  栉: 'Z',
  陟: 'Z',
  挚: 'Z',
  桎: 'Z',
  秩: 'Z',
  致: 'Z',
  贽: 'Z',
  轾: 'Z',
  掷: 'Z',
  痔: 'Z',
  窒: 'Z',
  鸷: 'Z',
  彘: 'Z',
  智: 'Z',
  滞: 'Z',
  痣: 'Z',
  蛭: 'Z',
  骘: 'Z',
  稚: 'Z',
  置: 'Z',
  雉: 'Z',
  誌: 'z',
  膣: 'Z',
  觯: 'Z',
  踬: 'Z',
  中: 'Z',
  忠: 'Z',
  终: 'Z',
  盅: 'Z',
  钟: 'Z',
  舯: 'Z',
  衷: 'Z',
  锺: 'Z',
  螽: 'Z',
  肿: 'Z',
  种: 'Z',
  冢: 'Z',
  踵: 'Z',
  仲: 'Z',
  众: 'Z',
  重: 'Z',
  州: 'Z',
  舟: 'Z',
  诌: 'Z',
  周: 'Z',
  洲: 'Z',
  粥: 'Z',
  妯: 'Z',
  轴: 'Z',
  碡: 'Z',
  肘: 'Z',
  帚: 'Z',
  纣: 'Z',
  咒: 'Z',
  宙: 'Z',
  绉: 'Z',
  昼: 'Z',
  胄: 'Z',
  荮: 'Z',
  皱: 'Z',
  酎: 'Z',
  骤: 'Z',
  籀: 'Z',
  朱: 'Z',
  侏: 'Z',
  诛: 'Z',
  邾: 'Z',
  洙: 'Z',
  茱: 'Z',
  株: 'Z',
  珠: 'Z',
  诸: 'Z',
  猪: 'Z',
  铢: 'Z',
  蛛: 'Z',
  槠: 'Z',
  潴: 'Z',
  橥: 'Z',
  竹: 'Z',
  竺: 'Z',
  烛: 'Z',
  逐: 'Z',
  舳: 'Z',
  瘃: 'Z',
  躅: 'Z',
  主: 'Z',
  拄: 'Z',
  渚: 'Z',
  属: 'S',
  煮: 'Z',
  嘱: 'Z',
  麈: 'Z',
  瞩: 'Z',
  伫: 'Z',
  住: 'Z',
  助: 'Z',
  苎: 'Z',
  杼: 'Z',
  注: 'Z',
  贮: 'Z',
  驻: 'Z',
  柱: 'Z',
  炷: 'Z',
  祝: 'Z',
  疰: 'Z',
  著: 'Z',
  蛀: 'Z',
  筑: 'Z',
  铸: 'Z',
  箸: 'Z',
  翥: 'Z',
  抓: 'Z',
  爪: 'Z',
  拽: 'Z',
  专: 'Z',
  砖: 'Z',
  颛: 'Z',
  转: 'Z',
  啭: 'Z',
  赚: 'Z',
  撰: 'Z',
  篆: 'Z',
  馔: 'Z',
  妆: 'Z',
  庄: 'Z',
  桩: 'Z',
  装: 'Z',
  壮: 'Z',
  状: 'Z',
  幢: 'C',
  撞: 'Z',
  隹: 'C',
  追: 'Z',
  骓: 'Z',
  椎: 'Z',
  锥: 'Z',
  坠: 'Z',
  缀: 'Z',
  惴: 'Z',
  缒: 'Z',
  赘: 'Z',
  肫: 'Z',
  窀: 'Z',
  谆: 'Z',
  准: 'Z',
  卓: 'Z',
  拙: 'Z',
  倬: 'Z',
  捉: 'Z',
  桌: 'Z',
  涿: 'Z',
  灼: 'Z',
  茁: 'Z',
  斫: 'Z',
  浊: 'Z',
  浞: 'Z',
  诼: 'Z',
  酌: 'Z',
  啄: 'Z',
  着: 'Z',
  琢: 'Z',
  禚: 'Z',
  擢: 'Z',
  濯: 'Z',
  镯: 'Z',
  仔: 'Z',
  孜: 'Z',
  兹: 'Z',
  咨: 'Z',
  姿: 'Z',
  赀: 'Z',
  资: 'Z',
  淄: 'Z',
  缁: 'Z',
  谘: 'Z',
  孳: 'Z',
  嵫: 'Z',
  滋: 'Z',
  粢: 'Z',
  辎: 'Z',
  觜: 'Z',
  趑: 'Z',
  锱: 'Z',
  龇: 'Z',
  髭: 'Z',
  鲻: 'Z',
  籽: 'Z',
  子: 'Z',
  姊: 'Z',
  秭: 'Z',
  耔: 'Z',
  笫: 'Z',
  梓: 'Z',
  紫: 'Z',
  滓: 'Z',
  訾: 'Z',
  字: 'Z',
  自: 'Z',
  恣: 'Z',
  渍: 'Z',
  眦: 'Z',
  宗: 'Z',
  综: 'Z',
  棕: 'Z',
  腙: 'Z',
  踪: 'Z',
  鬃: 'Z',
  总: 'Z',
  偬: 'Z',
  纵: 'Z',
  粽: 'Z',
  邹: 'Z',
  驺: 'Z',
  诹: 'Z',
  陬: 'Z',
  鄹: 'Z',
  鲰: 'Z',
  走: 'Z',
  奏: 'Z',
  揍: 'Z',
  租: 'Z',
  菹: 'Z',
  足: 'Z',
  卒: 'Z',
  族: 'Z',
  镞: 'Z',
  诅: 'Z',
  阻: 'Z',
  组: 'Z',
  俎: 'Z',
  祖: 'Z',
  躜: 'Z',
  缵: 'Z',
  纂: 'Z',
  钻: 'Z',
  攥: 'Z',
  嘴: 'Z',
  最: 'Z',
  罪: 'Z',
  蕞: 'Z',
  醉: 'Z',
  尊: 'Z',
  遵: 'Z',
  樽: 'Z',
  鳟: 'Z',
  撙: 'Z',
  昨: 'Z',
  左: 'Z',
  佐: 'Z',
  作: 'Z',
  坐: 'Z',
  阼: 'Z',
  怍: 'Z',
  柞: 'Z',
  祚: 'Z',
  胙: 'Z',
  唑: 'Z',
  座: 'Z',
  做: 'Z'
}

Pinyin._pinyin = {
  a: '\u554a\u963f\u9515',
  ai: '\u57c3\u6328\u54ce\u5509\u54c0\u7691\u764c\u853c\u77ee\u827e\u788d\u7231\u9698\u8bf6\u6371\u55f3\u55cc\u5ad2\u7477\u66a7\u7839\u953f\u972d',
  an: '\u978d\u6c28\u5b89\u4ffa\u6309\u6697\u5cb8\u80fa\u6848\u8c19\u57ef\u63de\u72b4\u5eb5\u6849\u94f5\u9e4c\u9878\u9eef',
  ang: '\u80ae\u6602\u76ce',
  ao: '\u51f9\u6556\u71ac\u7ff1\u8884\u50b2\u5965\u61ca\u6fb3\u5773\u62d7\u55f7\u5662\u5c99\u5ed2\u9068\u5aaa\u9a9c\u8071\u87af\u93ca\u9ccc\u93d6',
  ba: '\u82ad\u634c\u6252\u53ed\u5427\u7b06\u516b\u75a4\u5df4\u62d4\u8dcb\u9776\u628a\u8019\u575d\u9738\u7f62\u7238\u8307\u83dd\u8406\u636d\u5c9c\u705e\u6777\u94af\u7c91\u9c85\u9b43',
  bai: '\u767d\u67cf\u767e\u6446\u4f70\u8d25\u62dc\u7a17\u859c\u63b0\u97b4',
  ban: '\u6591\u73ed\u642c\u6273\u822c\u9881\u677f\u7248\u626e\u62cc\u4f34\u74e3\u534a\u529e\u7eca\u962a\u5742\u8c73\u94a3\u7622\u764d\u8228',
  bang: '\u90a6\u5e2e\u6886\u699c\u8180\u7ed1\u68d2\u78c5\u868c\u9551\u508d\u8c24\u84a1\u8783',
  bao: '\u82de\u80de\u5305\u8912\u96f9\u4fdd\u5821\u9971\u5b9d\u62b1\u62a5\u66b4\u8c79\u9c8d\u7206\u52f9\u8446\u5b80\u5b62\u7172\u9e28\u8913\u8db5\u9f85',
  bo: '\u5265\u8584\u73bb\u83e0\u64ad\u62e8\u94b5\u6ce2\u535a\u52c3\u640f\u94c2\u7b94\u4f2f\u5e1b\u8236\u8116\u818a\u6e24\u6cca\u9a73\u4eb3\u8543\u5575\u997d\u6a97\u64d8\u7934\u94b9\u9e41\u7c38\u8ddb',
  bei: '\u676f\u7891\u60b2\u5351\u5317\u8f88\u80cc\u8d1d\u94a1\u500d\u72c8\u5907\u60eb\u7119\u88ab\u5b5b\u9642\u90b6\u57e4\u84d3\u5457\u602b\u6096\u789a\u9e4e\u8919\u943e',
  ben: '\u5954\u82ef\u672c\u7b28\u755a\u574c\u951b',
  beng: '\u5d29\u7ef7\u752d\u6cf5\u8e66\u8ff8\u552a\u5623\u750f',
  bi: '\u903c\u9f3b\u6bd4\u9119\u7b14\u5f7c\u78a7\u84d6\u853d\u6bd5\u6bd9\u6bd6\u5e01\u5e87\u75f9\u95ed\u655d\u5f0a\u5fc5\u8f9f\u58c1\u81c2\u907f\u965b\u5315\u4ef3\u4ffe\u8298\u835c\u8378\u5421\u54d4\u72f4\u5eb3\u610e\u6ed7\u6fde\u5f3c\u59a3\u5a62\u5b16\u74a7\u8d32\u7540\u94cb\u79d5\u88e8\u7b5a\u7b85\u7be6\u822d\u895e\u8df8\u9ac0',
  bian: '\u97ad\u8fb9\u7f16\u8d2c\u6241\u4fbf\u53d8\u535e\u8fa8\u8fa9\u8fab\u904d\u533e\u5f01\u82c4\u5fed\u6c74\u7f0f\u7178\u782d\u78a5\u7a39\u7a86\u8759\u7b3e\u9cca',
  biao: '\u6807\u5f6a\u8198\u8868\u5a4a\u9aa0\u98d1\u98d9\u98da\u706c\u9556\u9573\u762d\u88f1\u9cd4',
  bie: '\u9cd6\u618b\u522b\u762a\u8e69\u9cd8',
  bin: '\u5f6c\u658c\u6fd2\u6ee8\u5bbe\u6448\u50a7\u6d5c\u7f24\u73a2\u6ba1\u8191\u9554\u9acc\u9b13',
  bing: '\u5175\u51b0\u67c4\u4e19\u79c9\u997c\u70b3\u75c5\u5e76\u7980\u90b4\u6452\u7ee0\u678b\u69df\u71f9',
  bu: '\u6355\u535c\u54fa\u8865\u57e0\u4e0d\u5e03\u6b65\u7c3f\u90e8\u6016\u62ca\u535f\u900b\u74ff\u6661\u949a\u91ad',
  ca: '\u64e6\u5693\u7924',
  cai: '\u731c\u88c1\u6750\u624d\u8d22\u776c\u8e29\u91c7\u5f69\u83dc\u8521',
  can: '\u9910\u53c2\u8695\u6b8b\u60ed\u60e8\u707f\u9a96\u74a8\u7cb2\u9eea',
  cang: '\u82cd\u8231\u4ed3\u6ca7\u85cf\u4f27',
  cao: '\u64cd\u7cd9\u69fd\u66f9\u8349\u8279\u5608\u6f15\u87ac\u825a',
  ce: '\u5395\u7b56\u4fa7\u518c\u6d4b\u5202\u5e3b\u607b',
  ceng: '\u5c42\u8e6d\u564c',
  cha: '\u63d2\u53c9\u832c\u8336\u67e5\u78b4\u643d\u5bdf\u5c94\u5dee\u8be7\u7339\u9987\u6c4a\u59f9\u6748\u6942\u69ce\u6aab\u9497\u9538\u9572\u8869',
  chai: '\u62c6\u67f4\u8c7a\u4faa\u8308\u7625\u867f\u9f87',
  chan: '\u6400\u63ba\u8749\u998b\u8c17\u7f20\u94f2\u4ea7\u9610\u98a4\u5181\u8c04\u8c36\u8487\u5edb\u5fcf\u6f7a\u6fb6\u5b71\u7fbc\u5a75\u5b17\u9aa3\u89c7\u7985\u9561\u88e3\u87fe\u8e94',
  chang: '\u660c\u7316\u573a\u5c1d\u5e38\u957f\u507f\u80a0\u5382\u655e\u7545\u5531\u5021\u4f25\u9b2f\u82cc\u83d6\u5f9c\u6005\u60dd\u960a\u5a3c\u5ae6\u6636\u6c05\u9cb3',
  chao: '\u8d85\u6284\u949e\u671d\u5632\u6f6e\u5de2\u5435\u7092\u600a\u7ec9\u6641\u8016',
  che: '\u8f66\u626f\u64a4\u63a3\u5f7b\u6f88\u577c\u5c6e\u7817',
  chen: '\u90f4\u81e3\u8fb0\u5c18\u6668\u5ff1\u6c89\u9648\u8d81\u886c\u79f0\u8c0c\u62bb\u55d4\u5bb8\u741b\u6987\u809c\u80c2\u789c\u9f80',
  cheng: '\u6491\u57ce\u6a59\u6210\u5448\u4e58\u7a0b\u60e9\u6f84\u8bda\u627f\u901e\u9a8b\u79e4\u57d5\u5d4a\u5fb5\u6d48\u67a8\u67fd\u6a18\u665f\u584d\u77a0\u94d6\u88ce\u86cf\u9172',
  chi: '\u5403\u75f4\u6301\u5319\u6c60\u8fdf\u5f1b\u9a70\u803b\u9f7f\u4f88\u5c3a\u8d64\u7fc5\u65a5\u70bd\u50ba\u5880\u82aa\u830c\u640b\u53f1\u54e7\u557b\u55e4\u5f73\u996c\u6cb2\u5ab8\u6555\u80dd\u7719\u7735\u9e31\u761b\u892b\u86a9\u87ad\u7b1e\u7bea\u8c49\u8e05\u8e1f\u9b51',
  chong: '\u5145\u51b2\u866b\u5d07\u5ba0\u833a\u5fe1\u61a7\u94f3\u825f',
  chou: '\u62bd\u916c\u7574\u8e0c\u7a20\u6101\u7b79\u4ec7\u7ef8\u7785\u4e11\u4fe6\u5733\u5e31\u60c6\u6eb4\u59af\u7633\u96e0\u9c8b',
  chu: '\u81ed\u521d\u51fa\u6a71\u53a8\u8e87\u9504\u96cf\u6ec1\u9664\u695a\u7840\u50a8\u77d7\u6410\u89e6\u5904\u4e8d\u520d\u61b7\u7ecc\u6775\u696e\u6a17\u870d\u8e70\u9edc',
  chuan: '\u63e3\u5ddd\u7a7f\u693d\u4f20\u8239\u5598\u4e32\u63be\u821b\u60f4\u9044\u5ddb\u6c1a\u948f\u9569\u8221',
  chuang: '\u75ae\u7a97\u5e62\u5e8a\u95ef\u521b\u6006',
  chui: '\u5439\u708a\u6376\u9524\u5782\u9672\u68f0\u69cc',
  chun: '\u6625\u693f\u9187\u5507\u6df3\u7eaf\u8822\u4fc3\u83bc\u6c8c\u80ab\u6710\u9e51\u877d',
  chuo: '\u6233\u7ef0\u851f\u8fb6\u8f8d\u955e\u8e14\u9f8a',
  ci: '\u75b5\u8328\u78c1\u96cc\u8f9e\u6148\u74f7\u8bcd\u6b64\u523a\u8d50\u6b21\u8360\u5472\u5d6f\u9e5a\u8785\u7ccd\u8d91',
  cong: '\u806a\u8471\u56f1\u5306\u4ece\u4e1b\u506c\u82c1\u6dd9\u9aa2\u742e\u7481\u679e',
  cu: '\u51d1\u7c97\u918b\u7c07\u731d\u6b82\u8e59',
  cuan: '\u8e7f\u7be1\u7a9c\u6c46\u64ba\u6615\u7228',
  cui: '\u6467\u5d14\u50ac\u8106\u7601\u7cb9\u6dec\u7fe0\u8403\u60b4\u7480\u69b1\u96b9',
  cun: '\u6751\u5b58\u5bf8\u78cb\u5fd6\u76b4',
  cuo: '\u64ae\u6413\u63aa\u632b\u9519\u539d\u811e\u9509\u77ec\u75e4\u9e7e\u8e49\u8e9c',
  da: '\u642d\u8fbe\u7b54\u7629\u6253\u5927\u8037\u54d2\u55d2\u601b\u59b2\u75b8\u8921\u7b2a\u977c\u9791',
  dai: '\u5446\u6b79\u50a3\u6234\u5e26\u6b86\u4ee3\u8d37\u888b\u5f85\u902e\u6020\u57ed\u7519\u5454\u5cb1\u8fe8\u902f\u9a80\u7ed0\u73b3\u9edb',
  dan: '\u803d\u62c5\u4e39\u5355\u90f8\u63b8\u80c6\u65e6\u6c2e\u4f46\u60ee\u6de1\u8bde\u5f39\u86cb\u4ebb\u510b\u5369\u840f\u5556\u6fb9\u6a90\u6b9a\u8d55\u7708\u7605\u8043\u7baa',
  dang: '\u5f53\u6321\u515a\u8361\u6863\u8c20\u51fc\u83ea\u5b95\u7800\u94db\u88c6',
  dao: '\u5200\u6363\u8e48\u5012\u5c9b\u7977\u5bfc\u5230\u7a3b\u60bc\u9053\u76d7\u53e8\u5541\u5fc9\u6d2e\u6c18\u7118\u5fd1\u7e9b',
  de: '\u5fb7\u5f97\u7684\u951d',
  deng: '\u8e6c\u706f\u767b\u7b49\u77aa\u51f3\u9093\u5654\u5d9d\u6225\u78f4\u956b\u7c26',
  di: '\u5824\u4f4e\u6ef4\u8fea\u654c\u7b1b\u72c4\u6da4\u7fdf\u5ae1\u62b5\u5e95\u5730\u8482\u7b2c\u5e1d\u5f1f\u9012\u7f14\u6c10\u7c74\u8bcb\u8c1b\u90b8\u577b\u839c\u837b\u5600\u5a23\u67e2\u68e3\u89cc\u7825\u78b2\u7747\u955d\u7f9d\u9ab6',
  dian: '\u98a0\u6382\u6ec7\u7898\u70b9\u5178\u975b\u57ab\u7535\u4f43\u7538\u5e97\u60e6\u5960\u6dc0\u6bbf\u4e36\u963d\u576b\u57dd\u5dc5\u73b7\u765c\u766b\u7c1f\u8e2e',
  diao: '\u7889\u53fc\u96d5\u51cb\u5201\u6389\u540a\u9493\u8c03\u8f7a\u94de\u8729\u7c9c\u8c82',
  die: '\u8dcc\u7239\u789f\u8776\u8fed\u8c0d\u53e0\u4f5a\u57a4\u581e\u63f2\u558b\u6e2b\u8f76\u7252\u74de\u8936\u800b\u8e40\u9cbd\u9cce',
  ding: '\u4e01\u76ef\u53ee\u9489\u9876\u9f0e\u952d\u5b9a\u8ba2\u4e22\u4ec3\u5576\u738e\u815a\u7887\u753a\u94e4\u7594\u8035\u914a',
  dong: '\u4e1c\u51ac\u8463\u61c2\u52a8\u680b\u4f97\u606b\u51bb\u6d1e\u578c\u549a\u5cbd\u5cd2\u5902\u6c21\u80e8\u80f4\u7850\u9e2b',
  dou: '\u515c\u6296\u6597\u9661\u8c46\u9017\u75d8\u8538\u94ad\u7aa6\u7aac\u86aa\u7bfc\u9161',
  du: '\u90fd\u7763\u6bd2\u728a\u72ec\u8bfb\u5835\u7779\u8d4c\u675c\u9540\u809a\u5ea6\u6e21\u5992\u828f\u561f\u6e0e\u691f\u6a50\u724d\u8839\u7b03\u9ad1\u9ee9',
  duan: '\u7aef\u77ed\u953b\u6bb5\u65ad\u7f0e\u5f56\u6934\u7145\u7c16',
  dui: '\u5806\u5151\u961f\u5bf9\u603c\u619d\u7893',
  dun: '\u58a9\u5428\u8e72\u6566\u987f\u56e4\u949d\u76fe\u9041\u7096\u7818\u7905\u76f9\u9566\u8db8',
  duo: '\u6387\u54c6\u591a\u593a\u579b\u8eb2\u6735\u8dfa\u8235\u5241\u60f0\u5815\u5484\u54da\u7f0d\u67c1\u94ce\u88f0\u8e31',
  e: '\u86fe\u5ce8\u9e45\u4fc4\u989d\u8bb9\u5a25\u6076\u5384\u627c\u904f\u9102\u997f\u5669\u8c14\u57a9\u57ad\u82ca\u83aa\u843c\u5443\u6115\u5c59\u5a40\u8f6d\u66f7\u816d\u786a\u9507\u9537\u9e57\u989a\u9cc4',
  en: '\u6069\u84bd\u6441\u5514\u55ef',
  er: '\u800c\u513f\u8033\u5c14\u9975\u6d31\u4e8c\u8d30\u8fe9\u73e5\u94d2\u9e38\u9c95',
  fa: '\u53d1\u7f5a\u7b4f\u4f10\u4e4f\u9600\u6cd5\u73d0\u57a1\u781d',
  fan: '\u85e9\u5e06\u756a\u7ffb\u6a0a\u77fe\u9492\u7e41\u51e1\u70e6\u53cd\u8fd4\u8303\u8d29\u72af\u996d\u6cdb\u8629\u5e61\u72ad\u68b5\u6535\u71d4\u7548\u8e6f',
  fang: '\u574a\u82b3\u65b9\u80aa\u623f\u9632\u59a8\u4eff\u8bbf\u7eba\u653e\u531a\u90a1\u5f77\u94ab\u822b\u9c82',
  fei: '\u83f2\u975e\u5561\u98de\u80a5\u532a\u8bfd\u5420\u80ba\u5e9f\u6cb8\u8d39\u82be\u72d2\u60b1\u6ddd\u5983\u7ecb\u7eef\u69a7\u8153\u6590\u6249\u7953\u7829\u9544\u75f1\u871a\u7bda\u7fe1\u970f\u9cb1',
  fen: '\u82ac\u915a\u5429\u6c1b\u5206\u7eb7\u575f\u711a\u6c7e\u7c89\u594b\u4efd\u5fff\u6124\u7caa\u507e\u7035\u68fc\u610d\u9cbc\u9f22',
  feng: '\u4e30\u5c01\u67ab\u8702\u5cf0\u950b\u98ce\u75af\u70fd\u9022\u51af\u7f1d\u8bbd\u5949\u51e4\u4ff8\u9146\u8451\u6ca3\u781c',
  fu: '\u4f5b\u5426\u592b\u6577\u80a4\u5b75\u6276\u62c2\u8f90\u5e45\u6c1f\u7b26\u4f0f\u4fd8\u670d\u6d6e\u6daa\u798f\u88b1\u5f17\u752b\u629a\u8f85\u4fef\u91dc\u65a7\u812f\u8151\u5e9c\u8150\u8d74\u526f\u8986\u8d4b\u590d\u5085\u4ed8\u961c\u7236\u8179\u8d1f\u5bcc\u8ba3\u9644\u5987\u7f1a\u5490\u5310\u51eb\u90db\u8299\u82fb\u832f\u83a9\u83d4\u544b\u5e5e\u6ecf\u8274\u5b5a\u9a78\u7ec2\u6874\u8d59\u9efb\u9efc\u7f58\u7a03\u99a5\u864d\u86a8\u8709\u8760\u876e\u9eb8\u8dba\u8dd7\u9cc6',
  ga: '\u5676\u560e\u86e4\u5c2c\u5477\u5c15\u5c1c\u65ee\u9486',
  gai: '\u8be5\u6539\u6982\u9499\u76d6\u6e89\u4e10\u9654\u5793\u6224\u8d45\u80f2',
  gan: '\u5e72\u7518\u6746\u67d1\u7aff\u809d\u8d76\u611f\u79c6\u6562\u8d63\u5769\u82f7\u5c34\u64c0\u6cd4\u6de6\u6f89\u7ec0\u6a44\u65f0\u77f8\u75b3\u9150',
  gang: '\u5188\u521a\u94a2\u7f38\u809b\u7eb2\u5c97\u6e2f\u6206\u7f61\u9883\u7b7b',
  gong: '\u6760\u5de5\u653b\u529f\u606d\u9f9a\u4f9b\u8eac\u516c\u5bab\u5f13\u5de9\u6c5e\u62f1\u8d21\u5171\u857b\u5efe\u54a3\u73d9\u80b1\u86a3\u86e9\u89e5',
  gao: '\u7bd9\u768b\u9ad8\u818f\u7f94\u7cd5\u641e\u9550\u7a3f\u544a\u777e\u8bf0\u90dc\u84bf\u85c1\u7f1f\u69d4\u69c1\u6772\u9506',
  ge: '\u54e5\u6b4c\u6401\u6208\u9e3d\u80f3\u7599\u5272\u9769\u845b\u683c\u9601\u9694\u94ec\u4e2a\u5404\u9b32\u4ee1\u54ff\u5865\u55dd\u7ea5\u643f\u8188\u784c\u94ea\u9549\u88bc\u988c\u867c\u8238\u9abc\u9ac2',
  gei: '\u7ed9',
  gen: '\u6839\u8ddf\u4e98\u831b\u54cf\u826e',
  geng: '\u8015\u66f4\u5e9a\u7fb9\u57c2\u803f\u6897\u54fd\u8d53\u9ca0',
  gou: '\u94a9\u52fe\u6c9f\u82df\u72d7\u57a2\u6784\u8d2d\u591f\u4f5d\u8bdf\u5ca3\u9058\u5abe\u7f11\u89cf\u5f40\u9e32\u7b31\u7bdd\u97b2',
  gu: '\u8f9c\u83c7\u5495\u7b8d\u4f30\u6cbd\u5b64\u59d1\u9f13\u53e4\u86ca\u9aa8\u8c37\u80a1\u6545\u987e\u56fa\u96c7\u560f\u8bc2\u83f0\u54cc\u5d2e\u6c69\u688f\u8f71\u726f\u727f\u80cd\u81cc\u6bc2\u77bd\u7f5f\u94b4\u9522\u74e0\u9e2a\u9e44\u75fc\u86c4\u9164\u89da\u9cb4\u9ab0\u9e58',
  gua: '\u522e\u74dc\u5250\u5be1\u6302\u8902\u5366\u8bd6\u5471\u681d\u9e39',
  guai: '\u4e56\u62d0\u602a\u54d9',
  guan: '\u68fa\u5173\u5b98\u51a0\u89c2\u7ba1\u9986\u7f50\u60ef\u704c\u8d2f\u500c\u839e\u63bc\u6dab\u76e5\u9e73\u9ccf',
  guang: '\u5149\u5e7f\u901b\u72b7\u6844\u80f1\u7592',
  gui: '\u7470\u89c4\u572d\u7845\u5f52\u9f9f\u95fa\u8f68\u9b3c\u8be1\u7678\u6842\u67dc\u8dea\u8d35\u523d\u5326\u523f\u5e8b\u5b84\u59ab\u6867\u7085\u6677\u7688\u7c0b\u9c91\u9cdc',
  gun: '\u8f8a\u6eda\u68cd\u4e28\u886e\u7ef2\u78d9\u9ca7',
  guo: '\u9505\u90ed\u56fd\u679c\u88f9\u8fc7\u9998\u8803\u57da\u63b4\u5459\u56d7\u5e3c\u5d1e\u7313\u6901\u8662\u951e\u8052\u872e\u873e\u8748',
  ha: '\u54c8',
  hai: '\u9ab8\u5b69\u6d77\u6c26\u4ea5\u5bb3\u9a87\u54b4\u55e8\u988f\u91a2',
  han: '\u9163\u61a8\u90af\u97e9\u542b\u6db5\u5bd2\u51fd\u558a\u7f55\u7ff0\u64bc\u634d\u65f1\u61be\u608d\u710a\u6c57\u6c49\u9097\u83e1\u6496\u961a\u701a\u6657\u7113\u9894\u86b6\u9f3e',
  hen: '\u592f\u75d5\u5f88\u72e0\u6068',
  hang: '\u676d\u822a\u6c86\u7ed7\u73e9\u6841',
  hao: '\u58d5\u568e\u8c6a\u6beb\u90dd\u597d\u8017\u53f7\u6d69\u8585\u55e5\u5686\u6fe0\u704f\u660a\u7693\u98a2\u869d',
  he: '\u5475\u559d\u8377\u83cf\u6838\u79be\u548c\u4f55\u5408\u76d2\u8c89\u9602\u6cb3\u6db8\u8d6b\u8910\u9e64\u8d3a\u8bc3\u52be\u58d1\u85ff\u55d1\u55ec\u9616\u76cd\u86b5\u7fee',
  hei: '\u563f\u9ed1',
  heng: '\u54fc\u4ea8\u6a2a\u8861\u6052\u8a07\u8605',
  hong: '\u8f70\u54c4\u70d8\u8679\u9e3f\u6d2a\u5b8f\u5f18\u7ea2\u9ec9\u8ba7\u836d\u85a8\u95f3\u6cd3',
  hou: '\u5589\u4faf\u7334\u543c\u539a\u5019\u540e\u5820\u5f8c\u9005\u760a\u7bcc\u7cc7\u9c8e\u9aba',
  hu: '\u547c\u4e4e\u5ffd\u745a\u58f6\u846b\u80e1\u8774\u72d0\u7cca\u6e56\u5f27\u864e\u552c\u62a4\u4e92\u6caa\u6237\u51b1\u553f\u56eb\u5cb5\u7322\u6019\u60da\u6d52\u6ef9\u7425\u69f2\u8f77\u89f3\u70c0\u7173\u623d\u6248\u795c\u9e55\u9e71\u7b0f\u9190\u659b',
  hua: '\u82b1\u54d7\u534e\u733e\u6ed1\u753b\u5212\u5316\u8bdd\u5290\u6d4d\u9a85\u6866\u94e7\u7a1e',
  huai: '\u69d0\u5f8a\u6000\u6dee\u574f\u8fd8\u8e1d',
  huan: '\u6b22\u73af\u6853\u7f13\u6362\u60a3\u5524\u75ea\u8c62\u7115\u6da3\u5ba6\u5e7b\u90c7\u5942\u57b8\u64d0\u571c\u6d39\u6d63\u6f36\u5bf0\u902d\u7f33\u953e\u9ca9\u9b1f',
  huang: '\u8352\u614c\u9ec4\u78fa\u8757\u7c27\u7687\u51f0\u60f6\u714c\u6643\u5e4c\u604d\u8c0e\u968d\u5fa8\u6e5f\u6f62\u9051\u749c\u8093\u7640\u87e5\u7bc1\u9cc7',
  hui: '\u7070\u6325\u8f89\u5fbd\u6062\u86d4\u56de\u6bc1\u6094\u6167\u5349\u60e0\u6666\u8d3f\u79fd\u4f1a\u70e9\u6c47\u8bb3\u8bf2\u7ed8\u8bd9\u8334\u835f\u8559\u54d5\u5599\u96b3\u6d04\u5f57\u7f0b\u73f2\u6656\u605a\u867a\u87ea\u9ebe',
  hun: '\u8364\u660f\u5a5a\u9b42\u6d51\u6df7\u8be8\u9984\u960d\u6eb7\u7f17',
  huo: '\u8c41\u6d3b\u4f19\u706b\u83b7\u6216\u60d1\u970d\u8d27\u7978\u6509\u56af\u5925\u94ac\u952a\u956c\u8020\u8816',
  ji: '\u51fb\u573e\u57fa\u673a\u7578\u7a3d\u79ef\u7b95\u808c\u9965\u8ff9\u6fc0\u8ba5\u9e21\u59ec\u7ee9\u7f09\u5409\u6781\u68d8\u8f91\u7c4d\u96c6\u53ca\u6025\u75be\u6c72\u5373\u5ac9\u7ea7\u6324\u51e0\u810a\u5df1\u84df\u6280\u5180\u5b63\u4f0e\u796d\u5242\u60b8\u6d4e\u5bc4\u5bc2\u8ba1\u8bb0\u65e2\u5fcc\u9645\u5993\u7ee7\u7eaa\u5c45\u4e0c\u4e69\u525e\u4f76\u4f74\u8114\u58bc\u82a8\u82b0\u8401\u84ba\u857a\u638e\u53fd\u54ad\u54dc\u5527\u5c8c\u5d74\u6d0e\u5f50\u5c50\u9aa5\u757f\u7391\u696b\u6b9b\u621f\u6222\u8d4d\u89ca\u7284\u9f51\u77f6\u7f81\u5d47\u7a37\u7620\u7635\u866e\u7b08\u7b04\u66a8\u8dfb\u8dfd\u9701\u9c9a\u9cab\u9afb\u9e82',
  jia: '\u5609\u67b7\u5939\u4f73\u5bb6\u52a0\u835a\u988a\u8d3e\u7532\u94be\u5047\u7a3c\u4ef7\u67b6\u9a7e\u5ac1\u4f3d\u90cf\u62ee\u5cac\u6d43\u8fe6\u73c8\u621b\u80db\u605d\u94d7\u9553\u75c2\u86f1\u7b33\u8888\u8dcf',
  jian: '\u6b7c\u76d1\u575a\u5c16\u7b3a\u95f4\u714e\u517c\u80a9\u8270\u5978\u7f04\u8327\u68c0\u67ec\u78b1\u7877\u62e3\u6361\u7b80\u4fed\u526a\u51cf\u8350\u69db\u9274\u8df5\u8d31\u89c1\u952e\u7bad\u4ef6\u5065\u8230\u5251\u996f\u6e10\u6e85\u6da7\u5efa\u50ed\u8c0f\u8c2b\u83c5\u84b9\u641b\u56dd\u6e54\u8e47\u8b07\u7f23\u67a7\u67d9\u6957\u620b\u622c\u726e\u728d\u6bfd\u8171\u7751\u950f\u9e63\u88e5\u7b15\u7bb4\u7fe6\u8dbc\u8e3a\u9ca3\u97af',
  jiang: '\u50f5\u59dc\u5c06\u6d46\u6c5f\u7586\u848b\u6868\u5956\u8bb2\u5320\u9171\u964d\u8333\u6d1a\u7edb\u7f30\u729f\u7913\u8029\u7ce8\u8c47',
  jiao: '\u8549\u6912\u7901\u7126\u80f6\u4ea4\u90ca\u6d47\u9a84\u5a07\u56bc\u6405\u94f0\u77eb\u4fa5\u811a\u72e1\u89d2\u997a\u7f34\u7ede\u527f\u6559\u9175\u8f7f\u8f83\u53eb\u4f7c\u50ec\u832d\u6322\u564d\u5ce4\u5fbc\u59e3\u7e9f\u656b\u768e\u9e6a\u86df\u91ae\u8de4\u9c9b',
  jie: '\u7a96\u63ed\u63a5\u7686\u79f8\u8857\u9636\u622a\u52ab\u8282\u6854\u6770\u6377\u776b\u7aed\u6d01\u7ed3\u89e3\u59d0\u6212\u85c9\u82a5\u754c\u501f\u4ecb\u75a5\u8beb\u5c4a\u5048\u8ba6\u8bd8\u5588\u55df\u736c\u5a55\u5b51\u6840\u7352\u78a3\u9534\u7596\u88b7\u9889\u86a7\u7faf\u9c92\u9ab1\u9aeb',
  jin: '\u5dfe\u7b4b\u65a4\u91d1\u4eca\u6d25\u895f\u7d27\u9526\u4ec5\u8c28\u8fdb\u9773\u664b\u7981\u8fd1\u70ec\u6d78\u5c3d\u537a\u8369\u5807\u5664\u9991\u5ed1\u5997\u7f19\u747e\u69ff\u8d46\u89d0\u9485\u9513\u887f\u77dc',
  jing: '\u52b2\u8346\u5162\u830e\u775b\u6676\u9cb8\u4eac\u60ca\u7cbe\u7cb3\u7ecf\u4e95\u8b66\u666f\u9888\u9759\u5883\u656c\u955c\u5f84\u75c9\u9756\u7adf\u7ade\u51c0\u522d\u5106\u9631\u83c1\u734d\u61ac\u6cfe\u8ff3\u5f2a\u5a67\u80bc\u80eb\u8148\u65cc',
  jiong: '\u70af\u7a98\u5182\u8fe5\u6243',
  jiu: '\u63ea\u7a76\u7ea0\u7396\u97ed\u4e45\u7078\u4e5d\u9152\u53a9\u6551\u65e7\u81fc\u8205\u548e\u5c31\u759a\u50e6\u557e\u9604\u67e9\u6855\u9e6b\u8d73\u9b0f',
  ju: '\u97a0\u62d8\u72d9\u75bd\u9a79\u83ca\u5c40\u5480\u77e9\u4e3e\u6cae\u805a\u62d2\u636e\u5de8\u5177\u8ddd\u8e1e\u952f\u4ff1\u53e5\u60e7\u70ac\u5267\u5028\u8bb5\u82e3\u82f4\u8392\u63ac\u907d\u5c66\u741a\u67b8\u6910\u6998\u6989\u6a58\u728b\u98d3\u949c\u9514\u7aad\u88fe\u8d84\u91b5\u8e3d\u9f83\u96ce\u97ab',
  juan: '\u6350\u9e43\u5a1f\u5026\u7737\u5377\u7ee2\u9104\u72f7\u6d93\u684a\u8832\u9529\u954c\u96bd',
  jue: '\u6485\u652b\u6289\u6398\u5014\u7235\u89c9\u51b3\u8bc0\u7edd\u53a5\u5282\u8c32\u77cd\u8568\u5658\u5d1b\u7357\u5b53\u73cf\u6877\u6a5b\u721d\u9562\u8e76\u89d6',
  jun: '\u5747\u83cc\u94a7\u519b\u541b\u5cfb\u4fca\u7ae3\u6d5a\u90e1\u9a8f\u6343\u72fb\u76b2\u7b60\u9e87',
  ka: '\u5580\u5496\u5361\u4f67\u5494\u80e9',
  ke: '\u54af\u5777\u82db\u67ef\u68f5\u78d5\u9897\u79d1\u58f3\u54b3\u53ef\u6e34\u514b\u523b\u5ba2\u8bfe\u5ca2\u606a\u6e98\u9a92\u7f02\u73c2\u8f72\u6c2a\u778c\u94b6\u75b4\u7aa0\u874c\u9ac1',
  kai: '\u5f00\u63e9\u6977\u51ef\u6168\u5240\u57b2\u8488\u5ffe\u607a\u94e0\u950e',
  kan: '\u520a\u582a\u52d8\u574e\u780d\u770b\u4f83\u51f5\u83b0\u83b6\u6221\u9f9b\u77b0',
  kang: '\u5eb7\u6177\u7ce0\u625b\u6297\u4ea2\u7095\u5751\u4f09\u95f6\u94aa',
  kao: '\u8003\u62f7\u70e4\u9760\u5c3b\u6832\u7292\u94d0',
  ken: '\u80af\u5543\u57a6\u6073\u57a0\u88c9\u9880',
  keng: '\u542d\u5fd0\u94ff',
  kong: '\u7a7a\u6050\u5b54\u63a7\u5025\u5d06\u7b9c',
  kou: '\u62a0\u53e3\u6263\u5bc7\u82a4\u853b\u53e9\u770d\u7b58',
  ku: '\u67af\u54ed\u7a9f\u82e6\u9177\u5e93\u88e4\u5233\u5800\u55be\u7ed4\u9ab7',
  kua: '\u5938\u57ae\u630e\u8de8\u80ef\u4f89',
  kuai: '\u5757\u7b77\u4fa9\u5feb\u84af\u90d0\u8489\u72ef\u810d',
  kuan: '\u5bbd\u6b3e\u9acb',
  kuang: '\u5321\u7b50\u72c2\u6846\u77ff\u7736\u65f7\u51b5\u8bd3\u8bf3\u909d\u5739\u593c\u54d0\u7ea9\u8d36',
  kui: '\u4e8f\u76d4\u5cbf\u7aa5\u8475\u594e\u9b41\u5080\u9988\u6127\u6e83\u9997\u532e\u5914\u9697\u63c6\u55b9\u559f\u609d\u6126\u9615\u9035\u668c\u777d\u8069\u8770\u7bd1\u81fe\u8dec',
  kun: '\u5764\u6606\u6346\u56f0\u6083\u9603\u7428\u951f\u918c\u9cb2\u9ae1',
  kuo: '\u62ec\u6269\u5ed3\u9614\u86de',
  la: '\u5783\u62c9\u5587\u8721\u814a\u8fa3\u5566\u524c\u647a\u908b\u65ef\u782c\u760c',
  lai: '\u83b1\u6765\u8d56\u5d03\u5f95\u6d9e\u6fd1\u8d49\u7750\u94fc\u765e\u7c41',
  lan: '\u84dd\u5a6a\u680f\u62e6\u7bee\u9611\u5170\u6f9c\u8c30\u63fd\u89c8\u61d2\u7f06\u70c2\u6ee5\u5549\u5c9a\u61d4\u6f24\u6984\u6593\u7f71\u9567\u8934',
  lang: '\u7405\u6994\u72fc\u5eca\u90ce\u6717\u6d6a\u83a8\u8497\u5577\u9606\u9512\u7a02\u8782',
  lao: '\u635e\u52b3\u7262\u8001\u4f6c\u59e5\u916a\u70d9\u6d9d\u5520\u5d02\u6833\u94d1\u94f9\u75e8\u91aa',
  le: '\u52d2\u4e50\u808b\u4ec2\u53fb\u561e\u6cd0\u9cd3',
  lei: '\u96f7\u956d\u857e\u78ca\u7d2f\u5121\u5792\u64c2\u7c7b\u6cea\u7fb8\u8bd4\u837d\u54a7\u6f2f\u5ad8\u7f27\u6a91\u8012\u9179',
  ling: '\u68f1\u51b7\u62ce\u73b2\u83f1\u96f6\u9f84\u94c3\u4f36\u7f9a\u51cc\u7075\u9675\u5cad\u9886\u53e6\u4ee4\u9143\u5844\u82d3\u5464\u56f9\u6ce0\u7eeb\u67c3\u68c2\u74f4\u8046\u86c9\u7fce\u9cae',
  leng: '\u695e\u6123',
  li: '\u5398\u68a8\u7281\u9ece\u7bf1\u72f8\u79bb\u6f13\u7406\u674e\u91cc\u9ca4\u793c\u8389\u8354\u540f\u6817\u4e3d\u5389\u52b1\u783e\u5386\u5229\u5088\u4f8b\u4fd0\u75e2\u7acb\u7c92\u6ca5\u96b6\u529b\u7483\u54e9\u4fea\u4fda\u90e6\u575c\u82c8\u8385\u84e0\u85dc\u6369\u5456\u5533\u55b1\u7301\u6ea7\u6fa7\u9026\u5a0c\u5ae0\u9a8a\u7f21\u73de\u67a5\u680e\u8f79\u623e\u783a\u8a48\u7f79\u9502\u9e42\u75a0\u75ac\u86ce\u870a\u8821\u7b20\u7be5\u7c9d\u91b4\u8dde\u96f3\u9ca1\u9ce2\u9ee7',
  lian: '\u4fe9\u8054\u83b2\u8fde\u9570\u5ec9\u601c\u6d9f\u5e18\u655b\u8138\u94fe\u604b\u70bc\u7ec3\u631b\u8539\u5941\u6f4b\u6fc2\u5a08\u740f\u695d\u6b93\u81c1\u81a6\u88e2\u880a\u9ca2',
  liang: '\u7cae\u51c9\u6881\u7cb1\u826f\u4e24\u8f86\u91cf\u667e\u4eae\u8c05\u589a\u690b\u8e09\u9753\u9b49',
  liao: '\u64a9\u804a\u50da\u7597\u71ce\u5be5\u8fbd\u6f66\u4e86\u6482\u9563\u5ed6\u6599\u84fc\u5c25\u5639\u7360\u5bee\u7f2d\u948c\u9e69\u8022',
  lie: '\u5217\u88c2\u70c8\u52a3\u730e\u51bd\u57d2\u6d0c\u8d94\u8e90\u9b23',
  lin: '\u7433\u6797\u78f7\u9716\u4e34\u90bb\u9cde\u6dcb\u51db\u8d41\u541d\u853a\u5d99\u5eea\u9074\u6aa9\u8f9a\u77b5\u7cbc\u8e8f\u9e9f',
  liu: '\u6e9c\u7409\u69b4\u786b\u998f\u7559\u5218\u7624\u6d41\u67f3\u516d\u62a1\u507b\u848c\u6cd6\u6d4f\u905b\u9a9d\u7efa\u65d2\u7198\u950d\u954f\u9e68\u938f',
  long: '\u9f99\u804b\u5499\u7b3c\u7abf\u9686\u5784\u62e2\u9647\u5f04\u5785\u830f\u6cf7\u73d1\u680a\u80e7\u783b\u7643',
  lou: '\u697c\u5a04\u6402\u7bd3\u6f0f\u964b\u55bd\u5d5d\u9542\u7618\u8027\u877c\u9ac5',
  lu: '\u82a6\u5362\u9885\u5e90\u7089\u63b3\u5364\u864f\u9c81\u9e93\u788c\u9732\u8def\u8d42\u9e7f\u6f5e\u7984\u5f55\u9646\u622e\u5786\u6445\u64b8\u565c\u6cf8\u6e0c\u6f09\u7490\u680c\u6a79\u8f73\u8f82\u8f98\u6c07\u80ea\u9565\u9e2c\u9e6d\u7c0f\u823b\u9c88',
  lv: '\u9a74\u5415\u94dd\u4fa3\u65c5\u5c65\u5c61\u7f15\u8651\u6c2f\u5f8b\u7387\u6ee4\u7eff\u634b\u95fe\u6988\u8182\u7a06\u891b',
  luan: '\u5ce6\u5b6a\u6ee6\u5375\u4e71\u683e\u9e3e\u92ae',
  lue: '\u63a0\u7565\u950a',
  lun: '\u8f6e\u4f26\u4ed1\u6ca6\u7eb6\u8bba\u56f5',
  luo: '\u841d\u87ba\u7f57\u903b\u9523\u7ba9\u9aa1\u88f8\u843d\u6d1b\u9a86\u7edc\u502e\u8366\u645e\u7321\u6cfa\u6924\u8136\u9559\u7630\u96d2',
  ma: '\u5988\u9ebb\u739b\u7801\u8682\u9a6c\u9a82\u561b\u5417\u551b\u72b8\u5b37\u6769\u9ebd',
  mai: '\u57cb\u4e70\u9ea6\u5356\u8fc8\u8109\u52a2\u836c\u54aa\u973e',
  man: '\u7792\u9992\u86ee\u6ee1\u8513\u66fc\u6162\u6f2b\u8c29\u5881\u5e54\u7f26\u71b3\u9558\u989f\u87a8\u9cd7\u9794',
  mang: '\u8292\u832b\u76f2\u5fd9\u83bd\u9099\u6f2d\u6726\u786d\u87d2',
  meng: '\u6c13\u840c\u8499\u6aac\u76df\u9530\u731b\u68a6\u5b5f\u52d0\u750d\u77a2\u61f5\u791e\u867b\u8722\u8813\u824b\u8268\u9efe',
  miao: '\u732b\u82d7\u63cf\u7784\u85d0\u79d2\u6e3a\u5e99\u5999\u55b5\u9088\u7f08\u7f2a\u676a\u6dfc\u7707\u9e4b\u8731',
  mao: '\u8305\u951a\u6bdb\u77db\u94c6\u536f\u8302\u5192\u5e3d\u8c8c\u8d38\u4f94\u88a4\u52d6\u8306\u5cc1\u7441\u6634\u7266\u8004\u65c4\u61cb\u7780\u86d1\u8765\u87ca\u9ae6',
  me: '\u4e48',
  mei: '\u73ab\u679a\u6885\u9176\u9709\u7164\u6ca1\u7709\u5a92\u9541\u6bcf\u7f8e\u6627\u5bd0\u59b9\u5a9a\u5776\u8393\u5d4b\u7338\u6d7c\u6e44\u6963\u9545\u9e5b\u8882\u9b45',
  men: '\u95e8\u95f7\u4eec\u626a\u739f\u7116\u61d1\u9494',
  mi: '\u772f\u919a\u9761\u7cdc\u8ff7\u8c1c\u5f25\u7c73\u79d8\u89c5\u6ccc\u871c\u5bc6\u5e42\u8288\u5196\u8c27\u863c\u5627\u7315\u736f\u6c68\u5b93\u5f2d\u8112\u6549\u7cf8\u7e3b\u9e8b',
  mian: '\u68c9\u7720\u7ef5\u5195\u514d\u52c9\u5a29\u7f05\u9762\u6c94\u6e4e\u817c\u7704',
  mie: '\u8511\u706d\u54a9\u881b\u7bfe',
  min: '\u6c11\u62bf\u76bf\u654f\u60af\u95fd\u82e0\u5cb7\u95f5\u6cef\u73c9',
  ming: '\u660e\u879f\u9e23\u94ed\u540d\u547d\u51a5\u8317\u6e9f\u669d\u7791\u9169',
  miu: '\u8c2c',
  mo: '\u6478\u6479\u8611\u6a21\u819c\u78e8\u6469\u9b54\u62b9\u672b\u83ab\u58a8\u9ed8\u6cab\u6f20\u5bde\u964c\u8c1f\u8309\u84e6\u998d\u5aeb\u9546\u79e3\u763c\u8031\u87c6\u8c8a\u8c98',
  mou: '\u8c0b\u725f\u67d0\u53b6\u54de\u5a7a\u7738\u936a',
  mu: '\u62c7\u7261\u4ea9\u59c6\u6bcd\u5893\u66ae\u5e55\u52df\u6155\u6728\u76ee\u7766\u7267\u7a46\u4eeb\u82dc\u5452\u6c90\u6bea\u94bc',
  na: '\u62ff\u54ea\u5450\u94a0\u90a3\u5a1c\u7eb3\u5185\u637a\u80ad\u954e\u8872\u7bac',
  nai: '\u6c16\u4e43\u5976\u8010\u5948\u9f10\u827f\u8418\u67f0',
  nan: '\u5357\u7537\u96be\u56ca\u5583\u56e1\u6960\u8169\u877b\u8d67',
  nao: '\u6320\u8111\u607c\u95f9\u5b6c\u57b4\u7331\u7459\u7847\u94d9\u86f2',
  ne: '\u6dd6\u5462\u8bb7',
  nei: '\u9981',
  nen: '\u5ae9\u80fd\u6798\u6041',
  ni: '\u59ae\u9713\u502a\u6ce5\u5c3c\u62df\u4f60\u533f\u817b\u9006\u6eba\u4f32\u576d\u730a\u6029\u6ee0\u6635\u65ce\u7962\u615d\u7768\u94cc\u9cb5',
  nian: '\u852b\u62c8\u5e74\u78be\u64b5\u637b\u5ff5\u5eff\u8f87\u9ecf\u9c87\u9cb6',
  niang: '\u5a18\u917f',
  niao: '\u9e1f\u5c3f\u8311\u5b32\u8132\u8885',
  nie: '\u634f\u8042\u5b7d\u556e\u954a\u954d\u6d85\u4e5c\u9667\u8616\u55eb\u8080\u989e\u81ec\u8e51',
  nin: '\u60a8\u67e0',
  ning: '\u72de\u51dd\u5b81\u62e7\u6cde\u4f5e\u84e5\u549b\u752f\u804d',
  niu: '\u725b\u626d\u94ae\u7ebd\u72c3\u5ff8\u599e\u86b4',
  nong: '\u8113\u6d53\u519c\u4fac',
  nu: '\u5974\u52aa\u6012\u5476\u5e11\u5f29\u80ec\u5b65\u9a7d',
  nv: '\u5973\u6067\u9495\u8844',
  nuan: '\u6696',
  nuenue: '\u8650',
  nue: '\u759f\u8c11',
  nuo: '\u632a\u61e6\u7cef\u8bfa\u50a9\u6426\u558f\u9518',
  ou: '\u54e6\u6b27\u9e25\u6bb4\u85d5\u5455\u5076\u6ca4\u6004\u74ef\u8026',
  pa: '\u556a\u8db4\u722c\u5e15\u6015\u7436\u8469\u7b62',
  pai: '\u62cd\u6392\u724c\u5f98\u6e43\u6d3e\u4ff3\u848e',
  pan: '\u6500\u6f58\u76d8\u78d0\u76fc\u7554\u5224\u53db\u723f\u6cee\u88a2\u897b\u87e0\u8e52',
  pang: '\u4e53\u5e9e\u65c1\u802a\u80d6\u6ec2\u9004',
  pao: '\u629b\u5486\u5228\u70ae\u888d\u8dd1\u6ce1\u530f\u72cd\u5e96\u812c\u75b1',
  pei: '\u5478\u80da\u57f9\u88f4\u8d54\u966a\u914d\u4f69\u6c9b\u638a\u8f94\u5e14\u6de0\u65c6\u952b\u9185\u9708',
  pen: '\u55b7\u76c6\u6e53',
  peng: '\u7830\u62a8\u70f9\u6f8e\u5f6d\u84ec\u68da\u787c\u7bf7\u81a8\u670b\u9e4f\u6367\u78b0\u576f\u580b\u562d\u6026\u87db',
  pi: '\u7812\u9739\u6279\u62ab\u5288\u7435\u6bd7\u5564\u813e\u75b2\u76ae\u5339\u75de\u50fb\u5c41\u8b6c\u4e15\u9674\u90b3\u90eb\u572e\u9f19\u64d7\u567c\u5e80\u5ab2\u7eb0\u6787\u7513\u7765\u7f74\u94cd\u75e6\u7656\u758b\u868d\u8c94',
  pian: '\u7bc7\u504f\u7247\u9a97\u8c1d\u9a88\u728f\u80fc\u890a\u7fe9\u8e41',
  piao: '\u98d8\u6f02\u74e2\u7968\u527d\u560c\u5ad6\u7f25\u6b8d\u779f\u87b5',
  pie: '\u6487\u77a5\u4e3f\u82e4\u6c15',
  pin: '\u62fc\u9891\u8d2b\u54c1\u8058\u62da\u59d8\u5ad4\u6980\u725d\u98a6',
  ping: '\u4e52\u576a\u82f9\u840d\u5e73\u51ed\u74f6\u8bc4\u5c4f\u4fdc\u5a09\u67b0\u9c86',
  po: '\u5761\u6cfc\u9887\u5a46\u7834\u9b44\u8feb\u7c95\u53f5\u9131\u6ea5\u73c0\u948b\u94b7\u76a4\u7b38',
  pou: '\u5256\u88d2\u8e23',
  pu: '\u6251\u94fa\u4ec6\u8386\u8461\u83e9\u84b2\u57d4\u6734\u5703\u666e\u6d66\u8c31\u66dd\u7011\u530d\u5657\u6fee\u749e\u6c06\u9564\u9568\u8e7c',
  qi: '\u671f\u6b3a\u6816\u621a\u59bb\u4e03\u51c4\u6f06\u67d2\u6c8f\u5176\u68cb\u5947\u6b67\u7566\u5d0e\u8110\u9f50\u65d7\u7948\u7941\u9a91\u8d77\u5c82\u4e5e\u4f01\u542f\u5951\u780c\u5668\u6c14\u8fc4\u5f03\u6c7d\u6ce3\u8bab\u4e9f\u4e93\u573b\u8291\u840b\u847a\u5601\u5c7a\u5c90\u6c54\u6dc7\u9a90\u7eee\u742a\u7426\u675e\u6864\u69ed\u6b39\u797a\u61a9\u789b\u86f4\u871e\u7da6\u7dae\u8dbf\u8e4a\u9ccd\u9e92',
  qia: '\u6390\u6070\u6d3d\u845c',
  qian: '\u7275\u6266\u948e\u94c5\u5343\u8fc1\u7b7e\u4edf\u8c26\u4e7e\u9ed4\u94b1\u94b3\u524d\u6f5c\u9063\u6d45\u8c34\u5811\u5d4c\u6b20\u6b49\u4f65\u9621\u828a\u82a1\u8368\u63ae\u5c8d\u60ad\u614a\u9a9e\u6434\u8930\u7f31\u6920\u80b7\u6106\u94a4\u8654\u7b9d',
  qiang: '\u67aa\u545b\u8154\u7f8c\u5899\u8537\u5f3a\u62a2\u5af1\u6a2f\u6217\u709d\u9516\u9535\u956a\u8941\u8723\u7f9f\u8deb\u8dc4',
  qiao: '\u6a47\u9539\u6572\u6084\u6865\u77a7\u4e54\u4fa8\u5de7\u9798\u64ac\u7fd8\u5ced\u4fcf\u7a8d\u5281\u8bee\u8c2f\u835e\u6100\u6194\u7f32\u6a35\u6bf3\u7857\u8df7\u9792',
  qie: '\u5207\u8304\u4e14\u602f\u7a83\u90c4\u553c\u60ec\u59be\u6308\u9532\u7ba7',
  qin: '\u94a6\u4fb5\u4eb2\u79e6\u7434\u52e4\u82b9\u64d2\u79bd\u5bdd\u6c81\u82a9\u84c1\u8572\u63ff\u5423\u55ea\u5659\u6eb1\u6a8e\u8793\u887e',
  qing: '\u9752\u8f7b\u6c22\u503e\u537f\u6e05\u64ce\u6674\u6c30\u60c5\u9877\u8bf7\u5e86\u5029\u82d8\u570a\u6aa0\u78ec\u873b\u7f44\u7b90\u8b26\u9cad\u9ee5',
  qiong: '\u743c\u7a77\u909b\u8315\u7a79\u7b47\u928e',
  qiu: '\u79cb\u4e18\u90b1\u7403\u6c42\u56da\u914b\u6cc5\u4fc5\u6c3d\u5def\u827d\u72b0\u6e6b\u9011\u9052\u6978\u8d47\u9e20\u866c\u86af\u8764\u88d8\u7cd7\u9cc5\u9f3d',
  qu: '\u8d8b\u533a\u86c6\u66f2\u8eaf\u5c48\u9a71\u6e20\u53d6\u5a36\u9f8b\u8da3\u53bb\u8bce\u52ac\u8556\u8627\u5c96\u8862\u9612\u74a9\u89d1\u6c0d\u795b\u78f2\u766f\u86d0\u883c\u9eb4\u77bf\u9ee2',
  quan: '\u5708\u98a7\u6743\u919b\u6cc9\u5168\u75ca\u62f3\u72ac\u5238\u529d\u8be0\u8343\u737e\u609b\u7efb\u8f81\u754e\u94e8\u8737\u7b4c\u9b08',
  que: '\u7f3a\u7094\u7638\u5374\u9e4a\u69b7\u786e\u96c0\u9619\u60ab',
  qun: '\u88d9\u7fa4\u9021',
  ran: '\u7136\u71c3\u5189\u67d3\u82d2\u9aef',
  rang: '\u74e4\u58e4\u6518\u56b7\u8ba9\u79b3\u7a70',
  rao: '\u9976\u6270\u7ed5\u835b\u5a06\u6861',
  ruo: '\u60f9\u82e5\u5f31',
  re: '\u70ed\u504c',
  ren: '\u58ec\u4ec1\u4eba\u5fcd\u97e7\u4efb\u8ba4\u5203\u598a\u7eab\u4ede\u834f\u845a\u996a\u8f6b\u7a14\u887d',
  reng: '\u6254\u4ecd',
  ri: '\u65e5',
  rong: '\u620e\u8338\u84c9\u8363\u878d\u7194\u6eb6\u5bb9\u7ed2\u5197\u5d58\u72e8\u7f1b\u6995\u877e',
  rou: '\u63c9\u67d4\u8089\u7cc5\u8e42\u97a3',
  ru: '\u8339\u8815\u5112\u5b7a\u5982\u8fb1\u4e73\u6c5d\u5165\u8925\u84d0\u85b7\u5685\u6d33\u6ebd\u6fe1\u94f7\u8966\u98a5',
  ruan: '\u8f6f\u962e\u670a',
  rui: '\u854a\u745e\u9510\u82ae\u8564\u777f\u868b',
  run: '\u95f0\u6da6',
  sa: '\u6492\u6d12\u8428\u5345\u4ee8\u6332\u98d2',
  sai: '\u816e\u9cc3\u585e\u8d5b\u567b',
  san: '\u4e09\u53c1\u4f1e\u6563\u5f61\u9993\u6c35\u6bf5\u7cc1\u9730',
  sang: '\u6851\u55d3\u4e27\u6421\u78c9\u98a1',
  sao: '\u6414\u9a9a\u626b\u5ac2\u57fd\u81ca\u7619\u9ccb',
  se: '\u745f\u8272\u6da9\u556c\u94e9\u94ef\u7a51',
  sen: '\u68ee',
  seng: '\u50e7',
  sha: '\u838e\u7802\u6740\u5239\u6c99\u7eb1\u50bb\u5565\u715e\u810e\u6b43\u75e7\u88df\u970e\u9ca8',
  shai: '\u7b5b\u6652\u917e',
  shan: '\u73ca\u82eb\u6749\u5c71\u5220\u717d\u886b\u95ea\u9655\u64c5\u8d61\u81b3\u5584\u6c55\u6247\u7f2e\u5261\u8baa\u912f\u57cf\u829f\u6f78\u59d7\u9a9f\u81bb\u9490\u759d\u87ee\u8222\u8dda\u9cdd',
  shang: '\u5892\u4f24\u5546\u8d4f\u664c\u4e0a\u5c1a\u88f3\u57a7\u7ef1\u6b87\u71b5\u89de',
  shao: '\u68a2\u634e\u7a0d\u70e7\u828d\u52fa\u97f6\u5c11\u54e8\u90b5\u7ecd\u52ad\u82d5\u6f72\u86f8\u7b24\u7b72\u8244',
  she: '\u5962\u8d4a\u86c7\u820c\u820d\u8d66\u6444\u5c04\u6151\u6d89\u793e\u8bbe\u538d\u4f58\u731e\u7572\u9e9d',
  shen: '\u7837\u7533\u547b\u4f38\u8eab\u6df1\u5a20\u7ec5\u795e\u6c88\u5ba1\u5a76\u751a\u80be\u614e\u6e17\u8bdc\u8c02\u5432\u54c2\u6e16\u6939\u77e7\u8703',
  sheng: '\u58f0\u751f\u7525\u7272\u5347\u7ef3\u7701\u76db\u5269\u80dc\u5723\u4e1e\u6e11\u5ab5\u771a\u7b19',
  shi: '\u5e08\u5931\u72ee\u65bd\u6e7f\u8bd7\u5c38\u8671\u5341\u77f3\u62fe\u65f6\u4ec0\u98df\u8680\u5b9e\u8bc6\u53f2\u77e2\u4f7f\u5c4e\u9a76\u59cb\u5f0f\u793a\u58eb\u4e16\u67ff\u4e8b\u62ed\u8a93\u901d\u52bf\u662f\u55dc\u566c\u9002\u4ed5\u4f8d\u91ca\u9970\u6c0f\u5e02\u6043\u5ba4\u89c6\u8bd5\u8c25\u57d8\u83b3\u84cd\u5f11\u5511\u9963\u8f7c\u8006\u8d33\u70bb\u793b\u94c8\u94ca\u87ab\u8210\u7b6e\u8c55\u9ca5\u9cba',
  shou: '\u6536\u624b\u9996\u5b88\u5bff\u6388\u552e\u53d7\u7626\u517d\u624c\u72e9\u7ef6\u824f',
  shu: '\u852c\u67a2\u68b3\u6b8a\u6292\u8f93\u53d4\u8212\u6dd1\u758f\u4e66\u8d4e\u5b70\u719f\u85af\u6691\u66d9\u7f72\u8700\u9ecd\u9f20\u5c5e\u672f\u8ff0\u6811\u675f\u620d\u7ad6\u5885\u5eb6\u6570\u6f31\u6055\u500f\u587e\u83fd\u5fc4\u6cad\u6d91\u6f8d\u59dd\u7ebe\u6bf9\u8167\u6bb3\u956f\u79eb\u9e6c',
  shua: '\u5237\u800d\u5530\u6dae',
  shuai: '\u6454\u8870\u7529\u5e05\u87c0',
  shuan: '\u6813\u62f4\u95e9',
  shuang: '\u971c\u53cc\u723d\u5b40',
  shui: '\u8c01\u6c34\u7761\u7a0e',
  shun: '\u542e\u77ac\u987a\u821c\u6042',
  shuo: '\u8bf4\u7855\u6714\u70c1\u84b4\u6420\u55cd\u6fef\u5981\u69ca\u94c4',
  si: '\u65af\u6495\u5636\u601d\u79c1\u53f8\u4e1d\u6b7b\u8086\u5bfa\u55e3\u56db\u4f3a\u4f3c\u9972\u5df3\u53ae\u4fdf\u5155\u83e5\u549d\u6c5c\u6cd7\u6f8c\u59d2\u9a77\u7f0c\u7940\u7960\u9536\u9e36\u801c\u86f3\u7b25',
  song: '\u677e\u8038\u6002\u9882\u9001\u5b8b\u8bbc\u8bf5\u51c7\u83d8\u5d27\u5d69\u5fea\u609a\u6dde\u7ae6',
  sou: '\u641c\u8258\u64de\u55fd\u53df\u55d6\u55fe\u998a\u6eb2\u98d5\u778d\u953c\u878b',
  su: '\u82cf\u9165\u4fd7\u7d20\u901f\u7c9f\u50f3\u5851\u6eaf\u5bbf\u8bc9\u8083\u5919\u8c21\u850c\u55c9\u612b\u7c0c\u89eb\u7a23',
  suan: '\u9178\u849c\u7b97',
  sui: '\u867d\u968b\u968f\u7ee5\u9ad3\u788e\u5c81\u7a57\u9042\u96a7\u795f\u84d1\u51ab\u8c07\u6fc9\u9083\u71e7\u772d\u7762',
  sun: '\u5b59\u635f\u7b0b\u836a\u72f2\u98e7\u69ab\u8de3\u96bc',
  suo: '\u68ad\u5506\u7f29\u7410\u7d22\u9501\u6240\u5522\u55e6\u5a11\u686b\u7743\u7fa7',
  ta: '\u584c\u4ed6\u5b83\u5979\u5854\u736d\u631e\u8e4b\u8e0f\u95fc\u6ebb\u9062\u69bb\u6c93',
  tai: '\u80ce\u82d4\u62ac\u53f0\u6cf0\u915e\u592a\u6001\u6c70\u90b0\u85b9\u80bd\u70b1\u949b\u8dc6\u9c90',
  tan: '\u574d\u644a\u8d2a\u762b\u6ee9\u575b\u6a80\u75f0\u6f6d\u8c2d\u8c08\u5766\u6bef\u8892\u78b3\u63a2\u53f9\u70ad\u90ef\u8548\u6619\u94bd\u952c\u8983',
  tang: '\u6c64\u5858\u642a\u5802\u68e0\u819b\u5510\u7cd6\u50a5\u9967\u6e8f\u746d\u94f4\u9557\u8025\u8797\u87b3\u7fb0\u91a3',
  thang: '\u5018\u8eba\u6dcc',
  theng: '\u8d9f\u70eb',
  tao: '\u638f\u6d9b\u6ed4\u7ee6\u8404\u6843\u9003\u6dd8\u9676\u8ba8\u5957\u6311\u9f17\u5555\u97ec\u9955',
  te: '\u7279',
  teng: '\u85e4\u817e\u75bc\u8a8a\u6ed5',
  ti: '\u68af\u5254\u8e22\u9511\u63d0\u9898\u8e44\u557c\u4f53\u66ff\u568f\u60d5\u6d95\u5243\u5c49\u8351\u608c\u9016\u7ee8\u7f07\u9e48\u88fc\u918d',
  tian: '\u5929\u6dfb\u586b\u7530\u751c\u606c\u8214\u8146\u63ad\u5fdd\u9617\u6b84\u754b\u94bf\u86ba',
  tiao: '\u6761\u8fe2\u773a\u8df3\u4f7b\u7967\u94eb\u7a95\u9f86\u9ca6',
  tie: '\u8d34\u94c1\u5e16\u841c\u992e',
  ting: '\u5385\u542c\u70c3\u6c40\u5ef7\u505c\u4ead\u5ead\u633a\u8247\u839b\u8476\u5a77\u6883\u8713\u9706',
  tong: '\u901a\u6850\u916e\u77b3\u540c\u94dc\u5f64\u7ae5\u6876\u6345\u7b52\u7edf\u75db\u4f5f\u50ee\u4edd\u833c\u55f5\u6078\u6f7c\u783c',
  tou: '\u5077\u6295\u5934\u900f\u4ea0',
  tu: '\u51f8\u79c3\u7a81\u56fe\u5f92\u9014\u6d82\u5c60\u571f\u5410\u5154\u580d\u837c\u83df\u948d\u9174',
  tuan: '\u6e4d\u56e2\u7583',
  tui: '\u63a8\u9893\u817f\u8715\u892a\u9000\u5fd2\u717a',
  tun: '\u541e\u5c6f\u81c0\u9968\u66be\u8c5a\u7a80',
  tuo: '\u62d6\u6258\u8131\u9e35\u9640\u9a6e\u9a7c\u692d\u59a5\u62d3\u553e\u4e47\u4f57\u5768\u5eb9\u6cb1\u67dd\u7823\u7ba8\u8204\u8dce\u9f0d',
  wa: '\u6316\u54c7\u86d9\u6d3c\u5a03\u74e6\u889c\u4f64\u5a32\u817d',
  wai: '\u6b6a\u5916',
  wan: '\u8c4c\u5f2f\u6e7e\u73a9\u987d\u4e38\u70f7\u5b8c\u7897\u633d\u665a\u7696\u60cb\u5b9b\u5a49\u4e07\u8155\u525c\u8284\u82cb\u83c0\u7ea8\u7efe\u742c\u8118\u7579\u873f\u7ba2',
  wang: '\u6c6a\u738b\u4ea1\u6789\u7f51\u5f80\u65fa\u671b\u5fd8\u5984\u7f54\u5c22\u60d8\u8f8b\u9b4d',
  wei: '\u5a01\u5dcd\u5fae\u5371\u97e6\u8fdd\u6845\u56f4\u552f\u60df\u4e3a\u6f4d\u7ef4\u82c7\u840e\u59d4\u4f1f\u4f2a\u5c3e\u7eac\u672a\u851a\u5473\u754f\u80c3\u5582\u9b4f\u4f4d\u6e2d\u8c13\u5c09\u6170\u536b\u502d\u504e\u8bff\u9688\u8473\u8587\u5e0f\u5e37\u5d34\u5d6c\u7325\u732c\u95f1\u6ca9\u6d27\u6da0\u9036\u5a13\u73ae\u97ea\u8ece\u709c\u7168\u71a8\u75ff\u8249\u9c94',
  wen: '\u761f\u6e29\u868a\u6587\u95fb\u7eb9\u543b\u7a33\u7d0a\u95ee\u520e\u6120\u960c\u6c76\u74ba\u97eb\u6b81\u96ef',
  weng: '\u55e1\u7fc1\u74ee\u84ca\u8579',
  wo: '\u631d\u8717\u6da1\u7a9d\u6211\u65a1\u5367\u63e1\u6c83\u83b4\u5e44\u6e25\u674c\u809f\u9f8c',
  wu: '\u5deb\u545c\u94a8\u4e4c\u6c61\u8bec\u5c4b\u65e0\u829c\u68a7\u543e\u5434\u6bcb\u6b66\u4e94\u6342\u5348\u821e\u4f0d\u4fae\u575e\u620a\u96fe\u6664\u7269\u52ff\u52a1\u609f\u8bef\u5140\u4ef5\u9622\u90ac\u572c\u82b4\u5e91\u6003\u5fe4\u6d6f\u5be4\u8fd5\u59a9\u9a9b\u727e\u7110\u9e49\u9e5c\u8708\u92c8\u9f2f',
  xi: '\u6614\u7199\u6790\u897f\u7852\u77fd\u6670\u563b\u5438\u9521\u727a\u7a00\u606f\u5e0c\u6089\u819d\u5915\u60dc\u7184\u70ef\u6eaa\u6c50\u7280\u6a84\u88ad\u5e2d\u4e60\u5ab3\u559c\u94e3\u6d17\u7cfb\u9699\u620f\u7ec6\u50d6\u516e\u96b0\u90d7\u831c\u8478\u84f0\u595a\u550f\u5f99\u9969\u960b\u6d60\u6dc5\u5c63\u5b09\u73ba\u6a28\u66e6\u89cb\u6b37\u71b9\u798a\u79a7\u94b8\u7699\u7a78\u8725\u87cb\u823e\u7fb2\u7c9e\u7fd5\u91af\u9f37',
  xia: '\u778e\u867e\u5323\u971e\u8f96\u6687\u5ce1\u4fa0\u72ed\u4e0b\u53a6\u590f\u5413\u6380\u846d\u55c4\u72ce\u9050\u7455\u7856\u7615\u7f45\u9ee0',
  xian: '\u9528\u5148\u4ed9\u9c9c\u7ea4\u54b8\u8d24\u8854\u8237\u95f2\u6d8e\u5f26\u5acc\u663e\u9669\u73b0\u732e\u53bf\u817a\u9985\u7fa1\u5baa\u9677\u9650\u7ebf\u51bc\u85d3\u5c98\u7303\u66b9\u5a34\u6c19\u7946\u9e47\u75eb\u86ac\u7b45\u7c7c\u9170\u8df9',
  xiang: '\u76f8\u53a2\u9576\u9999\u7bb1\u8944\u6e58\u4e61\u7fd4\u7965\u8be6\u60f3\u54cd\u4eab\u9879\u5df7\u6a61\u50cf\u5411\u8c61\u8297\u8459\u9977\u5ea0\u9aa7\u7f03\u87d3\u9c9e\u98e8',
  xiao: '\u8427\u785d\u9704\u524a\u54ee\u56a3\u9500\u6d88\u5bb5\u6dc6\u6653\u5c0f\u5b5d\u6821\u8096\u5578\u7b11\u6548\u54d3\u54bb\u5d24\u6f47\u900d\u9a81\u7ee1\u67ad\u67b5\u7b71\u7bab\u9b48',
  xie: '\u6954\u4e9b\u6b47\u874e\u978b\u534f\u631f\u643a\u90aa\u659c\u80c1\u8c10\u5199\u68b0\u5378\u87f9\u61c8\u6cc4\u6cfb\u8c22\u5c51\u5055\u4eb5\u52f0\u71ee\u85a4\u64b7\u5ee8\u7023\u9082\u7ec1\u7f2c\u69ad\u698d\u6b59\u8e9e',
  xin: '\u85aa\u82af\u950c\u6b23\u8f9b\u65b0\u5ffb\u5fc3\u4fe1\u8845\u56df\u99a8\u8398\u6b46\u94fd\u946b',
  xing: '\u661f\u8165\u7329\u60fa\u5174\u5211\u578b\u5f62\u90a2\u884c\u9192\u5e78\u674f\u6027\u59d3\u9649\u8347\u8365\u64e4\u60bb\u784e',
  xiong: '\u5144\u51f6\u80f8\u5308\u6c79\u96c4\u718a\u828e',
  xiu: '\u4f11\u4fee\u7f9e\u673d\u55c5\u9508\u79c0\u8896\u7ee3\u83a0\u5cab\u9990\u5ea5\u9e3a\u8c85\u9af9',
  xu: '\u589f\u620c\u9700\u865a\u5618\u987b\u5f90\u8bb8\u84c4\u9157\u53d9\u65ed\u5e8f\u755c\u6064\u7d6e\u5a7f\u7eea\u7eed\u8bb4\u8be9\u5729\u84ff\u6035\u6d2b\u6e86\u987c\u6829\u7166\u7809\u76f1\u80e5\u7cc8\u9191',
  xuan: '\u8f69\u55a7\u5ba3\u60ac\u65cb\u7384\u9009\u7663\u7729\u7eda\u5107\u8c16\u8431\u63ce\u9994\u6ceb\u6d35\u6e32\u6f29\u7487\u6966\u6684\u70ab\u714a\u78b9\u94c9\u955f\u75c3',
  xue: '\u9774\u859b\u5b66\u7a74\u96ea\u8840\u5671\u6cf6\u9cd5',
  xun: '\u52cb\u718f\u5faa\u65ec\u8be2\u5bfb\u9a6f\u5de1\u6b89\u6c5b\u8bad\u8baf\u900a\u8fc5\u5dfd\u57d9\u8340\u85b0\u5ccb\u5f87\u6d54\u66db\u7aa8\u91ba\u9c9f',
  ya: '\u538b\u62bc\u9e26\u9e2d\u5440\u4e2b\u82bd\u7259\u869c\u5d16\u8859\u6daf\u96c5\u54d1\u4e9a\u8bb6\u4f22\u63e0\u5416\u5c88\u8fd3\u5a05\u740a\u6860\u6c29\u7811\u775a\u75d6',
  yan: '\u7109\u54bd\u9609\u70df\u6df9\u76d0\u4e25\u7814\u8712\u5ca9\u5ef6\u8a00\u989c\u960e\u708e\u6cbf\u5944\u63a9\u773c\u884d\u6f14\u8273\u5830\u71d5\u538c\u781a\u96c1\u5501\u5f66\u7130\u5bb4\u8c1a\u9a8c\u53a3\u9765\u8d5d\u4fe8\u5043\u5156\u8ba0\u8c33\u90fe\u9122\u82ab\u83f8\u5d26\u6079\u95eb\u960f\u6d07\u6e6e\u6edf\u598d\u5ae3\u7430\u664f\u80ed\u814c\u7131\u7f68\u7b75\u917d\u9b47\u990d\u9f39',
  yang: '\u6b83\u592e\u9e2f\u79e7\u6768\u626c\u4f6f\u75a1\u7f8a\u6d0b\u9633\u6c27\u4ef0\u75d2\u517b\u6837\u6f3e\u5f89\u600f\u6cf1\u7080\u70ca\u6059\u86d8\u9785',
  yao: '\u9080\u8170\u5996\u7476\u6447\u5c27\u9065\u7a91\u8c23\u59da\u54ac\u8200\u836f\u8981\u8000\u592d\u723b\u5406\u5d3e\u5fad\u7039\u5e7a\u73e7\u6773\u66dc\u80b4\u9e5e\u7a88\u7e47\u9cd0',
  ye: '\u6930\u564e\u8036\u7237\u91ce\u51b6\u4e5f\u9875\u6396\u4e1a\u53f6\u66f3\u814b\u591c\u6db2\u8c12\u90ba\u63f6\u9980\u6654\u70e8\u94d8',
  yi: '\u4e00\u58f9\u533b\u63d6\u94f1\u4f9d\u4f0a\u8863\u9890\u5937\u9057\u79fb\u4eea\u80f0\u7591\u6c82\u5b9c\u59e8\u5f5d\u6905\u8681\u501a\u5df2\u4e59\u77e3\u4ee5\u827a\u6291\u6613\u9091\u5c79\u4ebf\u5f79\u81c6\u9038\u8084\u75ab\u4ea6\u88d4\u610f\u6bc5\u5fc6\u4e49\u76ca\u6ea2\u8be3\u8bae\u8c0a\u8bd1\u5f02\u7ffc\u7fcc\u7ece\u5208\u5293\u4f7e\u8bd2\u572a\u572f\u57f8\u61ff\u82e1\u858f\u5f08\u5955\u6339\u5f0b\u5453\u54a6\u54bf\u566b\u5cc4\u5db7\u7317\u9974\u603f\u6021\u6092\u6f2a\u8fe4\u9a7f\u7f22\u6baa\u8d3b\u65d6\u71a0\u9487\u9552\u9571\u75cd\u7617\u7654\u7fca\u8864\u8734\u8223\u7fbf\u7ff3\u914f\u9edf',
  yin: '\u8335\u836b\u56e0\u6bb7\u97f3\u9634\u59fb\u541f\u94f6\u6deb\u5bc5\u996e\u5c39\u5f15\u9690\u5370\u80e4\u911e\u5819\u831a\u5591\u72fa\u5924\u6c24\u94df\u763e\u8693\u972a\u9f88',
  ying: '\u82f1\u6a31\u5a74\u9e70\u5e94\u7f28\u83b9\u8424\u8425\u8367\u8747\u8fce\u8d62\u76c8\u5f71\u9896\u786c\u6620\u5b34\u90e2\u8314\u83ba\u8426\u6484\u5624\u81ba\u6ee2\u6f46\u701b\u745b\u748e\u6979\u9e66\u763f\u988d\u7f42',
  yo: '\u54df\u5537',
  yong: '\u62e5\u4f63\u81c3\u75c8\u5eb8\u96cd\u8e0a\u86f9\u548f\u6cf3\u6d8c\u6c38\u607f\u52c7\u7528\u4fd1\u58c5\u5889\u6175\u9095\u955b\u752c\u9cd9\u9954',
  you: '\u5e7d\u4f18\u60a0\u5fe7\u5c24\u7531\u90ae\u94c0\u72b9\u6cb9\u6e38\u9149\u6709\u53cb\u53f3\u4f51\u91c9\u8bf1\u53c8\u5e7c\u5363\u6538\u4f91\u83b8\u5466\u56ff\u5ba5\u67da\u7337\u7256\u94d5\u75a3\u8763\u9c7f\u9edd\u9f2c',
  yu: '\u8fc2\u6de4\u4e8e\u76c2\u6986\u865e\u611a\u8206\u4f59\u4fde\u903e\u9c7c\u6109\u6e1d\u6e14\u9685\u4e88\u5a31\u96e8\u4e0e\u5c7f\u79b9\u5b87\u8bed\u7fbd\u7389\u57df\u828b\u90c1\u5401\u9047\u55bb\u5cea\u5fa1\u6108\u6b32\u72f1\u80b2\u8a89\u6d74\u5bd3\u88d5\u9884\u8c6b\u9a6d\u79ba\u6bd3\u4f1b\u4fe3\u8c00\u8c15\u8438\u84e3\u63c4\u5581\u5704\u5709\u5d5b\u72f3\u996b\u5ebe\u9608\u59aa\u59a4\u7ea1\u745c\u6631\u89ce\u8174\u6b24\u65bc\u715c\u71e0\u807f\u94b0\u9e46\u7610\u7600\u7ab3\u8753\u7afd\u8201\u96e9\u9f89',
  yuan: '\u9e33\u6e0a\u51a4\u5143\u57a3\u8881\u539f\u63f4\u8f95\u56ed\u5458\u5706\u733f\u6e90\u7f18\u8fdc\u82d1\u613f\u6028\u9662\u586c\u6c85\u5a9b\u7457\u6a7c\u7230\u7722\u9e22\u8788\u9f0b',
  yue: '\u66f0\u7ea6\u8d8a\u8dc3\u94a5\u5cb3\u7ca4\u6708\u60a6\u9605\u9fa0\u6a3e\u5216\u94ba',
  yun: '\u8018\u4e91\u90e7\u5300\u9668\u5141\u8fd0\u8574\u915d\u6655\u97f5\u5b55\u90d3\u82b8\u72c1\u607d\u7ead\u6b92\u6600\u6c32',
  za: '\u531d\u7838\u6742\u62f6\u5482',
  zai: '\u683d\u54c9\u707e\u5bb0\u8f7d\u518d\u5728\u54b1\u5d3d\u753e',
  zan: '\u6512\u6682\u8d5e\u74d2\u661d\u7c2a\u7ccc\u8db1\u933e',
  zang: '\u8d43\u810f\u846c\u5958\u6215\u81e7',
  zao: '\u906d\u7cdf\u51ff\u85fb\u67a3\u65e9\u6fa1\u86a4\u8e81\u566a\u9020\u7682\u7076\u71e5\u5523\u7f2b',
  ze: '\u8d23\u62e9\u5219\u6cfd\u4ec4\u8d5c\u5567\u8fee\u6603\u7b2e\u7ba6\u8234',
  zei: '\u8d3c',
  zen: '\u600e\u8c2e',
  zeng: '\u589e\u618e\u66fe\u8d60\u7f2f\u7511\u7f7e\u9503',
  zha: '\u624e\u55b3\u6e23\u672d\u8f67\u94e1\u95f8\u7728\u6805\u69a8\u548b\u4e4d\u70b8\u8bc8\u63f8\u5412\u54a4\u54f3\u600d\u781f\u75c4\u86b1\u9f44',
  zhai: '\u6458\u658b\u5b85\u7a84\u503a\u5be8\u7826',
  zhan: '\u77bb\u6be1\u8a79\u7c98\u6cbe\u76cf\u65a9\u8f97\u5d2d\u5c55\u8638\u6808\u5360\u6218\u7ad9\u6e5b\u7efd\u8c35\u640c\u65c3',
  zhang: '\u6a1f\u7ae0\u5f70\u6f33\u5f20\u638c\u6da8\u6756\u4e08\u5e10\u8d26\u4ed7\u80c0\u7634\u969c\u4ec9\u9123\u5e5b\u5d82\u7350\u5adc\u748b\u87d1',
  zhao: '\u62db\u662d\u627e\u6cbc\u8d75\u7167\u7f69\u5146\u8087\u53ec\u722a\u8bcf\u68f9\u948a\u7b0a',
  zhe: '\u906e\u6298\u54f2\u86f0\u8f99\u8005\u9517\u8517\u8fd9\u6d59\u8c2a\u966c\u67d8\u8f84\u78d4\u9e67\u891a\u8707\u8d6d',
  zhen: '\u73cd\u659f\u771f\u7504\u7827\u81fb\u8d1e\u9488\u4fa6\u6795\u75b9\u8bca\u9707\u632f\u9547\u9635\u7f1c\u6862\u699b\u8f78\u8d48\u80d7\u6715\u796f\u755b\u9e29',
  zheng: '\u84b8\u6323\u7741\u5f81\u72f0\u4e89\u6014\u6574\u62ef\u6b63\u653f\u5e27\u75c7\u90d1\u8bc1\u8be4\u5ce5\u94b2\u94ee\u7b5d',
  zhi: '\u829d\u679d\u652f\u5431\u8718\u77e5\u80a2\u8102\u6c41\u4e4b\u7ec7\u804c\u76f4\u690d\u6b96\u6267\u503c\u4f84\u5740\u6307\u6b62\u8dbe\u53ea\u65e8\u7eb8\u5fd7\u631a\u63b7\u81f3\u81f4\u7f6e\u5e1c\u5cd9\u5236\u667a\u79e9\u7a1a\u8d28\u7099\u75d4\u6ede\u6cbb\u7a92\u536e\u965f\u90c5\u57f4\u82b7\u646d\u5e19\u5fee\u5f58\u54ab\u9a98\u6809\u67b3\u6800\u684e\u8f75\u8f7e\u6534\u8d3d\u81a3\u7949\u7957\u9ef9\u96c9\u9e37\u75e3\u86ed\u7d77\u916f\u8dd6\u8e2c\u8e2f\u8c78\u89ef',
  zhong: '\u4e2d\u76c5\u5fe0\u949f\u8877\u7ec8\u79cd\u80bf\u91cd\u4ef2\u4f17\u51a2\u953a\u87bd\u8202\u822f\u8e35',
  zhou: '\u821f\u5468\u5dde\u6d32\u8bcc\u7ca5\u8f74\u8098\u5e1a\u5492\u76b1\u5b99\u663c\u9aa4\u5544\u7740\u501c\u8bf9\u836e\u9b3b\u7ea3\u80c4\u78a1\u7c40\u8233\u914e\u9cb7',
  zhu: '\u73e0\u682a\u86db\u6731\u732a\u8bf8\u8bdb\u9010\u7af9\u70db\u716e\u62c4\u77a9\u5631\u4e3b\u8457\u67f1\u52a9\u86c0\u8d2e\u94f8\u7b51\u4f4f\u6ce8\u795d\u9a7b\u4f2b\u4f8f\u90be\u82ce\u8331\u6d19\u6e1a\u6f74\u9a7a\u677c\u69e0\u6a65\u70b7\u94e2\u75b0\u7603\u86b0\u7afa\u7bb8\u7fe5\u8e85\u9e88',
  zhua: '\u6293',
  zhuai: '\u62fd',
  zhuan: '\u4e13\u7816\u8f6c\u64b0\u8d5a\u7bc6\u629f\u556d\u989b',
  zhuang: '\u6869\u5e84\u88c5\u5986\u649e\u58ee\u72b6\u4e2c',
  zhui: '\u690e\u9525\u8ffd\u8d58\u5760\u7f00\u8411\u9a93\u7f12',
  zhun: '\u8c06\u51c6',
  zhuo: '\u6349\u62d9\u5353\u684c\u7422\u8301\u914c\u707c\u6d4a\u502c\u8bfc\u5ef4\u855e\u64e2\u555c\u6d5e\u6dbf\u6753\u712f\u799a\u65ab',
  zi: '\u5179\u54a8\u8d44\u59ff\u6ecb\u6dc4\u5b5c\u7d2b\u4ed4\u7c7d\u6ed3\u5b50\u81ea\u6e0d\u5b57\u8c18\u5d6b\u59ca\u5b73\u7f01\u6893\u8f8e\u8d40\u6063\u7726\u9531\u79ed\u8014\u7b2b\u7ca2\u89dc\u8a3e\u9cbb\u9aed',
  zong: '\u9b03\u68d5\u8e2a\u5b97\u7efc\u603b\u7eb5\u8159\u7cbd',
  zou: '\u90b9\u8d70\u594f\u63cd\u9139\u9cb0',
  zu: '\u79df\u8db3\u5352\u65cf\u7956\u8bc5\u963b\u7ec4\u4fce\u83f9\u5550\u5f82\u9a75\u8e74',
  zuan: '\u94bb\u7e82\u6525\u7f35',
  zui: '\u5634\u9189\u6700\u7f6a',
  zun: '\u5c0a\u9075\u6499\u6a3d\u9cdf',
  zuo: '\u6628\u5de6\u4f50\u67de\u505a\u4f5c\u5750\u5ea7\u961d\u963c\u80d9\u795a\u9162',
  cou: '\u85ae\u6971\u8f8f\u8160',
  nang: '\u652e\u54dd\u56d4\u9995\u66e9',
  o: '\u5594',
  dia: '\u55f2',
  chuai: '\u562c\u81aa\u8e39',
  cen: '\u5c91\u6d94',
  diu: '\u94e5',
  nou: '\u8028',
  fou: '\u7f36',
  bia: '\u9adf'
}

//单词首字母获取
Pinyin.getWordsCode = function(str) {
  str = str.toUpperCase()
  str = str.replace(/([A-Z])[A-Z]*/g, '$1')
  var len = str.length
  var c,
    result = '',
    t
  for (i = 0; i < len; i++) {
    c = str.substr(i, 1)
    t = this._JMcode[c]
    if ('' + t == 'undefined') t = ''
    result += t
  }
  return result.toLowerCase()
}

//////////////////////////////////////////////
// eslint-disable-next-line spaced-comment
//////////////////////////////////////////////

Pinyin._ucfirst = function(l1) {
  if (l1.length > 0) {
    var first = l1.substr(0, 1).toUpperCase()
    var spare = l1.substr(1, l1.length)
    return first + spare
  }
}

Pinyin._arraySearch = function(l1, l2) {
  for (var name in this._pinyin) {
    if (this._pinyin[name].indexOf(l1) != -1) {
      return this._ucfirst(name)
      break
    }
  }
  return false
}

/*
 * 获取汉字的简码拼音
 */
Pinyin.GetJP = function(str) {
  str = str.toUpperCase()
  var len = str.length
  var c,
    result = '',
    t
  for (var i = 0; i < len; i++) {
    c = str.substr(i, 1)
    t = this._JMcode[c]
    if ('' + t == 'undefined') t = ''
    result += t
  }

  return result.toUpperCase()
}

/*
 * 获取汉字的全拼
 */

Pinyin.GetQP = function(l1) {
  var l2 = l1.length
  var I1 = ''
  // var reg = new RegExp('[a-zA-Z0-9\- ]'); //空格转换成-
  var reg = new RegExp('[a-zA-Z0-9]') // 去掉空格
  for (var i = 0; i < l2; i++) {
    var val = l1.substr(i, 1)
    var name = this._arraySearch(val, this._pinyin)
    if (reg.test(val)) {
      I1 += val
    } else if (name !== false) {
      I1 += name
    }
  }
  I1 = I1.replace(/ /g, '-')
  while (I1.indexOf('--') > 0) {
    I1 = I1.replace('--', '-')
  }
  return I1.toLowerCase()
}

/*
 * 如果是两个汉字则直接输入汉字的全拼
 * 如果是三个以上的汉字，第一个汉字全拼，后面的汉字简码
 */
Pinyin.GetHP = function(str) {
  var result = ''
  var regStr = str.replace(/\s/g, '')
  var strlh = regStr.length
  if (strlh == 2) {
    result = Pinyin.GetQP(regStr)
  } else {
    for (var i = 0; i < strlh; i++) {
      if (i != 0) {
        result += Pinyin.GetJP(regStr.charAt(i).toString())
      } else {
        result += Pinyin.GetQP(regStr.charAt(i).toString())
      }
    }
  }
  return result
}
export default Pinyin
