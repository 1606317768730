import Vue from "vue"
import Router from "vue-router"
import home from "../views/home.vue"
import dashboard from "../views/dashboard.vue"
import info from "../views/person/info.vue"
Vue.use(Router)
    // 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
    // push
Router.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // replace
Router.prototype.replace = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
        return originalReplace.call(this, location).catch(err => err)
    }
    //静态页面路径
export const constantRoutes = [{
        path: "/home",
        name: "home",
        component: home,
        meta: { title: "控制台" },
        children: [
            { path: "", component: dashboard, meta: { title: "控制台" } },
            { path: "person", component: info, meta: { title: "个人信息" } },
            {
                path: "/form/formdata/:id",
                component: () =>
                    import ("../views/form/formdata.vue"),
                meta: { title: "表单form" }
            },
            {
                path: "/operation/info",
                component: () =>
                    import ("../views/operation/info.vue"),
                meta: { title: "会员管理" },
                children: []
            },
        ],
    },
    {
        path: "/formView",
        name: "formView",
        component: () =>
            import ("../views/form/formconfig-preview.vue"),
        meta: { title: "信息录入" },
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import ("../views/about.vue"),
        meta: { title: "关于我们" },
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("../views/login/login.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/",
        name: "login",
        component: () =>
            import ("../views/login/login.vue"),
        meta: { title: "登录" },
    },
]
const createRouter = () =>
    new Router({
        mode: "history",
        base: process.env.BASE_URL,
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes,
    })

const router = createRouter()
    //路由前置守卫
router.beforeEach((to, from, next) => {
        if (localStorage.getItem('formdata')) {
            let formNewData = JSON.parse(localStorage.getItem('formdata'))
            formNewData.forEach(item => {
                if (item.path == to.path) {
                    to.meta.title = item.meta.title
                    to.meta.id = item.meta.id
                }
            });
        }
        /* 路由发生变化修改页面meta */
        if (to.meta.content) {
            let head = document.getElementsByTagName("head")
            let meta = document.createElement("meta")
            meta.content = to.meta.content
            head[0].appendChild(meta)
        }
        /* 路由发生变化修改页面title */
        if (to.meta.title) {
            document.title = to.meta.title + "-56世界运营管理平台"
        }
        next()
    })
    //后置路由守卫
router.afterEach((to, from) => {
        if (localStorage.getItem('currentPath') !== to.fullPath) {
            localStorage.setItem('currentMenu', to.fullPath)
            localStorage.setItem('currentPath', to.fullPath)
        }

    })
    // 重置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router