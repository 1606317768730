//自定义校验规则
//参照 https://www.cnblogs.com/lieone/p/11856330.html

//是否合法IP
export function validateIP(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的IP地址'));
        } else {
            callback();
        }
    }
}
//是否合法url
export function validateURL(rule, value, callback) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    const rsCheck = urlregex.test(value);
    if (!rsCheck) {
        callback(new Error('请输入合法的URL'));
    } else {
        callback();
    }
}
//验证端口
export function validatePort(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        const re = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
            callback(new Error('请输入在[0-65535]之间的端口值'));
        } else {
            callback();
        }
    }
}
//是否手机号码或者固话
export function validatePhoneTwo(rule, value, callback) {
    const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的电话号码或者固话号码'));
        } else {
            callback();
        }
    }
}
//是否固话
export function validateTelphone(rule, value, callback) {
    const reg = /0\d{2,3}-\d{7,8}/;
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的固定电话）'));
        } else {
            callback();
        }
    }
}
//是否手机号
export function validatePhone(rule, value, callback) {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的电话号码'));
        } else {
            callback();
        }
    }
}
//是否身份证
export function validateIdNo(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        if ((!reg.test(value)) && value != '') {
            callback(new Error('请输入正确的身份证号码'));
        } else {
            callback();
        }
    }
}
//是否邮箱
export function validateEMail(rule, value, callback) {
    const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    } else {
        if (!reg.test(value)) {
            callback(new Error('请输入正确的邮箱'));
        } else {
            callback();
        }
    }
}

//是否整数
export function validateInteger(rule, value, callback) {
    const re = /^-?[1-9]\d*|0$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    const rsCheck = re.test(value);
    if (!rsCheck) {
        callback(new Error('请输入整数'));
    } else {
        callback();
    }
}
//是否正整数
export function validatePInteger(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    if (!Number(value)) {
        callback(new Error('请输入正整数'));
    } else {
        const re = /^[0-9]*[1-9][0-9]*$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
            callback(new Error('请输入正整数'));
        } else {
            callback();
        }
    }
}
//小写字母
export function validateLowerCase(rule, value, callback) {
    const reg = /^[a-z]+$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    const rsCheck = reg.test(value);
    if (!rsCheck) {
        callback(new Error('请输入小写字母'));
    } else {
        callback();
    }
}
//大写字母
export function validateUpperCase(rule, value, callback) {
    const reg = /^[A-Z]+$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    const rsCheck = reg.test(value);
    if (!rsCheck) {
        callback(new Error('请输入大写字母'));
    } else {
        callback();
    }
}
//大小写字母
export function validateAlphabets(rule, value, callback) {
    const reg = /^[A-Za-z]+$/;
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    const rsCheck = reg.test(value);
    if (!rsCheck) {
        callback(new Error('请输入字母'));
    } else {
        callback();
    }
}
//中文校验
export function validateChinese(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    if (!/^[\u0391-\uFFE5A-Za-z]+$/.test(value)) {
        callback(new Error('不可输入特殊字符'))
    } else {
        callback()
    }
}
//密码校验
export function validatePsdReg(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/.test(value)) {
        callback(new Error('请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
    } else {
        callback()
    }
}
//一位小数
export function validateOnePoint(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    if (!/^[0-9]+([.]{1}[0-9]{1})?$/.test(value)) {
        callback(new Error('最多一位小数！！！'));
    } else {
        callback();
    }
}
//两位小数
export function validateTwoPoint(rule, value, callback) {
    if (value == '' || value == undefined || value == null) {
        callback();
    }
    if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
        callback(new Error('最多两位小数！！！'));
    } else {
        callback();
    }
}