<template>
	<fragment>
		<template v-for="sidebar in sidebars">
			<!-- 最后一级菜单 -->
			<el-menu-item v-if="!sidebar.children" :key="sidebar.id" :index="sidebar.viewPath">
				<i :class="sidebar.icon || 'el-icon-menu'"></i>
				<span slot="title">{{ sidebar.name }}</span>
			</el-menu-item>
			<el-submenu v-if="sidebar.children" :key="sidebar.id" :index="sidebar.id">
				<template slot="title">
					<i :class="sidebar.icon || 'el-icon-menu'"></i>
					<span>{{ sidebar.name }}</span>
				</template>
				<fortress-sidebar :sidebars="sidebar.children"></fortress-sidebar>
			</el-submenu>
		</template>
	</fragment>
</template>
<script>
export default {
	name: "FortressSidebar",
	props: ["sidebars"],
	data() {
		return {}
	},
	methods: {},
}
</script>

<style scoped></style>
