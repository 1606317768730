<template>
	<div class="card-item">
		<router-link :to="{ path: path }" class="link">
			<i :class="iconClass" :style="{ color: color }"></i>
			<span>{{ text }}</span>
		</router-link>
	</div>
</template>

<script>
export default {
	name: "card",
	props: ["iconClass", "text", "color", "path"],
	data() {
		return {}
	},
	created() {},
	methods: {},
	components: {},
}
</script>

<style lang="scss" scoped>
.card-item {
	padding: 15px;
	width: 135px;
	height: 95px;
	background: #fff;
	border-radius: 5px;
	margin: 5px 8px;
	.link {
		display: flex;
		flex-wrap: wrap;
		i {
			margin-bottom: 4px;
			width: 100%;
			text-align: center;
			font-size: 32px;
		}
		span {
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #515a6e;
		}
	}
	&:hover {
		cursor: pointer;
		box-shadow: 1px 1px 2px 2px #eee;
	}
}
</style>
