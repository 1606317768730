<template>
  <div>
    <el-input
        readonly
        v-model="value"
        autocomplete="off"
        :placeholder="placeholder"
        @focus="openJob()"
    ></el-input>
    <el-dialog append-to-body :width="width" v-if="dialogShow" :visible="dialogShow" @close="closeDialog">
      <div class="inner">
        <div class="search">
          <span class="title">{{title}}</span>
          <el-autocomplete
              class="inline-input"
              v-model="state"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @select="handleSelect"
              prefix-icon="el-icon-search"
              :clearable="true"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.parentName }}-{{ item.parentsName }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div class="content">
          <div class="scrollbar">
            <ul class="list">
              <li
                  v-for="(item,index) in dataList"
                  @click="changeOne(index,item)"
                  :key="index"
                  :class="[{pActive:index==i},{active:item.active}]"
              >{{item.name}}</li>
            </ul>
          </div>
          <div class="scrollbar">
            <ul class="list">
              <li
                  v-show="dataList[i].children"
                  v-for="(items,indexs) in dataList[i].children"
                  @click="changeTwo(indexs,items)"
                  :key="indexs"
                  :class="[{pActive:indexs==j},{active:items.active}]"
              >{{items.name}}</li>
            </ul>
          </div>
          <div class="scrollbar right">
            <ul class="list">
              <li
                  :class="[{pActive:indexss==x},{active:itemss.active}]"
                  v-for="(itemss,indexss) in dataList[i].children[j].children"
                  :key="indexss"
                  v-show="dataList[i].children[j].children"
                  @click="changeThree(indexss,itemss)"
              >{{itemss.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import vPinyin from '../../../public/pinyin/pinyin2.js'
import { industryList} from "@/api/system/industry";
export default {
  name: "FortressIndustry",
  props: {
    title: String,
    placeholder: String,
    checkId: {
      type: String,
    },
    width: {
      type: String,
      dafault: "50%"
    },
  },
  data () {
    return {
      id: '',
      dialogShow:false,
      value:'',
      jobIndex: '',
      dataList: [],
      state: '',
      i: 0,
      j: 0,
      x: -1,
      active: {
        i: -1,
        j: -1,
        x: -1
      },
      activeData: [],
      checkItem: {},
      searchData: [],
      parentItem: {},
      parentsItem: {}
    };
  },

  components: {
  },
  created() {
    industryList().then(res => {
      this.dataList = res.data
      this.id = this.checkId
      this.init()
    })
  },

  computed: {},
  methods: {
    init(){
        this.loop(this.dataList)
        this.TransferEn(this.dataList)
        if (this.id != '') {
          this.downFind(this.dataList, this.id)
          this.upFind(this.dataList, this.checkItem.parentId)
          this.activeData = []
          this.filterTrue(this.dataList)

          localStorage.setItem("activeData", JSON.stringify(this.activeData))
          this.activeData = JSON.parse(localStorage.getItem('activeData'))

          if (this.activeData.length > 0) {
            this.activeData.forEach((item, index) => {
              if (index == 0) {
                this.i = item.index
              }
              if (index == 1) {
                this.j = item.index
              }
              if (index == 2) {
                this.x = item.index
              }
            })
          }
        }
        else {
          this.activeData = JSON.parse(localStorage.getItem('activeData'))
          if (this.activeData.length > 0) {
            this.activeData.forEach((item, index) => {
              if (index == 0) {
                this.i = item.index
              }
              if (index == 1) {
                this.j = item.index
              }
              if (index == 2) {
                this.x = item.index
              }
            })
          }
        }
    },
    openJob(){ //打开选择职业框
      console.log('打开选择职业框')
      this.dialogShow=true
    },
    closeDialog () {
      this.dialogShow = false
      this.$emit('dialogShowChange', false)
    },
    handleSelect (e) {
      this.downFind(this.dataList, e.id)
      this.upFind(this.dataList, e.parentId)
      this.activeData = []
      this.filterTrue(this.dataList)
      localStorage.setItem("activeData", JSON.stringify(this.activeData))
      this.$emit('check', e)
      // this.dataList.push(e)
      this.id = e.id
      this.value = e.name
      this.dialogShow = false
    },
    TransferEn (data) {
      if (data) {
        data.forEach(item => {
          if (!item.children) {
            let pinyin = vPinyin.chineseToPinYin(item.name)
            this.parentItem = {}
            this.upFind(this.dataList, item.parentId, 2)
            this.upFind(this.dataList, this.parentItem.parentId, 3)
            // , parentName: this.parentItem.name, parentsName: this.parentsItem.name
            this.searchData.push({ name: item.name, id: item.id, pinyin: pinyin, parentId: item.parentId, parentName: this.parentItem.name, parentsName: this.parentsItem.name })
          }
          this.TransferEn(item.children)
        })
      }
    },
    querySearch (queryString, cb) {
      var searchList = this.searchData;
      var results = queryString ? searchList.filter(this.createFilter(queryString)) : searchList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter (queryString) {
      return searchList => {
        const searchValueReg = new RegExp(queryString.toLowerCase(), "gi");
        return searchValueReg.test(searchList.name.toLowerCase()) || searchValueReg.test(searchList.pinyin.toLowerCase())
        // return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    loop (data) {
      if (data) {
        data.forEach(item => {
          this.$set(item, 'active', false)
          this.loop(item.children)
        })
      }
    },
    downFind (data, id) {
      if (data) {
        data.forEach(item => {
          if (item.id == id) {
            this.$set(item, 'active', true)
            this.checkItem = item
            this.value = this.checkItem.name
          } else {
            this.downFind(item.children, id)
          }
        })
      }
    },
    changeOne (n) {
      this.j = 0
      this.x = -1
      this.i = n
    },
    changeTwo (n) {
      this.j = n
      this.x = -1
    },
    changeThree (n, data) {
      this.loop(this.dataList)
      this.x = n
      this.$set(data, 'active', true)
      this.upFind(this.dataList, data.parentId)
      this.activeData = []
      this.filterTrue(this.dataList)
      localStorage.setItem("activeData", JSON.stringify(this.activeData))
      this.$emit('check', data)
      // this.dataList.push(data)
      this.id = data.id
      this.value=data.name
      this.dialogShow = false
    },
    upFind (data, pid, state) {
      for (var i = 0, length = data.length; i < length; i++) {
        let node = data[i];
        if (node.id == pid) {
          if (state && state == 2) {
            this.parentItem = node
            this.upFind(data, node.parentId, 2);
          }
          else if (state && state == 3) {
            this.parentsItem = node
            this.upFind(data, node.parentId, 3);
          }
          else {
            this.$set(node, 'active', true)
            this.upFind(this.dataList, node.parentId);
          }

          break;
        }
        else {
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!node.children) {
            if (state && state == 2) {
              this.upFind(node.children, pid, 2);
            } else if (state && state == 3) {
              this.upFind(node.children, pid, 3);
            }
            else {
              this.upFind(node.children, pid);
            }

          }
        }
      }
    },
    filterTrue (data) {
      if (data) {
        data.some((item, index) => {
          if (item.active) {
            this.activeData.push({
              ...item,
              index: index
            })
            this.filterTrue(item.children)
          }
        })
      }
    }
  }

};
</script>
<style lang='scss' scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0px !important;
}
/deep/ .el-dialog__close {
  font-size: 26px;
  margin-top: -4px;
  &:hover {
    color: #333;
    background: transparent !important;
  }
}
///deep/ .el-autocomplete-suggestion li:hover {
//  background-color: rgba(61, 126, 255, 0.05) !important;
//  color: #3d7eff !important;
//}
///deep/ .el-input__inner {
//  width: 340px;
//  height: 32px;
//}
///deep/ .el-input__inner:focus {
//  outline: none;
//  border-color: #c0c4cc;
//}

.inner {
  .search {
    height: 56px;
    line-height: 56px;
    background: #f0f2f6;
    padding: 0 15px;
    .title {
      margin-right: 20px;
      color: #101030;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .content {
    height: 540px;
  }
}
.scrollbar {
  height: 100%;
  float: left;
  width: 170px;
  overflow: auto;
  height: 520px;
  &:not(:last-child) {
    border-right: 1px solid #eee;
  }
  ul {
    li {
      height: 48px;
      line-height: 48px;
      padding-left: 15px;
      cursor: pointer;

      &:hover {
        color: #3d7eff;
        background: rgba(61, 126, 255, 0.05);
      }
    }
    .pActive {
      color: #3d7eff;
      background: rgba(61, 126, 255, 0.05);
    }
    .active {
      color: #3d7eff;
    }
  }
}
.right {
  width: calc(100% - 340px);
  ul {
    li {
      display: inline-block;
      &:hover {
        background: transparent;
        color: #3d7eff;
      }
    }
    .pActive {
      color: #3d7eff;
      background: transparent;
    }
  }
}
</style>
