import request from "@/common/request"
//登录
export const login = (username, pwd) => {
        return request("post", "/login?username=" + username + "&password=" + pwd);
    }
    //获取当前用户信息
export const userInfo = () => {
    return request("get", "/userInfo");
}

//获取当前用户可操作菜单
export const menuList = () => {
    return request("get", "/system/menu/authority");
}