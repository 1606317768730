<template>
	<div class="dashboardBox">
		<div class="cardBox">
			<fortress-card :iconClass="item.iconClass" :text="item.text" :color="item.color" :path="item.path" v-for="(item, index) in cardGroup" :key="index"></fortress-card>
		</div>
	</div>
</template>
<script>
import fortressCard from "../components/dashboard/fortress-card.vue"
import { menuList } from "@/api/auth/auth.js"
export default {
	data() {
		return {
			cardGroup: [],
			colors: [],
			color: [{ color: "rgb(105, 192, 255)" }, { color: "rgb(255, 133, 192)" }, { color: "rgb(149, 222, 100)" }, { color: "rgb(255, 214, 102)" }, { color: "rgb(255, 156, 110)" }, { color: "rgb(92, 219, 211)" }, { color: "rgb(179, 127, 235)" }, { color: "rgb(255, 192, 105)" }, { color: "rgb(105, 192, 255)" }],
		}
	},

	components: { fortressCard },
	methods: {
		// 多次循环颜色
		colorGrops() {
			this.colors = []
			for (let i = 0; i < 10; i++) {
				this.colors.push(...this.color)
			}
		},
		//初始化菜单
		initMenu() {
			menuList().then(resp => {
				if (resp.code == 0) {
					this.menuList = resp.data
					this.getSon(this.menuList)
					this.colors.forEach((item, index) => {
						if (this.cardGroup[index]) {
							this.cardGroup[index].color = item.color
						}
					})
				} else {
					this.$msg({
						message: resp.msg,
						type: "error",
					})
				}
			})
		},
		// 获取叶节点
		getSon(arrys) {
			for (var i = 0; i < arrys.length; i++) {
				if (arrys[i].children) {
					this.getSon(arrys[i].children)
				} else {
					var sonDate = {}
					sonDate.iconClass = arrys[i].icon || "el-icon-menu"
					sonDate.text = arrys[i].name
					sonDate.path = arrys[i].viewPath
					sonDate.color = ""
					this.cardGroup.push(sonDate)
				}
			}
			return this.cardGroup
		},
	},
	created() {
		this.colorGrops()
		this.initMenu()
	},
}
</script>

<style lang="scss" scoped>
$list-bg: rgb(105, 192, 255), rgb(255, 133, 192), rgb(149, 222, 100), rgb(255, 214, 102), rgb(255, 156, 110), rgb(92, 219, 211), rgb(179, 127, 235), rgb(255, 192, 105);

.dashboardBox {
	width: 100%;
}
.cardBox {
	margin: 10px -8px 16px -8px;
	display: flex;
	flex-wrap: wrap;
}
</style>
