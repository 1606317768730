<template>
  <el-select
    :value="value == null ? '' : value + ''"
    @input="$emit('input', $event)"
    :placeholder="placeholder || '请选择数据'"
    :clearable="clearable"
    :filterable="filterable"
    :disabled="disabled"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="item in dictList"
      :key="item.code"
      :label="item.name"
      :value="item.code"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "FortressSelect",
  props: {
    value: [Number, String] || "",
    dictList: Array,
    placeholder: String,
    clearable: Boolean,
    filterable: Boolean,
    disabled: Boolean,
  },
};
</script>
