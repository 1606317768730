import Vue from "vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store/store"
import "./plugins/element.js"
import Element from "element-ui"
import { Message } from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import i18n from "./locale" // Internationalization
import "./styles/global.scss" // global css
import * as commonConf from "@/common/config.js"
import "./plugins/axios"
import Fragment from "vue-fragment"
import * as tools from "@/common/tools.js"
import FortressRadioGroup from "@/components/fortress-radio-group"
import FortressSelect from "@/components/fortress-select"
import FortressRegion from "@/components/fortress-region"
import FortressCopy from "@/components/fortress-copy"
import FortressEditor from "@/components/ckeditor"
import FortressIndustry from "@/components/fortress-industry"
import FortressSearch from "@/components/fortress-search"
import VueAMap from "vue-amap"
import FortressMap from "@/components/fortress-region"
import Pinyin from "@/assets/ChinesePY"
import loading from "@/components/loading/loading.vue"
import FortressRegionChecked from "@/components/fortress-region-checked"
import "@/icons"
require("../public/style/fonts/iconfont.css")
Vue.use(Fragment.Plugin)
Vue.use(FortressIndustry)
Vue.use(FortressRadioGroup)
Vue.use(FortressSelect)
Vue.use(FortressRegionChecked)
Vue.use(FortressRegion)
Vue.use(FortressCopy)
Vue.use(FortressEditor)
Vue.use(FortressSearch)
Vue.component("loading", loading)
Vue.prototype.Pinyin = (restaurant, queryString) => {
	const jp = Pinyin.GetJP(restaurant)
	const qp = Pinyin.GetQP(restaurant)
	const hp = Pinyin.GetHP(restaurant)
	const rgx = new RegExp(queryString, "gi")
	if (rgx.test(jp) || rgx.test(qp) || rgx.test(hp)) {
		return true
	}
	return false
}
Vue.prototype.translatePY = queryString => {
	const jp = Pinyin.GetJP(queryString)
	const qp = Pinyin.GetQP(queryString)
	const hp = Pinyin.GetHP(queryString)
	console.log(jp, qp, hp)
}

Vue.prototype.$msg = Message
Vue.prototype.$tools = tools
// Vue.prototype.$conf = commonConf
window.$conf = {
	commonConf,
}
// 引入高德地图组件
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
	key: "0be2f849e87060284e61a7344618f4ee",
	//用到的插件
	plugin: [
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.Scale",
		"AMap.OverView",
		"AMap.ToolBar",
		"AMap.MapType",
		"AMap.Geolocation",
		"AMap.Geocoder",
		"AMap.AMapManager",
		"AMap.Marker",
	],
	v: "1.4.4", //版本
	uiVersion: "1.0", //ui版本
})
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode: "f78a748bb23f4e3fbe67812001791675" // "安全密钥",
}
//封装的地图组件
Vue.use(FortressMap)

//语雀视频组件
import EleForm from "vue-ele-form"
import EleFormGallery from "vue-ele-form-gallery" //图片、视频展示组件
import EleFormVideoUploader from "vue-ele-form-video-uploader" //视频上传
import EleFormImageUploader from "vue-ele-form-image-uploader" //图片上传
import EleFormUploadFile from "vue-ele-form-upload-file"

// 注册 gallery 组件 图片、视频展示组件
Vue.component("gallery", EleFormGallery)
// 注册 ele-form
Vue.use(EleForm, {
	// 可以在这里设置全局的 gallery 属性
	// 属性参考下方 #attrs
	gallery: {
		size: 150,
	},
})
// 注册 video-uploader 组件
Vue.component("video-uploader", EleFormVideoUploader)
// 注册 image-uploader 组件
Vue.component("image-uploader", EleFormImageUploader)
// 注册 upload-file 组件
Vue.component("upload-file", EleFormUploadFile)
import FortressUpload from "@/components/fortress-upload"
Vue.use(FortressUpload)

Vue.use(Element, {
	i18n: (key, value) => i18n.t(key, value),
})

Vue.config.productionTip = false
export default new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount("#app")
